import React from "react";
import { useHistory } from "react-router-dom";
import AirbusLogo from "../../../../../assets/airbus-logo.png";
import NextAxyaLogo from "assets/img/axya-logo-topbar.svg";
import { ValidationManagementUserProfile } from "./validation-management-user-profile/validation-management-user-profile";
import { CustomNavTab } from "../../custom-nav-tab/custom-nav-tab";
import { frontendUrl } from "urls";
import * as S from "./validation-management-app-bar.styled";

export const ValidationManagementAppBar = () => {
  const history = useHistory();

  return (
    <S.StyledAppBar>
      <S.StyledImg src={NextAxyaLogo} alt="Axya" />

      <S.StyledAvatar src={AirbusLogo} />

      <S.StyledNavTabBox>
        <CustomNavTab text="Questionnaires" path={frontendUrl.validationManagementReviews} />

        <CustomNavTab text="Readiness dashboard" path={frontendUrl.readinessDashboard} />
      </S.StyledNavTabBox>

      <S.ProfileWrapper>
        <ValidationManagementUserProfile />
      </S.ProfileWrapper>
    </S.StyledAppBar>
  );
};
