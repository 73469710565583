import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, MenuItem, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";
import {
  selectConfigurationError,
  selectIsCreatingConfiguration,
  selectIsFetchingSuppliers,
  selectSuppliers,
} from "@next/modules/ai/redux/selectors";
import snackbarUtils from "@next/utils/snackbarUtils";

const parserTypes = [
  { value: "POConfirmation", label: "PO Confirmation" },
  { value: "ShipmentConfirmation", label: "Shipment Confirmation" },
  { value: "Quotation", label: "Quotation" },
];

export const ParserConfigurationSetupForm: React.FC = () => {
  const dispatch = useDispatch();

  const loadingSuppliers = useSelector(selectIsFetchingSuppliers);
  const creatingConfiguration = useSelector(selectIsCreatingConfiguration);
  const error = useSelector(selectConfigurationError);
  const suppliers = useSelector(selectSuppliers);

  const [selectedSupplier, setSelectedSupplier] = useState<string>("");
  const [selectedParserType, setSelectedParserType] = useState<string>("");
  const [importFile, setImportFile] = useState<File | null>(null);

  useEffect(() => {
    dispatch(parserActions.fetchSuppliersRequest());
  }, [dispatch]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImportFile(e.target.files[0]);
    }
  };

  const handleSubmit = () => {
    if (selectedSupplier && selectedParserType) {
      // Create payload based on whether a file is selected
      const payload = new FormData();
      payload.append("supplierPk", selectedSupplier);
      payload.append("parserType", selectedParserType);

      if (importFile) {
        payload.append("file", importFile);
      }

      dispatch(parserActions.createConfigurationRequest(payload));
    } else {
      snackbarUtils.warning("Please select both supplier and parser type.");
    }
  };

  useEffect(() => {
    if (error) {
      snackbarUtils.error(error);
    }
  }, [error]);

  return (
    <Box p="16px">
      <Typography variant="h5" gutterBottom>
        Parser Configuration Setup
      </Typography>
      {loadingSuppliers ? (
        <CircularProgress />
      ) : (
        <>
          <TextField
            select
            label="Supplier"
            value={selectedSupplier}
            onChange={(e) => setSelectedSupplier(e.target.value)}
            fullWidth
            margin="normal"
            error={!!error && !selectedSupplier}
            helperText={!selectedSupplier ? "Please select a supplier." : ""}
          >
            {suppliers.map((supplier) => (
              <MenuItem key={supplier.pk} value={supplier.pk.toString()}>
                {supplier.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Parser Type"
            value={selectedParserType}
            onChange={(e) => setSelectedParserType(e.target.value)}
            fullWidth
            margin="normal"
            error={!!error && !selectedParserType}
            helperText={!selectedParserType ? "Please select a parser type." : ""}
          >
            {parserTypes.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                {type.label}
              </MenuItem>
            ))}
          </TextField>

          <Typography variant="body2">Import Parser Config (Optional): </Typography>
          <input
            type="file"
            accept="application/json"
            onChange={handleFileChange}
            style={{ marginTop: "16px" }}
          />
          <Box mt="16px">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!selectedSupplier || !selectedParserType || creatingConfiguration}
            >
              {creatingConfiguration ? <CircularProgress size={24} /> : "Create Configuration"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
