import { Box, Typography, styled } from "@mui/material";

export const StyledCardBox = styled(Box)({
  width: "99%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  padding: "48px",
  borderRadius: "32px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  backgroundColor: "#fff",
  gap: "24px",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
  alignSelf: "flex-start",
}));
