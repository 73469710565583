import React from "react";
import { useParams } from "react-router-dom";
import SampleRunner from "../components/parser-config-details-view/sample-runner-view";
import { Breadcrumbs, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SamplesListView from "../components/list-parser-samples-view/list-parser-samples-view";
import ParserDetailsAppBarComponent from "../components/parser-config-details-view/parser-appbar";

const ParsersConfigurationDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  // id is composed of supplier name and parser id separated by `:`
  const parts = id.split(":");
  const supplierName = parts[0];
  const _parserId = parts[1];

  return (
    <>
      <ParserDetailsAppBarComponent parserId={_parserId} />
      <Paper elevation={3}>
        <Breadcrumbs aria-label="breadcrumb" sx={{ paddingTop: 5, paddingLeft: 5 }}>
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/ai/parsers">
            Parsers
          </Link>

          <Typography color="textPrimary">{supplierName}</Typography>
        </Breadcrumbs>
        <SamplesListView supplierName={supplierName} parserId={_parserId} />
        <SampleRunner supplierName={supplierName} parserId={_parserId} />
      </Paper>
    </>
  );
};

export default ParsersConfigurationDetails;
