import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import * as S from "./custom-nav-tab.styled";

type Props = {
  text: string;
  path: string;
};

export const CustomNavTab: React.FC<Props> = ({ text, path }) => {
  const history = useHistory();
  const location = useLocation();
  const isRouted = location.pathname === path;

  const onClick = () => {
    if (!isRouted) {
      history.push(path);
    }
  };

  return (
    <S.StyledBox onClick={onClick} isRouted={isRouted}>
      <S.StyledTypography variant="body1">{text}</S.StyledTypography>
    </S.StyledBox>
  );
};
