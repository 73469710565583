import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { ReadinessDashboardSupplierViewChartsCard } from "./readiness-dashboard-supplier-view-charts-card/readiness-dashboard-supplier-view-charts-card";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import { SupplierViewDonutChartsCard } from "@next/modules/vendor-management/redux";

export const ReadinessDashboardSupplierViewChartsCardsGroup: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const pieChartsData: SupplierViewDonutChartsCard[] = dashboardData?.supplier_view?.piecharts;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ width: "100%" }} spacing={2}>
        {pieChartsData?.map((chartData) => (
          <Grid item xs={12} md={12} lg={12} key={chartData.slug}>
            <ReadinessDashboardSupplierViewChartsCard
              slug={chartData.slug}
              charts={chartData.charts}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
