import { GridColDef } from "@mui/x-data-grid-pro-v5";
import { HeaderCell } from "../table-cells/header-cell";
import { DashboardStatusCell } from "../table-cells/dashboard-status-cell";
import TextCell from "@next/components/table-cells/text-cell-v5";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
  renderHeader: HeaderCell,
  renderCell: TextCell,
};

export const getReadinessDashboardSuppliersTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "supplier",
      headerName: "Supplier",
    },
    {
      ...defaultColumnConfig,
      field: "vendor_code",
      headerName: "Vendor Code",
    },
    {
      ...defaultColumnConfig,
      field: "company_type",
      headerName: "Priority",
      valueGetter: (params) => {
        return params.row.company_type ? params.row.company_type.toUpperCase() : "";
      },
    },
    {
      ...defaultColumnConfig,
      field: "questionnaire_type",
      headerName: "Questionnaire type",
    },
    {
      ...defaultColumnConfig,
      field: "manufacturing_site",
      headerName: "Manufacturing site",
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: "communication",
      headerName: "Communication",
      renderCell: DashboardStatusCell,
    },
    {
      ...defaultColumnConfig,
      field: "questionnaire_status",
      headerName: "Questionnaire",
      renderCell: DashboardStatusCell,
      flex: 1,
    },
    {
      ...defaultColumnConfig,
      field: "specs_confirmed",
      headerName: "Specs completed",
    },
    {
      ...defaultColumnConfig,
      field: "specs_with_poe_after_june_2025",
      headerName: "Specs with a POE after June 2025",
    },
  ];

  return columns;
};
