import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { HeaderCell } from "../table-cells/header-cell";
import { DashboardStatusCell } from "../table-cells/dashboard-status-cell";
import { OverdueCell } from "../table-cells/overdue-cell";
import { SpecTypeCell } from "../table-cells/spec-type-cell";
import TextCell from "@next/components/table-cells/text-cell-v5";
import { formatDate } from "helpers/formatDate";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
  renderHeader: HeaderCell,
  renderCell: TextCell,
};

export const getReadinessDashboardSpecViewSpecsTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "specification",
      headerName: "Specification",
    },
    {
      ...defaultColumnConfig,
      field: "specification_type",
      headerName: "Spec type",
      renderCell: SpecTypeCell,
      flex: 0.2,
    },
    {
      ...defaultColumnConfig,
      field: "furthest_po",
      headerName: "Furthest POE",
      renderCell: (params: GridRenderCellParams<any>) => {
        return <>{formatDate(params.value, "MM/DD/YYYY")}</>;
      },
      flex: 0.2,
    },
    {
      ...defaultColumnConfig,
      field: "status",
      headerName: "Status",
      renderCell: DashboardStatusCell,
    },
    {
      ...defaultColumnConfig,
      field: "days_overdue",
      headerName: "Days overdue",
      renderCell: OverdueCell,
      flex: 0.3,
    },
    {
      ...defaultColumnConfig,
      field: "gap_a2ms",
      headerName: "Gap A2MS End User POE < to A2MS Manuf POE",
      renderCell: (params: GridRenderCellParams<any>) => {
        return <>{params.value ? "Yes" : null}</>;
      },
    },
  ];

  return columns;
};
