import React, { useEffect } from "react";
import { Box, Card, CardContent, Chip, Typography } from "@mui/material";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { parserActions } from "@next/modules/ai/redux/slices";
import {
  selectConfigurations,
  selectConfigurationsLoading,
} from "@next/modules/ai/redux/selectors";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
const ConfigurationsList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const configurations = useSelector(selectConfigurations) || [];
  const loading = useSelector(selectConfigurationsLoading);

  useEffect(() => {
    dispatch(parserActions.fetchConfigurationsRequest());
  }, [dispatch]);

  const groupedConfigurations = configurations.reduce(
    (acc, config) => {
      if (!acc[config.type]) {
        acc[config.type] = [];
      }
      acc[config.type].push(config);
      return acc;
    },
    {} as Record<string, any[]>
  );

  const handleToggleActive = (event: React.MouseEvent, configId: string, currentState: boolean) => {
    event.stopPropagation();
    dispatch(
      parserActions.updateParserConfigRequest({
        parserId: configId,
        data: { is_active: !currentState },
        action_type: "update",
      })
    );
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "supplier_name", headerName: "Supplier Name", flex: 1 },
    { field: "type", headerName: "Parser Type", flex: 1 },
    { field: "samples_count", headerName: "Samples Count", flex: 1 },
    {
      field: "samples_passing",
      headerName: "Is Passing",
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Success" : "Failed"}
          color={params.value ? "success" : "error"}
          size="small"
          style={{ marginLeft: "4px" }}
        />
      ),
    },
    {
      field: "is_active",
      headerName: "Deployed",
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <ToggleOnIcon
            style={{ color: "green", cursor: "pointer" }}
            onClick={(event) => handleToggleActive(event, params.row.id, !!params?.value)}
          />
        ) : (
          <ToggleOffIcon
            style={{ color: "grey", cursor: "pointer" }}
            onClick={(event) => handleToggleActive(event, params.row.id, !!params.value)}
          />
        ),
    },
    {
      field: "unpublished_data",
      headerName: "Published",
      flex: 1,
      renderCell: (params) =>
        !params.value ? (
          <CheckIcon style={{ color: "grey" }} />
        ) : (
          <CloseIcon style={{ color: "red" }} />
        ),
    },
    { field: "created_at", headerName: "Created At", flex: 1 },
  ];

  const handleRowClick = (params: any) => {
    const configId = params.id;
    const supplierName = params.row.supplier_name;
    history.push(`/ai/parsers/${supplierName}:${configId}`);
  };

  return (
    <Box p={3} height="100%" display="flex" flexDirection="column">
      <Typography variant="h4" gutterBottom>
        Parser Configurations
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : (
        Object.keys(groupedConfigurations).map((type) => (
          <Box key={type} mb={4} flex="1" display="flex" flexDirection="column">
            <Typography variant="h5" gutterBottom>
              {type}
            </Typography>
            <Card style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <CardContent style={{ flex: 1, display: "flex", flexDirection: "column" }}>
                <DataGridPro
                  rows={groupedConfigurations[type]}
                  columns={columns}
                  autoHeight={true}
                  onRowClick={handleRowClick}
                  disableColumnMenu
                  disableColumnSelector
                  disableDensitySelector
                  hideFooter
                  style={{ flex: 1 }}
                />
              </CardContent>
            </Card>
          </Box>
        ))
      )}
    </Box>
  );
};

export default ConfigurationsList;
