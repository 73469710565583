import { Box, styled } from "@mui/material";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";

export const StyledBox = styled(Box)({
  width: "100%",
  height: "100%",
  margin: "0",
  overflowX: "auto",
});

export const StyledDataGridPro = styled(DataGridProV5)(({ theme }) => ({
  width: "1792px",
  height: "100%",
  border: "none",
  "& .MuiDataGrid-columnHeaders": {
    border: "none",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    border: "none",
  },
  "& .even": {
    backgroundColor: "#F0F7FB",
  },
  "& .MuiDataGrid-row": {
    "&:hover": {
      backgroundColor: "#D1ECFB",
    },
  },
}));
