import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Chip, styled } from "@mui/material";

const customTypeStyles: Record<string, { backgroundColor: string; color: string }> = {
  A2ERD: {
    backgroundColor: "#242D66",
    color: "#FFFFFF",
  },
  A2LS: {
    backgroundColor: "#275586",
    color: "#FFFFFF",
  },
  A2NDE: {
    backgroundColor: "#20ACC7",
    color: "#FFFFFF",
  },
  A0: {
    backgroundColor: "#E9E9E9",
    color: "theme.palette.text.primary",
  },
};

type ChipStyleProps = {
  type: string;
};

const StyledChip = styled(Chip)<ChipStyleProps>(({ type, theme }) => ({
  backgroundColor: customTypeStyles[type]?.backgroundColor || "#E9E9E9",
  color:
    customTypeStyles[type]?.color === "theme.palette.text.primary"
      ? theme.palette.text.primary
      : customTypeStyles[type]?.color || "#000000",
  height: "24px",
  fontSize: "12px",
}));

export const SpecTypeCell: React.FC<GridRenderCellParams<any>> = (params) => {
  const type = params.value;
  return <StyledChip label={type} type={type} />;
};
