import { Box, Grid, Typography, styled } from "@mui/material";

export const StyledCardBox = styled(Box)({
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderRadius: "32px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  backgroundColor: "#fff",
  gap: "80px",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
}));

export const StyledGridContainer = styled(Grid)({
  width: "74%",
  display: "flex",
  "@media (max-width: 800px)": {
    width: "80%",
  },
  "@media (max-width: 675px)": {
    width: "90%",
  },
  "@media (max-width: 600px)": {
    width: "100%",
  },
});
