import { FavoriteSupplier, workspaceNextActions } from "@next/modules/workspace/redux";
import {
  selectFavoriteSupplierGroups,
  selectFavoriteSupplierGroupsLoading,
  selectRfqFormFavoriteSuppliersList,
  selectRfqFormFavoriteSuppliersListLoading,
  selectRfqFormFavoriteSuppliersListNext,
} from "@next/modules/workspace/redux/selectors";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete";
import {
  AutocompleteRenderInputParams,
  Box,
  Button,
  Skeleton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { uniqBy } from "lodash";
import CustomButton from "@next/components/custom-button";
import { RFQSupplierType } from "@next/modules/workspace/components/rfq-creation-form/rfq-creation.form.types";
import SupplierListItem, {
  GroupChip,
} from "@next/modules/workspace/components/rfq-creation-form/supplier-list-item";
import { selectAnalyticFilters } from "../../redux/selectors";
import { analyticsActions } from "../../redux";

type StyleProps = {
  paperWidth?: number | string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    button: {
      height: 40,
      marginLeft: theme.spacing(2),
    },
    paperComponent: {
      width: ({ paperWidth }) => paperWidth,
      marginLeft: "auto",
    },
    componentContainer: {
      padding: theme.spacing(2),
    },
    infoText: {
      color: theme.palette.action.active,
    },
  })
);

interface SuppliersAutoCompleteDropdownProps {
  onInvite?: () => void;
  pageSize?: number;
  showGroupsAndMarketplace?: boolean;
  getOptionDisabled?: Function;
  paperWidth?: number | string;
  multiple?: boolean;
  fieldName?: string;
  endAdornment?: React.ReactNode;
  fullWidth?: boolean;
}

const AnaltyticFiltersSuppliersAutoCompleteDropdown: React.FC<
  SuppliersAutoCompleteDropdownProps
> = ({
  pageSize = 12,
  onInvite,
  showGroupsAndMarketplace = true,
  getOptionDisabled,
  paperWidth = 680,
  multiple = true,
  endAdornment,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const favoriteSuppliersList = useSelector(selectRfqFormFavoriteSuppliersList);
  const nextPage = useSelector(selectRfqFormFavoriteSuppliersListNext);
  const loading = useSelector(selectRfqFormFavoriteSuppliersListLoading);
  const groups = useSelector(selectFavoriteSupplierGroups);
  const groupsLoading = useSelector(selectFavoriteSupplierGroupsLoading);
  const dispatch = useDispatch();
  const classes = useStyles({ paperWidth });

  const analyticFilters = useSelector(selectAnalyticFilters);

  const [showAllGroups, setShowAllGroups] = useState(false);

  const value: any = analyticFilters?.selectedSuppliers;

  const normalizedValue = useMemo(
    () =>
      value
        ? Array.isArray(value)
          ? value
          : favoriteSuppliersList.find((supplier) => supplier["pk"] === value?.pk)
        : undefined,
    [favoriteSuppliersList, value]
  );

  const onEndReached = useCallback(() => {
    if (nextPage) {
      dispatch(
        workspaceNextActions.fetchRFQFavoriteSuppliersRequest({
          url: nextPage,
        })
      );
    }
  }, [nextPage]);

  const onSearch = useCallback(
    (search) => {
      dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
      dispatch(
        workspaceNextActions.fetchRFQFavoriteSuppliersRequest({
          query: {
            page: 1,
            pageSize,
            name: search,
          },
        })
      );
    },
    [pageSize]
  );

  const onInitialFetch = useCallback(() => {
    dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
    dispatch(
      workspaceNextActions.fetchRFQFavoriteSuppliersRequest({
        query: { page: 1, pageSize },
      })
    );
  }, [pageSize]);

  const renderOption = useCallback(
    (option, { selected }) => (
      <SupplierListItem
        name={option.name}
        picture={option.picture}
        groups={option.groups}
        selected={selected}
        enableCheckbox
        avatarStyle={{ borderRadius: "4px" }}
      />
    ),
    []
  );

  const onSetValue = (newValue: any) => {
    dispatch(
      analyticsActions.setAnalyticFilters({
        ...analyticFilters,
        selectedSuppliers: newValue,
      })
    );
  };

  const onSuppliersFetched = useCallback(
    (groupSuppliers) => {
      onSetValue(uniqBy(value.concat(groupSuppliers), "pk") as any);
    },
    [value]
  );

  useEffect(() => {
    dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
    return () => {
      dispatch(workspaceNextActions.fetchRFQFavoriteSuppliersReset());
    };
  }, []);

  const maxDisplayedGroups = 20;

  const displayedGroups = useMemo(() => {
    return showAllGroups ? groups : groups.slice(0, maxDisplayedGroups);
  }, [groups, showAllGroups]);

  const GroupComponent = useMemo(() => {
    return (
      <>
        {!groupsLoading && groups.length === 0 ? null : (
          <Box className={classes.componentContainer}>
            {groupsLoading ? (
              <Skeleton width="100%" height={32} variant="rectangular" />
            ) : (
              <>
                <Box mb={1} mr={1}>
                  <Typography className={classes.infoText} variant="caption">
                    {t("workspaceNext:rfqDrawer:groups", {
                      count: groups.length,
                    })}
                  </Typography>
                </Box>
                {displayedGroups.map((group) => (
                  <GroupChip key={group.pk} group={group} onSuppliersFetched={onSuppliersFetched} />
                ))}
                {groups.length > maxDisplayedGroups && (
                  <CustomButton
                    sx={{
                      borderRadius: "16px",
                    }}
                    variant="outlined"
                    onMouseDown={() => setShowAllGroups(!showAllGroups)}
                  >
                    <Typography variant="caption">
                      {showAllGroups
                        ? t("workspaceNext:rfqDrawer:viewLess")
                        : t("workspaceNext:rfqDrawer:viewMore", {
                            count: groups.length - maxDisplayedGroups,
                          })}
                    </Typography>
                  </CustomButton>
                )}
              </>
            )}
          </Box>
        )}
      </>
    );
  }, [groups, groupsLoading, showAllGroups, displayedGroups, onSuppliersFetched]);

  const SuppliersListTitle = useMemo(() => {
    return (
      <Box ml={2} mt={2}>
        <Typography className={classes.infoText} variant="caption">
          {t("workspaceNext:rfqDrawer:suppliersInList", {
            count: favoriteSuppliersList.length,
          })}
        </Typography>
      </Box>
    );
  }, [favoriteSuppliersList]);

  const CustomComponent = useMemo(() => {
    return (
      <>
        {GroupComponent || null}
        {SuppliersListTitle}
      </>
    );
  }, [GroupComponent, SuppliersListTitle]);

  const getInputFieldValue = useCallback((value: any) => {
    return `${value.name}`;
  }, []);

  const renderTags = (tags: FavoriteSupplier[]) => {
    return <Box pl={1}>{tags.length > 1 ? tags.length + " suppliers" : tags?.[0]?.name}</Box>;
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={
          analyticFilters.selectedSuppliers.length === 0
            ? t("analytics:global:allSuppliers")
            : t("analytics:global:supplier")
        }
        placeholder={
          analyticFilters.selectedSuppliers.length === 0 ? t("analytics:global:allSuppliers") : ""
        }
      />
    );
  };

  return (
    <Box display="flex" width={fullWidth ? "100%" : "auto"}>
      <Box flex={1} width={fullWidth ? "100%" : "auto"}>
        <PaginatedAutoComplete
          value={normalizedValue}
          onSelectionChange={onSetValue}
          loading={loading}
          options={favoriteSuppliersList}
          onEndReached={onEndReached}
          onSearch={onSearch}
          onInitialFetch={onInitialFetch}
          renderOption={renderOption}
          getOptionSelected={(option: RFQSupplierType, value: RFQSupplierType) =>
            option.pk === value.pk
          }
          CustomComponent={showGroupsAndMarketplace ? CustomComponent : SuppliersListTitle}
          paperComponentClassName={classes.paperComponent}
          placeholder={t("workspaceNext:rfqDrawer:searchInList")}
          getOptionDisabled={getOptionDisabled}
          multiple={multiple}
          disableClearable={false}
          disableCloseOnSelect={multiple}
          getInputFieldValue={multiple ? undefined : getInputFieldValue}
          endAdornment={endAdornment}
          getOptionLabel={(option) => option.name || ""}
          style={{ width: !fullWidth ? "360px" : "auto" }}
          renderTags={renderTags}
          renderInput={renderInput}
        />
      </Box>
      {typeof onInvite === "function" ? (
        <Button
          className={classes.button}
          variant="contained"
          startIcon={<PersonAddIcon />}
          onClick={onInvite}
        >
          {t("workspaceNext:rfqDrawer:inviteSupplier")}
        </Button>
      ) : null}
    </Box>
  );
};

export default AnaltyticFiltersSuppliersAutoCompleteDropdown;
