import { Box, Chip, alpha, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";
import { TrendColor } from "./analytic-card";

export const RootBox = styled(Box)`
  font-family: "Space Grotesk";
  width: 100%;
  height: 124px;
  border-radius: 24px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  padding: 24px;
  border-radius: 24px;
  background-color: white;
`;

export const StyledTypography = styled(CustomTypography)`
  margin-top: 5px;
`;

type TrendChipProps = {
  isTrendUp: boolean;
  trendUpColor: TrendColor;
  trendDownColor: TrendColor;
};

export const TrendChip = styled(Chip, {
  shouldForwardProp: (prop) =>
    prop !== "isTrendUp" && prop !== "trendUpColor" && prop !== "trendDownColor",
})<TrendChipProps>(({ theme, isTrendUp, trendDownColor, trendUpColor }) => {
  const labelColor =
    isTrendUp && trendUpColor === "success"
      ? theme.palette.success.main
      : isTrendUp && trendUpColor === "error"
        ? theme.palette.error.main
        : !isTrendUp && trendDownColor === "success"
          ? theme.palette.success.main
          : !isTrendUp && trendDownColor === "error"
            ? theme.palette.error.main
            : theme.palette.primary.main;
  const backgroundColor = alpha(labelColor, 0.1);

  return {
    backgroundColor: backgroundColor,
    height: "24px",
    "& .MuiChip-label": {
      color: labelColor,
      fontSize: "12px",
    },
  };
});
