import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { SearchTextField } from "../../../search-text-field/search-text-field";
import { ReadinessDashboardSuppliersTable } from "../../../table/readiness-dashboard-suppliers-table/readiness-dashboard-suppliers-table";
import {
  selectSupplierFilter,
  selectSuppliersTableData,
} from "@next/modules/vendor-management/redux/selectors";
import {
  PaginationSizeForSpecs,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-supplier-view-suppliers-table-with-search.styled";

export const ReadinessDashboardSupplierViewSuppliersTableWithSearch = () => {
  const dispatch = useDispatch();
  const supplierFilter = useSelector(selectSupplierFilter);
  const suppliersTableData = useSelector(selectSuppliersTableData);
  const rowCount = suppliersTableData?.count;
  const suppliers = suppliersTableData?.results;
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const clearSearch = () => {
    setPage(1);
    setSearchValue("");
  };

  const handleSearchValueChange = (value: string) => {
    if (searchValue !== value) {
      setPage(1);
    }
    setSearchValue(value);
  };

  useEffect(() => {
    const supplierType =
      supplierFilter === "All Suppliers" ? "" : supplierFilter.toLocaleLowerCase();

    dispatch(
      vendorManagementActions.fetchSuppliersTableDataRequest({
        page: page,
        pageSize: PaginationSizeForSpecs,
        searchTerm: searchValue,
        supplierType: supplierType,
      })
    );
  }, [searchValue, page]);

  return (
    <S.StyledRootBox>
      <S.StyledTypography variant="h6">Tracking at supplier level</S.StyledTypography>

      <Box>
        <SearchTextField
          searchValue={searchValue}
          setSearchValue={handleSearchValueChange}
          clearSearch={clearSearch}
          placeholder="Search by supplier, vendor code or status..."
          width="300px"
        />
      </Box>

      <ReadinessDashboardSuppliersTable
        page={page}
        setPage={setPage}
        pageSize={PaginationSizeForSpecs}
        rows={suppliers}
        rowCount={rowCount}
      />
    </S.StyledRootBox>
  );
};
