import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, CircularProgress, Typography, styled } from "@mui/material";

const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
  padding: "0",
  justifyContent: "center",
});

export const ProgressCell: React.FC<GridRenderCellParams<any>> = ({ value }) => {
  return (
    <StyledBox>
      <CircularProgress
        variant="determinate"
        value={value}
        sx={{
          color: "#303030",
          position: "relative",
          width: "40px",
          height: "40px",
          "& .MuiCircularProgress-svg": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "24px",
            height: "24px",
          },
        }}
        thickness={2}
      />

      <Typography variant="caption" sx={{ marginLeft: "-8px" }}>
        {value + "%"}
      </Typography>
    </StyledBox>
  );
};
