import React, { useState } from "react";
import { FormControl, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  selectFormData,
  selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion,
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepApproved,
  selectIsFormStepCompleted,
  selectIsFormStepInReview,
  selectIsLastStep,
  selectIsSideDrawerOpen,
  selectIsThereAnyStepInReviewProcess,
  selectIsThereUnsavedChangesInFormStep,
  selectProcessControlStandards,
  selectSpecsOfScope,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { AddAirbusSpecificationField } from "./add-airbus-specification-field/add-airbus-specification-field";
import { AddInternationalStandardField } from "./add-international-standard-field/add-international-standard-field";
import { FormBoxLg } from "../../form-box/form-box-lg";
import { FormTitle } from "../../form-title/form-title";
import { FormFooter } from "../../form-footer/form-footer";
import { FormParentBox } from "../../form-parent-box";
import { FormFieldBox } from "../../form-field-box";
import { FormFieldLabel } from "../../form-field-label/form-field-label";
import { FormFieldErrorTypography } from "../../form-field-error-typography/form-field-error-typography";
import { SideDrawer } from "../../side-drawer/side-drawer";
import { FormBoxWrapper } from "../../form-box/form-box-wrapper";
import {
  A2psProcessControlFormData,
  FieldMandatoryText,
  PartialStep,
  QuestionnaireForms,
  VendorManagementModalTypes,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import { validateInternationalStandardTableRows } from "@next/modules/vendor-management/utils/validateInternationalStandardTableRows";
import { showCompletedMessage } from "@next/modules/vendor-management/utils/showCompletedMessage";
import { showInvalidMessage } from "@next/modules/vendor-management/utils/showInvalidMessage";
import { modalsActions } from "@next/redux/modalsSlices";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { showSavedMessage } from "@next/modules/vendor-management/utils/showSavedMessage";
import { showThreadsNeedToBeResolvedMessage } from "@next/modules/vendor-management/utils/showThreadsNeedToBeResolvedMessage";
import * as S from "./a2ps-process-control-form.styled";

export const A2psProcessControlForm: React.FC = () => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const activeFormStep = steps[activeFormStepIndex];
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormInReview = useSelector(selectIsFormStepInReview);
  const isFormApproved = useSelector(selectIsFormStepApproved);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isLastStep = useSelector(selectIsLastStep);
  const isAllStepsCompletedExceptBeforeCurrentStepCompletion = useSelector(
    selectIsAllStepsCompletedExceptBeforeCurrentStepCompletion
  );
  const isThereAnyStepInReview = useSelector(selectIsThereAnyStepInReviewProcess);
  const isFormFrozen = isFormApproved || isFormDisplayForValidationManagement;
  const isThereUnsavedChanges = useSelector(selectIsThereUnsavedChangesInFormStep);
  const isDrawerOpen = useSelector(selectIsSideDrawerOpen);
  const a2psProcessControlFormData = useSelector(selectFormData) as A2psProcessControlFormData;
  const tableData = useSelector(selectSpecsOfScope);
  const internalStandardFieldTableRows = useSelector(selectProcessControlStandards);
  const [isRenderOptionalFields, setIsRenderOptionalFields] = useState<boolean>(
    a2psProcessControlFormData?.has_captive_laboratory || false
  );
  const [isShowTableErrorsIfExist, setIsShowTableErrorsIfExist] = useState<boolean>(false);
  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
    setError,
  } = useForm<A2psProcessControlFormData>({
    defaultValues: a2psProcessControlFormData,
  });

  const handleRadioBtnChange = (value: boolean) => {
    checkInputChange();

    if (value) {
      setIsRenderOptionalFields(true);
    } else {
      setIsRenderOptionalFields(false);
    }
  };

  const saveChanges = (isForCompletion: boolean) => {
    const formValues = watch();
    dispatch(
      vendorManagementActions.updateFormDataRequest({
        data: formValues,
        formName: QuestionnaireForms.A2psProcessControl,
        companyId: companyId,
      })
    );

    const fieldOfStep = isForCompletion ? "is_completed" : "is_partially_filled";
    updateFieldStatusOfStepForSaveAndCompletion(fieldOfStep, true, activeFormStepIndex);
    if (!isForCompletion) {
      showSavedMessage();
    }
    dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(false));
  };

  const confirmStepCompletion: SubmitHandler<A2psProcessControlFormData> = (data) => {
    if (activeFormStep?.unresolved_threads_count !== 0) {
      showThreadsNeedToBeResolvedMessage();
      return;
    }

    const isHaveCaptiveLaboratory = data.has_captive_laboratory;

    if (isHaveCaptiveLaboratory) {
      const isAirbusTableValidated = tableData?.results?.uncompleted_count === 0;
      const isInternalStandardTableValidated = validateInternationalStandardTableRows(
        internalStandardFieldTableRows
      );
      if (isAirbusTableValidated && isInternalStandardTableValidated) {
        makeFormStepCompletionProcess();
      } else {
        if (!isShowTableErrorsIfExist) {
          setIsShowTableErrorsIfExist(true);
        }
        showInvalidMessage();
      }
    } else if (isHaveCaptiveLaboratory === false) {
      makeFormStepCompletionProcess();
    } else {
      setError("has_captive_laboratory", { type: "manual" });
      showInvalidMessage();
    }
  };

  const makeFormStepCompletionProcess = () => {
    saveChanges(true);
    showCompletedMessage();
    if (isAllStepsCompletedExceptBeforeCurrentStepCompletion) {
      showThankYouModal();
    } else if (!isLastStep && !isThereAnyStepInReview) {
      routeNextStep();
    }
  };

  const routeNextStep = () => {
    const newActiveStepIndex = activeFormStepIndex + 1;
    setActiveFormStepIndex(newActiveStepIndex);
  };

  const setActiveFormStepIndex = (index: number) => {
    updateFieldStatusOfStepForSaveAndCompletion("is_active", true, index);
  };

  const showThankYouModal = () => {
    dispatch(
      modalsActions.showModal({
        key: VendorManagementModalTypes.THANK_YOU_MODAL,
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false, activeFormStepIndex);
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    } else if (!isThereUnsavedChanges) {
      dispatch(vendorManagementActions.setIsThereUnsavedChangesInFormStep(true));
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled" | "is_active",
    value: boolean,
    stepIndex: number
  ) => {
    const step = steps[stepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_done")
            : key === "is_completed" && !value
              ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
              : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (
    statusParam: "changes_done" | "changes_requested"
  ) => {
    if (
      isFormInReview &&
      activeFormStep?.unresolved_threads_count === 0 &&
      statusParam === "changes_done"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    } else if (
      activeFormStep?.approval_status === "changes_done" &&
      statusParam === "changes_requested"
    ) {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: activeFormStep.step,
          status: statusParam,
        })
      );
    }
  };

  const onInvalid = () => {
    showInvalidMessage();
  };

  return (
    <>
      <FormParentBox>
        <FormBoxWrapper isDrawerOpen={isDrawerOpen}>
          <FormBoxLg
            isCompleted={isFormCompleted}
            isApproved={isFormApproved}
            isInReview={isFormInReview && step?.unresolved_threads_count !== 0}
          >
            <FormTitle
              title={"A2PS' Tests & Methods"}
              isCompleted={isFormCompleted}
              isApproved={isFormApproved}
              isInReview={isFormInReview && step?.unresolved_threads_count !== 0}
            />

            <S.StyledInnerBox>
              <FormControl
                fullWidth
                margin="none"
                error={errors.has_captive_laboratory ? true : false}
              >
                <FormFieldBox>
                  <FormFieldLabel label="Do you have a captive laboratory to support A2PS's process control or qualification testing, including tests performed in production?" />

                  <Controller
                    name="has_captive_laboratory"
                    control={control}
                    render={({ field }) => (
                      <>
                        <S.StyledRadioGroup
                          {...field}
                          value={field?.value === null ? "" : field.value}
                          onChange={(e) => {
                            const value = e.target.value === "true" ? true : false;
                            field.onChange(value);
                            handleRadioBtnChange(value);
                          }}
                        >
                          <S.StyledFormControlLabel
                            value="true"
                            control={<S.StyledRadio />}
                            label="Yes"
                            disabled={isFormFrozen}
                          />
                          <S.StyledFormControlLabel
                            value="false"
                            control={<S.StyledRadio />}
                            label="No"
                            disabled={isFormFrozen}
                          />
                        </S.StyledRadioGroup>
                        {errors.has_captive_laboratory ? (
                          <FormFieldErrorTypography text={FieldMandatoryText} />
                        ) : null}
                      </>
                    )}
                  />
                </FormFieldBox>
              </FormControl>

              {isRenderOptionalFields ? (
                <>
                  <AddAirbusSpecificationField
                    isShowTableErrorsIfExist={isShowTableErrorsIfExist}
                    isTableDisabled={isFormFrozen}
                  />

                  <AddInternationalStandardField isTableDisabled={isFormFrozen} />
                </>
              ) : null}
            </S.StyledInnerBox>

            <FormControl margin="none">
              <Controller
                name="additional_comment"
                control={control}
                defaultValue={a2psProcessControlFormData?.additional_comment || ""}
                render={({ field: { onChange, value } }) => (
                  <S.StyledFormFieldBox>
                    <TextField
                      sx={{ width: "100%" }}
                      multiline
                      rows={3}
                      placeholder="Additional comment..."
                      value={value || ""}
                      onChange={(e) => {
                        onChange(e);
                        checkInputChange();
                      }}
                      InputProps={{
                        readOnly: isFormFrozen,
                      }}
                    />
                  </S.StyledFormFieldBox>
                )}
              />
            </FormControl>
          </FormBoxLg>
        </FormBoxWrapper>

        <SideDrawer isThreadful={true} />
      </FormParentBox>

      <FormFooter
        saveChanges={() => saveChanges(false)}
        confirmStepCompletion={handleSubmit(confirmStepCompletion, onInvalid)}
        isCompleted={isFormCompleted}
        isApproved={isFormApproved}
        isInReview={isFormInReview && step?.unresolved_threads_count !== 0}
        isShowReviewButton={!watch("has_captive_laboratory")}
      />
    </>
  );
};
