import axios from "axios";
import PQueue from "p-queue";

import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import { safeCall } from "@next/redux/safeCall";
import { workspaceNextService } from "../services/workspace-services";
import { workspaceNextActions } from "./slices";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  AddAddendaInput,
  AddFavoriteSupplierInput,
  AnswerTopicInput,
  ApprovedSupplierInput,
  BuyerContractsTabType,
  CheckEmailAlreadyExistsInput,
  ContactUsInput,
  ContractMarkAsCompleteInput,
  CreateDiscordNofityInput,
  CreateEasyRfqInput,
  CreateFavoriteSupplierGroupInput,
  CreatePortalQuoteInput,
  CreateTopicInput,
  CustomQuotationExportInput,
  DeletePortalQuoteFileInput,
  DraftRFQ,
  DraftRFQData,
  ExportBRPFilesInput,
  ExportQuotesComparisonSheetInput,
  FavoriteSupplier,
  FetchContractsInput,
  FetchDraftRFQsInput,
  FetchFavoriteSupplierInput,
  FetchInternalChatTopicInput,
  FetchMyPartsInput,
  FetchPartsInput,
  FetchPortalDataInput,
  FetchPresignedS3Input,
  FetchQAThreadDetailsInput,
  FetchQuoteComparisonInput,
  FetchRFQFavoriteSuppliersInput,
  FetchRFQQuestionsStatsInput,
  FetchSupplierContactsList,
  FetchSupplierQuotesInput,
  FetchTopicInput,
  FetchTopicsInput,
  FileChunkEntry,
  FileChunkUploadProgress,
  FileChunkUploadSuccess,
  FileContentType,
  GlobalSearchInput,
  HandleS3MultipartUploadInput,
  InviteSupplierContactInput,
  InviteSupplierInput,
  LoadDraftRFQInput,
  PostInternalChatMessageInput,
  PresignedS3Url,
  ProceedQuoteComparisonSelectionInput,
  QaMarkAsReadInput,
  SendAirbusConfirmationInput,
  SendEmailConfirmationInput,
  SendNewVerificationEmailInput,
  SendRevisionNoteInput,
  StepsAfterCheckEmailAlreadyExists,
  SyncERPQuotationInput,
  UpdateDraftRFQCollaboratorsInput,
  UpdateNDAInput,
  UpdatePortalQuoteInput,
  UpdateQuoteComparisonConfigInput,
  UpdateQuoteNoQuoteStatusInput,
  UpdateRFQCollaboratorsInput,
  UpdateRFQFollowupDateInput,
  UpdateRFQIsPublicInput,
  UpdateRFQTargetedProfilesInput,
  UpdateSupplierContactsListInput,
  UploadAddendaAttachmentsInput,
  UploadPortalQuoteFilesInput,
  UploadQuotationAsBuyerInput,
  UploadQuotationFilesAsBuyerInput,
  UploadRfqAttachmentsInput,
  WorkspaceNextState,
  payloadPartData,
  requestDataTypes,
  uploadMultiplePartFilesInput,
  uploadSinglePartFileInput,
} from "./types";
import { modalsActions } from "@next/redux/modalsSlices";
import { WorkspaceModalTypes } from "../modals/types";
import { t } from "assets/configi18n/i18n";
import { profileActions } from "services/profile";
import { downloadFileNext, getFileChunk } from "@next/utils/fileUtils";
import {
  selectActiveDraftRFQ,
  selectContractsLastQuery,
  selectFavoriteSuppliers,
  selectMyPartsLastQuery,
  selectQuoteComparisonLastQueryRfqId,
} from "./selectors";
import {
  ExportMyPartsInput,
  ImportMyPartsInput,
  ProjectCompanyPart,
  ProjectCreatePartInput,
  ProjectType,
  ProjectUpdatePartInput,
  projectActions,
  refetchCompanyOrders,
} from "@next/modules/project/redux";
import { UserRatingActionTypes } from "services/rating/rating.constants";
import { SharedModalTypes } from "@next/modals/types";
import { projectService } from "@next/modules/project/services/projectService";
import { workspaceActions, workspaceApi, workspaceConstants } from "services/workspace";
import { GenericObject } from "@next/modules/profile/redux";
import { GenericPaginatedData } from "@next/utils/typeUtils";
import {
  selectProjectCompanyOrdersLastQuery,
  selectProjectCompanyPart,
  selectProjectCompanyPartsLastQuery,
  selectProjectStats,
} from "@next/modules/project/redux/selectors";
import { selectFileUploadProgress } from "@next/modules/workspace/redux/selectors";
import { store } from "store";
import { backendUrl, frontendUrl } from "urls";
import { fileDownloadFromUrl } from "@next/utils/downloadUtils";
import { createRedirectTabUrl } from "@next/utils/browserUtils";
import { marketplaceActions } from "services/marketplace";
import { CustomFileObject } from "@next/components/multiple-dnd-file-upload/multiple-dnd-file-upload.types";
import { genericErrorHandler } from "@next/utils/apiUtils";
import { history } from "helpers/history";
import { getToken } from "services/profile/profile.selectors";
import { getQueryParams } from "@next/utils/miscUtils";
import { ordersActions } from "@next/modules/orders/redux";
import { END } from "redux-saga";
import { createEventChannel } from "@next/utils/sagaUtils";
import { getFileUploadProgress } from "@next/utils/fileUtils";
import { createErrorMessage } from "helpers/createErrorMessage";
import snackbarUtils from "@next/utils/snackbarUtils";
import { selectRFQPart } from "services/workspace/workspace.selectors";
import { selectOrderPartByPk } from "@next/modules/orders/redux/selectors";

/**
 * Fetches presigned S3 URL from Backend.
 */
export function* handleFetchPresignedS3Request({ payload }: PayloadAction<FetchPresignedS3Input>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchPresignedS3, payload);

  if (error) {
    yield put(workspaceNextActions.fetchPresignedUrlFailure(error));
    return { error, response: null };
  } else {
    yield put(workspaceNextActions.fetchPresignedUrlSuccess(response.data));
    return { error: null, response: response.data };
  }
}

/**
 * Monitors file upload progress from chan and dispatches state update action
 */
function* watchFileUploadProgress(chan: any) {
  while (true) {
    const data: FileChunkUploadProgress = yield take(chan);
    yield put(workspaceNextActions.updateFileUploadProgress(data));
  }
}

/**
 * Handles the success event of a file chunk upload by checking if the
 * upload progress has reached 100%, and signals completion if so.
 */
function* handleFileChunkUploadSuccess({ payload }: PayloadAction<FileChunkUploadSuccess>) {
  const fileUploadProgress = yield select(selectFileUploadProgress);
  const uploadProgressPercent = getFileUploadProgress(fileUploadProgress, payload.fileName);

  // If file upload reaches 100%, signal the emitter that the upload is complete.
  if (uploadProgressPercent === 100) {
    payload.emitter(END);
  }
}

/**
 * Uploads a chunk of a file to a presigned URL and tracks its upload progress.
 * Emits progress events and dispatches a success action when the chunk upload completes.
 */
async function uploadFileChunk(
  file: FileChunkEntry,
  event_emitter: any
): Promise<{ etag: string; part_number: number }> {
  try {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const response = await axios.put(file.url, file.fileChunk, {
      onUploadProgress: (progressEvent) => {
        event_emitter({
          fileName: file.name,
          partNumber: file.partNumber,
          uploaded: progressEvent.loaded,
          source,
        });
      },
      cancelToken: source.token,
    });

    // Dispatch success upon file completion
    store.dispatch(
      workspaceNextActions.fileChunkUploadSuccess({
        fileName: file.name,
        emitter: event_emitter,
      })
    );

    return {
      etag: response.headers["etag"],
      part_number: file.partNumber,
    };
  } catch (error) {
    throw new Error(`Failed to upload file part`);
  }
}

/**
 * Uploads file chunks concurrently with a concurrency limit,
 * using a priority queue and an event channel to monitor progress.
 */
function* uploadFileChunks(originalFile: File, files: FileChunkEntry[]): any {
  const queue = new PQueue({ concurrency: 3 });

  // Dispatch an action to set the initial state for file upload progress
  yield put(
    workspaceNextActions.updateFileUploadProgress({
      fileName: originalFile.name,
      partNumber: 0,
      uploaded: 0,
      total: originalFile.size,
      pqueue: queue,
      source: null,
    })
  );

  // Create an event channel and fork a saga to watch for upload progress messages
  const [emitter, channel] = createEventChannel();
  yield fork(watchFileUploadProgress, channel);

  // Map each file chunk to a promise created by adding the upload task to the queue
  const uploadPromises = files.map((file) => queue.add(() => uploadFileChunk(file, emitter)));

  // Wait for all upload promises to resolve.
  return yield all(uploadPromises);
}

/**
 * Handles multipart upload to S3 by fetching presigned URLs, uploading file chunks, and finalizing the upload.
 * Invokes an onSuccess callback upon successful upload.
 */
function* handleS3MultipartUpload({
  file,
  onSuccess,
  instanceId,
  contentType,
}: HandleS3MultipartUploadInput): any {
  try {
    // Fetch presigned URLs from the server
    const presignedResult: PresignedS3Url = yield call(workspaceNextService.fetchPresignedS3, {
      file_name: file.name,
      file_size: file.size,
      file_type: file.type || "application/octet-stream",
      instance_id: instanceId,
      content_type: contentType,
    });

    // Prepare data for file parts upload
    const fileChunksData = presignedResult.presigned_urls.map(({ url, part_number }) => ({
      name: file.name,
      url,
      partNumber: part_number,
      fileChunk: getFileChunk(file, part_number, presignedResult.presigned_urls.length),
    }));

    // Upload file parts
    const uploadFileChunksResult = yield call(uploadFileChunks, file, fileChunksData);

    // Finalize the multipart upload
    const finalizationResult = yield call(workspaceNextService.finishMultipartS3Upload, {
      parts: uploadFileChunksResult,
      file_path: presignedResult.file_path,
      upload_id: presignedResult.upload_id,
    });

    // Call onSuccess callback with the final URL
    const onSuccessResponse = yield call(onSuccess, finalizationResult.data.url);

    return { response: onSuccessResponse, error: null };
  } catch (error) {
    return { response: null, error: error };
  }
}

function* handleFetchQATopicsRequest({ payload }: PayloadAction<FetchTopicsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchQATopics, payload);

  if (error) {
    yield put(workspaceNextActions.fetchQATopicsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchQATopicsSuccess(response.data));
  }
}

function* handleFetchQATopicRequest({ payload }: PayloadAction<FetchTopicInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchQATopic, payload);

  if (error) {
    yield put(workspaceNextActions.fetchQATopicFailure(error));
  } else {
    yield put(workspaceNextActions.fetchQATopicSuccess(response.data));
  }
}

function* handleFetchInternalChatTopicRequest({
  payload,
}: PayloadAction<FetchInternalChatTopicInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchInternalChatTopic, payload);

  if (error) {
    yield put(workspaceNextActions.fetchInternalChatTopicFailure(error));
  } else {
    yield put(workspaceNextActions.fetchInternalChatTopicSuccess(response.data));
  }
}

function* handleCreateQATopicRequest({ payload }: PayloadAction<CreateTopicInput>) {
  const { response, error } = yield safeCall(workspaceNextService.createQATopic, payload);

  if (error) {
    yield put(workspaceNextActions.createQATopicFailure(error));
  } else {
    yield put(modalsActions.closeModal(WorkspaceModalTypes.ASK_QUESTION_MODAL));

    const { qaTopics } = yield select((state) => state.workspaceNext);
    const ix =
      qaTopics?.findIndex((topic: Record<string, any>) => topic.pk === response.data?.pk) ?? -1;

    yield put(workspaceNextActions.createQATopicSuccess(response.data));
    if (ix === -1) yield put(marketplaceActions.incrementRfqQuestionsCount());

    yield snackbarUtils.success(t("workspaceNext:askQuestion:questionSuccess"));
  }
}

function* handleAnswerQATopicRequest({ payload }: PayloadAction<AnswerTopicInput>) {
  const { response, error } = yield safeCall(workspaceNextService.answerQATopic, payload);
  if (error) {
    yield put(workspaceNextActions.answerQATopicFailure(error));
  } else {
    yield put(workspaceNextActions.answerQATopicSuccess(response.data));

    const isFilesError = !!response.data.filesError;
    if (isFilesError) {
      yield snackbarUtils.error(t("workspace:fileUploadFailed"));
    }

    if (payload.showToastOnMessage) {
      yield snackbarUtils.success(t("workspaceNext:QA:answerSuccess"));
    }
  }
}

function* handlePostInternalChatMessageRequest({
  payload,
}: PayloadAction<PostInternalChatMessageInput>) {
  const { response, error } = yield safeCall(workspaceNextService.postInternalChatMessage, payload);

  if (error) {
    yield put(workspaceNextActions.postInternalChatMessageFailure(error));
  } else {
    yield put(workspaceNextActions.postInternalChatMessageSuccess(response.data));
  }
}

function* handleQaMarkAsRead({ payload }: PayloadAction<QaMarkAsReadInput>) {
  const { error } = yield safeCall(workspaceNextService.qaMarkAsRead, payload);

  if (error) {
    yield put(workspaceNextActions.qaMarkAsReadFilure(error));
  } else {
    const { qaTopic, qaTopics, qaThreadDetails } = yield select((state) => state.workspaceNext);
    let count = 0;
    if (qaTopic?.pk === payload.qaId) {
      count = qaTopic.unseen_messages_count;
    } else if (qaThreadDetails?.pk === payload.qaId) {
      count = qaThreadDetails.unseen_messages_count;
    } else {
      const ix = qaTopics?.findIndex((item: any) => item.pk === payload.qaId) ?? -1;
      if (ix !== -1) count = qaTopics[ix].unseen_messages_count;
    }
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield put(workspaceNextActions.qaMarkAsReadSuccess(payload));
    if (count > 0) {
      yield put(profileActions.decrementCompanyStatsNewQaMsgCount(count));
      yield put(workspaceActions.decrementRFQNewQaMsgCount(count));
      yield put(marketplaceActions.decrementRFQNewQaMsgCount(count));
    }
  }
}

function* handleContactUsRequest({ payload }: PayloadAction<ContactUsInput>) {
  const { error } = yield safeCall(workspaceNextService.contactUs, payload);

  if (error) {
    yield put(workspaceNextActions.contactUsFailure(error));
  } else {
    yield put(workspaceNextActions.contactUsSuccess({}));
    yield put(modalsActions.closeModal(WorkspaceModalTypes.CONTACT_US_MODAL));
    yield snackbarUtils.success(t("workspace:thankYouYourMessageHasBeenSent"));
  }
}

function* handleCustomQuotationExportRequest({
  payload,
}: PayloadAction<CustomQuotationExportInput>) {
  const { response, error } = yield safeCall(workspaceNextService.customQuotationExport, payload);

  if (error) {
    yield put(workspaceNextActions.customQuotationExportFailure(error));
  } else {
    if (response.data instanceof Blob) {
      downloadFileNext(response.data, payload?.fileName, "xlsx");
      yield put(workspaceNextActions.customQuotationExportSuccess());
    } else {
      yield put(workspaceNextActions.customQuotationExportFailure(error));
    }
  }
}

function* handleInviteSupplierRequest({ payload }: PayloadAction<InviteSupplierInput>) {
  const { error } = yield safeCall(workspaceNextService.inviteSupplier, payload);

  if (error) {
    yield put(workspaceNextActions.inviteSupplierFailure(error));
  } else {
    yield put(workspaceNextActions.inviteSupplierSuccess({}));
    yield put(modalsActions.closeModal(WorkspaceModalTypes.INVITE_SUPPLIER_MODAL));
    yield snackbarUtils.success(t("workspaceNext:inviteSupplier:success"));
  }
}

function* handleFetchNDAListRequest() {
  const { response, error } = yield safeCall(workspaceNextService.fetchNDAList);

  if (error) {
    yield put(workspaceNextActions.fetchNDAListFailure(error));
  } else {
    yield put(workspaceNextActions.fetchNDAListSuccess(response.data));
  }
}

function* handleUpdateNDARequest({ payload }: PayloadAction<UpdateNDAInput>) {
  const { response, error } = yield safeCall(workspaceNextService.updateNDA, payload);

  if (error) {
    yield put(workspaceNextActions.updateNDAFailure(error));
  } else {
    yield put(workspaceNextActions.updateNDASuccess(response.data));

    yield snackbarUtils.success(t("workspaceNext:ndaTable:row:updateNDASuccess"));
  }
}

function* handleFetchPartsRequest(action?: PayloadAction<FetchPartsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchPartsList, action?.payload);

  if (error) {
    yield put(workspaceNextActions.fetchPartsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchPartsSuccess(response.data));
  }
}

function* handleFetchFavoriteSuppliersRequest({
  payload,
}: PayloadAction<FetchFavoriteSupplierInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchFavoriteSuppliers, payload);

  if (error) {
    yield put(workspaceNextActions.fetchFavoriteSuppliersFailure(error));
  } else {
    yield put(
      workspaceNextActions.fetchFavoriteSuppliersSuccess({
        results: Array.isArray(response.data) ? response.data : response.data.results,
        count: response.data.count,
      })
    );
  }
}

function* handleFetchRFQFavoriteSuppliersRequest({
  payload,
}: PayloadAction<FetchRFQFavoriteSuppliersInput>) {
  const { response, error } = yield safeCall(
    workspaceNextService.fetchFavoriteSuppliersList,
    payload
  );

  if (error) {
    yield put(workspaceNextActions.fetchRFQFavoriteSuppliersFailure(error));
  } else {
    yield put(
      workspaceNextActions.fetchRFQFavoriteSuppliersSuccess({
        data: response.data,
        distributionForSupplierContacts: payload?.distributionForSupplierContacts,
      })
    );
  }
}

function* handleUpdateFavoriteSupplierRequest({
  payload,
}: PayloadAction<Partial<FavoriteSupplier>>) {
  const { response, error } = yield safeCall(workspaceNextService.updateFavoriteSupplier, payload);

  if (error) {
    yield put(workspaceNextActions.updateFavoriteSupplierFailure(error));
  } else {
    yield put(workspaceNextActions.updateFavoriteSupplierSuccess(response.data));

    yield snackbarUtils.success(t("common:succesfullyUpdated"));
  }
}

function* handleRemoveFavoriteSupplierRequest({ payload }: PayloadAction<number>) {
  const { error } = yield safeCall(workspaceNextService.removeFavoriteSupplier, payload);
  if (error) {
    yield put(workspaceNextActions.removeFavoriteSupplierFailure(error));
  } else {
    yield put(workspaceNextActions.removeFavoriteSupplierSuccess(payload));
    yield snackbarUtils.success(t("common:succesfullyRemoved"));
  }
}

function* handleAddFavoriteSupplierRequest({ payload }: PayloadAction<AddFavoriteSupplierInput>) {
  const { response, error } = yield safeCall(workspaceNextService.addFavoriteSupplier, payload);

  if (error) {
    yield put(workspaceNextActions.addFavoriteSupplierFailure(error));
  } else {
    yield put(workspaceNextActions.addFavoriteSupplierSuccess(response.data));
    yield snackbarUtils.success(t("common:succesfullyAdded"));
  }
}

function* handleFetchFavoriteSupplierGroupsRequest({ payload }: PayloadAction) {
  const { response, error } = yield safeCall(
    workspaceNextService.fetchFavoriteSupplierGroups,
    payload
  );

  if (error) {
    yield put(workspaceNextActions.fetchFavoriteSupplierGroupsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchFavoriteSupplierGroupsSuccess(response.data));
  }
}

function* handleCreateFavoriteSupplierGroupRequest({
  payload: { groupName, assignSupplierPk, onSuccess },
}: PayloadAction<CreateFavoriteSupplierGroupInput>) {
  const { response, error } = yield safeCall(
    workspaceNextService.createFavoriteSupplierGroup,
    groupName
  );

  if (error) {
    yield put(workspaceNextActions.createFavoriteSupplierGroupFailure(error));
  } else {
    try {
      if (assignSupplierPk) {
        const favoriteSuppliers: FavoriteSupplier[] = yield select(selectFavoriteSuppliers);
        let favoriteSupplier = favoriteSuppliers.find((item) => item.pk === assignSupplierPk);

        if (!favoriteSupplier) {
          const { response, error } = yield safeCall(
            workspaceNextService.fetchSingleFavoriteSupplier,
            { pk: assignSupplierPk }
          );
          if (error) {
            throw error;
          }
          favoriteSupplier = response.data;
          yield put(
            workspaceNextActions.fetchFavoriteSuppliersSuccess({
              results: [...favoriteSuppliers!, favoriteSupplier!],
              count: favoriteSuppliers.length + 1,
            })
          );
        }

        if (favoriteSupplier) {
          yield put(
            workspaceNextActions.updateFavoriteSupplierRequest({
              pk: assignSupplierPk,
              groups: [response.data, ...favoriteSupplier?.groups]?.map((item) => item.pk),
            })
          );
        }
      }

      if (onSuccess) onSuccess(response.data);

      yield put(workspaceNextActions.createFavoriteSupplierGroupSuccess(response.data));

      yield snackbarUtils.success(t("common:succesfullyUpdated"));
    } catch (error) {
      yield put(workspaceNextActions.createFavoriteSupplierGroupFailure(error as Error));
    }
  }
}

function* refetchFavoriteSupplierGroups() {
  yield put(workspaceNextActions.fetchFavoriteSupplierGroupsRequest());
}

function* handleFetchDraftRFQsRequest({ payload }: PayloadAction<FetchDraftRFQsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchDraftRFQs, payload);

  if (error) {
    yield put(workspaceNextActions.fetchDraftRFQsFailure(error));
  } else {
    yield put(
      workspaceNextActions.fetchDraftRFQsSuccess({
        reset: payload.query?.page === 1,
        data: response.data as GenericPaginatedData<DraftRFQ>,
      })
    );
  }
}

function* handleSaveDraftRFQ({ payload }: PayloadAction<DraftRFQData>) {
  const activeDraftRFQ: WorkspaceNextState["activeDraftRFQ"] = yield select(selectActiveDraftRFQ);

  const newData: Record<string, any> = {
    ...(activeDraftRFQ?.data || {}),
    ...payload,
  };

  const { response, error } = yield activeDraftRFQ
    ? safeCall(workspaceNextService.updatedDraftRFQ, activeDraftRFQ.id, newData)
    : safeCall(workspaceNextService.createDraftRFQ, newData);

  if (error) {
    yield put(workspaceNextActions.saveDraftRFQFailure(error));
  } else {
    yield put(workspaceNextActions.saveDraftRFQSuccess(response.data));
  }
}

function* handleDeleteRFQRequest({ payload }: PayloadAction<number>) {
  const { error } = yield safeCall(workspaceNextService.removeDraftRFQ, payload);

  if (error) {
    yield put(workspaceNextActions.deleteDraftRFQFailure(error));
  } else {
    yield put(workspaceNextActions.deleteDraftRFQSuccess(payload));
    yield put(modalsActions.closeModal(WorkspaceModalTypes.RFQ_CREATION_DRAWER));
    yield put(modalsActions.closeModal(WorkspaceModalTypes.RFQ_DELETE_CONFIRMATION_MODAL));
  }
}

function* handleLoadDraftRFQRequest({ payload }: PayloadAction<LoadDraftRFQInput>) {
  const { response, error } = yield safeCall(workspaceNextService.getDraftRFQ, payload.id);

  if (error) {
    yield put(workspaceNextActions.loadDraftRFQFailure(error));
  } else {
    yield put(workspaceNextActions.loadDraftRFQSuccess(response.data));

    yield put(
      modalsActions.showModal({
        key: WorkspaceModalTypes.RFQ_CREATION_DRAWER,
        data: {
          form: response.data?.data || {},
        },
      })
    );
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleUpdateDraftRFQCollaboratorsRequest({
  payload,
}: PayloadAction<UpdateDraftRFQCollaboratorsInput>) {
  const { response, error } = yield safeCall(
    workspaceNextService.updateDraftRFQCollaborators,
    payload
  );

  if (error) {
    yield put(workspaceNextActions.updateDraftRFQCollaboratorsFailure(error));
  } else {
    yield put(workspaceNextActions.updateDraftRFQCollaboratorsSuccess(response.data));
    yield snackbarUtils.toast(t("workspaceNext:collaboratorsModal:success"));
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleCreateDiscordNotifyRequest({ payload }: PayloadAction<CreateDiscordNofityInput>) {
  const { error } = yield safeCall(workspaceNextService.createDiscordNotify, payload);

  if (error) {
    yield put(workspaceNextActions.createDiscordNotifyFailure(error));
  } else {
    yield put(workspaceNextActions.createDiscordNotifySuccess());

    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleFetchContractsRequest({ payload }: PayloadAction<FetchContractsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchContracts, payload);

  if (error) {
    yield put(workspaceNextActions.fetchContractsFailure(error));
  } else {
    yield put(
      workspaceNextActions.fetchContractsSuccess({
        results: response.data.results,
        count: response.data.count,
      })
    );
  }
}

function* refetchContracts() {
  const lastQuery: string = yield select(selectContractsLastQuery);

  yield put(workspaceNextActions.fetchContractsRequest({ query: lastQuery }));
  yield put(modalsActions.closeModal(SharedModalTypes.USER_RATING_MODAL));
}

function* handleFetchMyPartsRequest({ payload }: PayloadAction<FetchMyPartsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchMyParts, payload);

  if (error) {
    yield put(workspaceNextActions.fetchMyPartsFailure(error));
  } else {
    yield put(
      workspaceNextActions.fetchMyPartsSuccess({
        results: response.data.results,
        count: response.data.count,
      })
    );
  }
}

function* handleExportMyPartsRequest({ payload }: PayloadAction<ExportMyPartsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.exportMyParts, payload);

  if (error) {
    yield put(workspaceNextActions.exportMyPartsFailure(error));
  } else {
    if (response.data instanceof Blob) {
      downloadFileNext(
        response.data,
        `${payload?.code?.replaceAll(".", "_") || "WORKSPACE"}_PARTS`
      );
      yield put(workspaceNextActions.exportMyPartsSuccess());
    } else {
      yield put(workspaceNextActions.exportMyPartsFailure(error));
    }
  }
}

function* handleImportMyPartsRequest({ payload }: PayloadAction<ImportMyPartsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.importMyParts, payload);

  if (error) {
    yield put(workspaceNextActions.importMyPartsFailure(error));
  } else {
    const successParts = response?.data?.success?.map(
      (item: { data: ProjectCompanyPart; msg: string; operation: string }) => item?.data
    );
    const successPartsMessages = response?.data?.success?.map(
      (item: { data: ProjectCompanyPart; msg: string; operation: string }) => {
        return `${item?.data?.name} ${item?.operation}`;
      }
    );

    yield put(workspaceNextActions.importMyPartsSuccess(successParts));

    if (successPartsMessages?.length > 0) {
      yield snackbarUtils.success(successPartsMessages.join(", "));
    }
  }
}

function* handleDeletePartRequest({ payload }: PayloadAction<any>) {
  const { error } = yield safeCall(projectService.deleteProjectPart, payload);

  if (error) {
    yield put(workspaceNextActions.deletePartFailure(error));
  } else {
    yield put(workspaceNextActions.deletePartSuccess(payload));

    yield snackbarUtils.success(`${t("workspace:yourPartHasBeenSuccessfullyDeleted")}`);
  }
}

function* handleClonePartRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(projectService.cloneProjectPart, payload);

  if (error) {
    yield put(workspaceNextActions.clonePartFailure(error));
  } else {
    yield put(workspaceNextActions.clonePartSuccess(response.data));

    yield snackbarUtils.success(`${t("workspace:yourPartHasBeenSuccessfullyDuplicated")}`);
  }
}

function* handleUpdatePartRequest({ payload }: PayloadAction<Partial<ProjectUpdatePartInput>>) {
  const { response, error } = yield safeCall(projectService.updateProjectPart, payload);

  if (error) {
    yield put(workspaceNextActions.updatePartFailure(error));
  } else {
    yield put(workspaceNextActions.updatePartSuccess(response.data));

    yield put(modalsActions.closeModal(WorkspaceModalTypes.PART_MODAL));

    yield snackbarUtils.success(`${t("workspace:yourPartHasBeenSuccessfullyUpdated")}`);
  }
}

function* handleCreatePartRequest({ payload }: PayloadAction<Partial<ProjectCreatePartInput>>) {
  const { response, error } = yield safeCall(projectService.createProjectPart, payload);

  if (error) {
    yield put(workspaceNextActions.createPartRequest(error));
  } else {
    yield put(workspaceNextActions.createPartSuccess(response.data));

    yield put(modalsActions.closeModal(WorkspaceModalTypes.PART_MODAL));

    yield snackbarUtils.success(`${t("workspace:yourPartHasBeenSuccessfullyCreated")}`);
  }
}

function* refetchMyPartsAndOrders() {
  const projectCompanyOrdersLastQuery: string = yield select(selectProjectCompanyOrdersLastQuery);

  if (projectCompanyOrdersLastQuery) {
    yield put(
      projectActions.fetchProjectCompanyOrdersRequest({
        query: projectCompanyOrdersLastQuery,
      })
    );
  }

  const isProjects = window.location.pathname.includes(frontendUrl.projects);

  if (isProjects) {
    const projectCompanyPartsLastQuery: string = yield select(selectProjectCompanyPartsLastQuery);
    const projectStats: ProjectType = yield select(selectProjectStats);

    if (projectCompanyPartsLastQuery && projectStats?.code) {
      yield put(
        projectActions.fetchProjectCompanyPartsRequest({
          query: projectCompanyPartsLastQuery,
          projectCode: projectStats?.code,
        })
      );
    }
  } else {
    const myPartsLastQuery: string = yield select(selectMyPartsLastQuery);

    if (myPartsLastQuery) {
      yield put(workspaceNextActions.fetchMyPartsRequest({ query: myPartsLastQuery }));
    }
  }
}

function* handleUploadMyPartsAttachmentsRequest({
  payload,
}: PayloadAction<{
  files: File[];
}>) {
  const results: GenericObject = yield all(
    payload.files?.map((file) =>
      safeCall(projectService.uploadPartFile, {
        file,
      })
    )
  );

  const errorResults = results.map((result: any) => result?.error).filter((item: any) => !!item);

  const successResults = results
    .map((result: any) => result?.response?.data)
    .filter((item: any) => !!item);

  if (errorResults?.length > 0) {
    yield put(workspaceNextActions.uploadMyPartsAttachmentsFailure(errorResults?.join(",")));

    yield snackbarUtils.error(errorResults?.join(","));
  } else if (successResults?.length > 0) {
    yield put(workspaceNextActions.uploadMyPartsAttachmentsSuccess(successResults));
  }
}

function* handleExportBRPFilesRequest({ payload }: PayloadAction<ExportBRPFilesInput>) {
  const { rfqId, rfqDisplayName, quoteId } = payload;
  const filesConfig = [
    // Temporarily removing CSV export: AAC-4689
    // {
    //   url: `${backendUrl.rfqs}/${rfqId}/brp/dataExport?ext=csv`,
    //   fileName: `SERVTECH_${rfqDisplayName}`,
    //   onError: (_: any) =>
    //     snackbarUtils.info(
    //       `${t("rfq:info:noServtechForThisRFQ", { rfqDisplayName })}`
    //     ),
    // },
    {
      url: `${backendUrl.rfqs}/${rfqId}/brp/dataExport?ext=xlsx`,
      fileName: `SERVTECH_${rfqDisplayName}`,
      onError: (_: any) =>
        snackbarUtils.info(`${t("rfq:info:noServtechForThisRFQ", { rfqDisplayName })}`),
    },
    {
      url: `${backendUrl.rfqs}/${rfqId}/brp/aribaExport`,
      fileName: `ARIBA_${rfqDisplayName}`,
      onError: (_: any) =>
        snackbarUtils.info(`${t("rfq:info:noAribaForThisRFQ", { rfqDisplayName })}`),
    },
    {
      url: `${backendUrl.quotation}/${quoteId}/pdfExport`,
      fileName: `QUOTE_${rfqDisplayName}`,
      onError: (_: any) =>
        snackbarUtils.info(`${t("rfq:info:noQuoteFileForThisRFQ", { rfqDisplayName })}`),
    },
  ];

  //make calls to download files in parallel
  const results: [{ response: any; error: any }] = yield all(
    filesConfig.map((item) =>
      safeCall(fileDownloadFromUrl, item.url, item.fileName, undefined, true, true)
    )
  );

  //handle errors if any
  let hasErrors = false;
  for (let i = 0; i < results.length; ++i) {
    const error = results[i].error;
    if (error) {
      hasErrors = true;
      yield put(workspaceNextActions.exportBRPFilesFailure({ rfqId, error }));
      filesConfig[i].onError(error);
    }
  }
  if (!hasErrors) yield put(workspaceNextActions.exportBRPFilesSuccess(rfqId));
}

function* resetMyPartsAttachments() {
  yield put(workspaceNextActions.uploadMyPartsAttachmentsReset());
}

function* handleMarkAsCompleteRequest({ payload }: PayloadAction<ContractMarkAsCompleteInput>) {
  const { error } = yield safeCall(projectService.updateRFQ, {
    pk: payload.pk,
    is_contract_completed: !payload.isCompleted,
  });

  if (error) {
    yield put(
      workspaceNextActions.contractMarkAsCompleteFailure({
        pk: payload.pk,
        error,
      })
    );
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    const tab = payload.isCompleted ? BuyerContractsTabType.ACTIVE : BuyerContractsTabType.ARCHIVED;

    yield put(workspaceNextActions.contractMarkAsCompleteSuccess(payload.pk));
    yield snackbarUtils.custom(
      t("contract:success:markComplete", {
        tabName: t(`contract:tabs:${tab}`),
      }),
      {
        buttonRedirectTo: createRedirectTabUrl(tab),
        buttonText: t("contract:goToTab") as string,
      }
    );
    if (payload.onSuccess) payload.onSuccess();
  }
}

function* handleFetchSupplierContactsListRequest({
  payload,
}: PayloadAction<FetchSupplierContactsList>) {
  const { error, response } = yield safeCall(
    workspaceNextService.fetchSupplierContactsList,
    payload
  );

  if (error) {
    yield put(workspaceNextActions.fetchSupplierContactsListFailure(error));
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    yield put(workspaceNextActions.fetchSupplierContactsListSuccess(response.data));
  }
}

function* handleInviteSupplierContactRequest({
  payload,
}: PayloadAction<InviteSupplierContactInput>) {
  const { error, response } = yield safeCall(workspaceNextService.inviteSupplierContact, payload);

  if (error) {
    yield put(workspaceNextActions.inviteSupplierContactFailure(error));
    const errorMsg = createErrorMessage(error).includes("unique")
      ? t("workspaceNext:supplierContactsList:uniqueEmailError")
      : t("common:error:general");
    yield snackbarUtils.error(errorMsg);
  } else {
    yield put(workspaceNextActions.inviteSupplierContactSuccess(response.data));
    yield snackbarUtils.toast(t("workspaceNext:supplierContactsList:invitationSuccess"));
    if (typeof payload.onSuccess === "function") payload.onSuccess(response.data);
  }
}

function* handleUpdateSupplierContactsListRequest({
  payload,
}: PayloadAction<UpdateSupplierContactsListInput>) {
  const { error, response } = yield safeCall(workspaceNextService.updateFavoriteSupplier, {
    target_profiles: payload.target_profiles,
    pk: payload.supplierId,
  });

  if (error) {
    yield put(workspaceNextActions.updateSupplierContactsListFailure(error));
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    yield put(workspaceNextActions.updateSupplierContactsListSuccess(response.data));
    yield snackbarUtils.toast(t("workspaceNext:supplierContactsList:updateSuccess"));
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleUpdateRFQCollaboratorsRequest({
  payload,
}: PayloadAction<UpdateRFQCollaboratorsInput>) {
  const { error, response } = yield safeCall(workspaceNextService.updateRFQCollaborators, payload);

  if (error) {
    yield put(workspaceNextActions.updateRFQCollaboratorsFailure(error));
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    yield put(workspaceNextActions.updateRFQCollaboratorsSuccess());

    if (payload?.refetchData) {
      yield refetchCompanyOrders();
    } else {
      if (payload.poId) {
        yield put(ordersActions.updateOrderCollaborators(response.data));
      }
      yield put(
        workspaceActions.updateRFQDetails({
          collaborators: response.data,
        })
      );
    }

    yield snackbarUtils.toast(t("workspaceNext:collaboratorsModal:success"));
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleUpdateRFQIsPublicRequest({ payload }: PayloadAction<UpdateRFQIsPublicInput>) {
  const prevIsPublicValue: string | null = yield select(
    (state) => state.workspace.rfqDetails.is_public
  );

  //optimistic update
  yield put(
    workspaceActions.updateRFQDetails({
      is_public: payload.isPublic,
    })
  );

  const { error } = yield safeCall(projectService.updateRFQ, {
    pk: payload.pk,
    is_public: payload.isPublic,
  });

  if (error) {
    yield put(workspaceNextActions.updateRFQIsPublicFailure(error));
    //revert optimistic update on error
    yield put(workspaceActions.updateRFQDetails({ is_public: prevIsPublicValue }));
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    yield put(workspaceNextActions.updateRFQIsPublicSuccess());
    yield snackbarUtils.success(t("rfq:isPublicConfirmation:successSnackbar"));
  }
}

function* handleUpdateRFQFollowupDateRequest({
  payload,
}: PayloadAction<UpdateRFQFollowupDateInput>) {
  const prevFollowupDate: string | null = yield select(
    (state: any) => state.workspace.rfqDetails.followup_date
  );
  //optimistic update
  yield put(
    workspaceActions.updateRFQDetails({
      followup_date: payload.followupDate || null,
    })
  );

  const { error } = yield safeCall(projectService.updateRFQ, {
    pk: payload.pk,
    followup_date: payload.followupDate || null,
  });

  if (error) {
    yield put(workspaceNextActions.updateRFQFollowupDateFailure(error));
    //revert optimistic update on error
    yield put(workspaceActions.updateRFQDetails({ followup_date: prevFollowupDate }));
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    yield put(workspaceNextActions.updateRFQFollowupDateSuccess());
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleUpdateRFQTargetProfilesRequest({
  payload,
}: PayloadAction<UpdateRFQTargetedProfilesInput>) {
  const { response, error } = yield safeCall(workspaceNextService.updateRFQTargetProfiles, {
    rfqId: payload.rfqId,
    supplierId: payload.supplierId,
    target_profiles: payload.target_profiles,
  });

  if (error) {
    yield put(workspaceNextActions.updateRFQTargetedProfilesFailure(error));
    yield snackbarUtils.error(t("common:error:general"));
  } else {
    yield put(workspaceNextActions.updateRFQTargetedProfilesSuccess());
    yield put(
      workspaceActions.updateRFQTargetProfiles(payload.supplierId, response.data.target_profiles)
    );
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

function* handleFetchAxyaRFQStats() {
  const { response, error } = yield safeCall(workspaceNextService.fetchAxyaRFQStatsData);

  if (error) {
    yield put(workspaceNextActions.fetchAxyaRFQStatsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchAxyaRFQStatsSuccess(response.data));
  }
}

function* handleFetchTendersRequest() {
  const { response, error } = yield safeCall(workspaceNextService.fetchTenders);

  if (error) {
    yield put(workspaceNextActions.fetchTendersFailure(error));
  } else {
    yield put(
      workspaceNextActions.fetchTendersSuccess({
        results: response.data,
      })
    );
  }
}

function* handleMarketplaceCountsRequest() {
  const { response, error } = yield safeCall(workspaceNextService.fetchMarketplaceCounts);

  if (error) {
    yield put(workspaceNextActions.fetchMarketplaceCountsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchMarketplaceCountsSuccess(response));
  }
}

function* handleFetchPortalDataRequest({ payload }: PayloadAction<FetchPortalDataInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchPortalData, payload);

  if (error) {
    yield put(workspaceNextActions.fetchPortalDataFailure(error));
  } else {
    yield put(workspaceNextActions.fetchPortalDataSuccess(response.data));
  }
}

function* handleUpdatePortalQuoteNoQuoteRequest({
  payload,
}: PayloadAction<UpdateQuoteNoQuoteStatusInput>) {
  const { error } = yield safeCall(workspaceNextService.updatePortalQuoteNoQuote, payload);

  if (error) {
    yield put(workspaceNextActions.updatePortalQuoteNoQuoteStatusFailure(error));
  } else {
    yield put(workspaceNextActions.updatePortalQuoteNoQuoteStatusSuccess(payload.type));

    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }

    yield snackbarUtils.toast(t("workspaceNext:QA:topicPortal:updateOfYourStatusSent"));
  }
}

function* handleUploadPortalQuoteFilesRequest({
  payload,
}: PayloadAction<UploadPortalQuoteFilesInput>) {
  const results: GenericObject = yield all(
    payload.files?.map((file) =>
      safeCall(workspaceNextService.uploadPortalQuoteFile, {
        rfqToken: payload.rfqToken,
        file,
      })
    )
  );

  const errorResults = results.map((result: any) => result?.error).filter((item: any) => !!item);

  const successResults = results
    .map((result: any) => result?.response?.data)
    .filter((item: any) => !!item);

  if (errorResults?.length > 0) {
    yield put(workspaceNextActions.uploadPortalQuoteFilesFailure(errorResults?.join(",")));

    yield snackbarUtils.error(errorResults?.join(","));
  } else if (successResults?.length > 0) {
    yield put(workspaceNextActions.uploadPortalQuoteFilesSuccess(successResults));
  }
}

function* uploadPortalQuoteFiles(rfqToken: string, customFiles: CustomFileObject[]): any {
  const currentFilesPks = customFiles
    ?.filter((customFile) => customFile?.pk !== -1)
    .map((customFile) => customFile.pk);
  const newFiles = customFiles
    ?.filter((customFile) => customFile.pk === -1)
    .map?.((customFile) => customFile.file);

  if (newFiles?.length === 0) return currentFilesPks;

  yield put(
    workspaceNextActions.uploadPortalQuoteFilesRequest({
      rfqToken,
      files: newFiles,
    })
  );

  const successFiles = yield take(workspaceNextActions.uploadPortalQuoteFilesSuccess);

  const successFilesPks = successFiles?.payload?.map((file: any) => file.pk);

  return [...currentFilesPks.filter((pk) => pk !== -1), ...successFilesPks];
}

function* handleCreatePortalQuoteRequest({ payload }: PayloadAction<CreatePortalQuoteInput>) {
  const filePks: number[] = yield uploadPortalQuoteFiles(payload.rfqToken, payload.files || []);

  const { response, error } = yield safeCall(workspaceNextService.createPortalQuote, {
    ...payload,
    files: filePks,
  });

  if (error) {
    yield put(workspaceNextActions.createPortalQuoteFailure(error));

    yield snackbarUtils.error(error);
  } else {
    yield put(workspaceNextActions.createPortalQuoteSuccess(response?.data));

    yield put(modalsActions.closeModal(WorkspaceModalTypes.TOPIC_PORTAL_SEND_QUOTATION_MODAL));

    let successMessage = "workspaceNext:QA:topicPortal:quoteSent";

    if (payload?.isRFI) {
      successMessage = "workspaceNext:QA:topicPortal:submissionSent";
    } else if (payload?.isSimpleRFQ) {
      successMessage = "workspaceNext:QA:topicPortal:quoteSent";
    }

    yield snackbarUtils.success(t(successMessage));
  }
}

function* handleUpdatePortalQuoteRequest({ payload }: PayloadAction<UpdatePortalQuoteInput>) {
  const filePks: number[] = yield uploadPortalQuoteFiles(payload.rfqToken, payload.files || []);

  const { response, error } = yield safeCall(workspaceNextService.updatePortalQuote, {
    ...payload,
    files: filePks,
  });

  if (error) {
    yield put(workspaceNextActions.updatePortalQuoteFailure(error));

    yield snackbarUtils.error(error);
  } else {
    yield put(workspaceNextActions.updatePortalQuoteSuccess(response?.data));

    yield put(modalsActions.closeModal(WorkspaceModalTypes.TOPIC_PORTAL_SEND_QUOTATION_MODAL));

    let successMessage = "workspaceNext:QA:topicPortal:quoteSent";

    if (payload?.isRFI) {
      successMessage = "workspaceNext:QA:topicPortal:submissionSent";
    } else if (payload?.isSimpleRFQ) {
      successMessage = "workspaceNext:QA:topicPortal:quoteSent";
    }
    yield snackbarUtils.success(t(successMessage));
  }
}

function* handleExportQuotesComparisonSheetRequest({
  payload,
}: PayloadAction<ExportQuotesComparisonSheetInput>) {
  const { response, error } = yield safeCall(
    workspaceNextService.exportQuotesComparisonSheet,
    payload
  );

  if (error) {
    yield put(workspaceNextActions.customQuotationExportFailure(error));
  } else {
    if (response.data instanceof Blob) {
      downloadFileNext(response.data, `${payload?.rfqDisplayName}_quotes_comparison_sheet`, "xlsx");
      yield put(workspaceNextActions.customQuotationExportSuccess());
    } else {
      yield put(workspaceNextActions.customQuotationExportFailure(error));
    }
  }
}

function* handleSendRevisionNoteRequest({ payload }: PayloadAction<SendRevisionNoteInput>) {
  const { rfqId, parts, partsIDs, revisionWithoutNote } = payload;
  const updatedPartList = parts?.map((part: any) => {
    const updatedPart: payloadPartData = {
      quantity: part.quantity || 1,
      pk: part.pk,
      name: part.name,
      additional_details: part.additional_details,
      alloy: part.alloy_pk || part.alloy,
      processes: part?.processes?.map((process) =>
        process instanceof Object ? process.id : process
      ),
      files: part?.part_uploads_list?.map((file) => file.pk),
      extra_fields: part?.extra_fields,
    };
    return updatedPart;
  });
  const requestData: requestDataTypes = {
    pk: rfqId,
    parts: updatedPartList,
  };

  if (partsIDs && partsIDs.length > 0) {
    requestData.parts_to_remove = partsIDs;
  }
  const { error } = yield safeCall(projectService.updateRFQ, requestData);

  if (error) {
    yield put(workspaceNextActions.sendRevisionFailure(error));
    return;
  }

  workspaceActions.fetchRFQParts(rfqId)(store.dispatch);

  if (payload.companies?.length > 0) {
    const { error } = yield safeCall(workspaceNextService.sendRevisionNote, payload);
    if (error) {
      yield put(workspaceNextActions.sendRevisionFailure(error));
    }
  }

  if (revisionWithoutNote) {
    yield snackbarUtils.toast(t("rfq:partsRevisionModal:revisionSuccess"));
  } else {
    yield snackbarUtils.toast(t("rfq:partsRevisionModal:successTooltip"));
  }

  yield put(workspaceNextActions.sendRevisionSuccess());
  if (typeof payload.onSuccess === "function") payload.onSuccess();
}

function* handleGlobalSearchRequest({ payload }: PayloadAction<GlobalSearchInput>) {
  const { response, error } = yield safeCall(workspaceNextService.globalSearch, payload);

  if (error) {
    yield put(workspaceNextActions.globalSearchFailure(error));
  } else {
    yield put(workspaceNextActions.globalSearchSuccess(response?.data.results));
  }
}

function* handleFetchSupplierQuotesRequest({ payload }: PayloadAction<FetchSupplierQuotesInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchSupplierQuotes, payload);

  if (error) {
    yield put(workspaceNextActions.fetchSupplierQuotesFailure(error));
  } else {
    yield put(workspaceNextActions.fetchSupplierQuotesSuccess(response.data));
  }
}

function* handleDeletePortalQuoteFileRequest({
  payload,
}: PayloadAction<DeletePortalQuoteFileInput>) {
  const { error } = yield safeCall(workspaceNextService.deletePortalQuoteFile, payload);

  if (error) {
    yield put(workspaceNextActions.deletePortalQuoteFailure(error));
  } else {
    yield put(workspaceNextActions.deletePortalQuoteSuccess());
  }
}

/**
 * Manages the upload of a single Part file.
 */
function* handleUploadSinglePartFile({ payload }: PayloadAction<uploadSinglePartFileInput>): any {
  const { file, onSuccess, onError } = payload;

  const { response, error } = yield call(handleS3MultipartUpload, {
    file: file,
    instanceId: null,
    contentType: FileContentType.PART_UPLOAD,
    onSuccess: (url: string) => safeCall(workspaceApi.uploadPart, url),
  });

  if (response) {
    // Call onSuccess function if is passed
    if (payload.onSuccess) payload.onSuccess(response.response);
    return { response: response.response, error };
  } else {
    // Call onError function if is passed
    if (payload.onError) payload.onError(error.message);
    yield snackbarUtils.error(error.message);
    yield workspaceNextActions.uploadSinglePartFileRequestFailure();
    return { error: error.message, response: null };
  }
}

/**
 * Manages the upload of a multiple Part file and update the part with new files.
 */
function* handleMultiplePartFilesUpload({
  payload,
}: PayloadAction<uploadMultiplePartFilesInput>): any {
  const results: { response: any; error: any; canceled: boolean }[] = yield all(
    (payload.files || []).map((file) =>
      call(handleUploadSinglePartFile, {
        payload: {
          file: file,
        },
        type: workspaceNextActions.uploadSinglePartFileRequest.type,
      })
    )
  );

  // In case of RFQ Edit, no need to update the part after uploading files
  if (payload.isRFQEditMode) {
    const partFiles = results.filter((r) => r.response && !r?.canceled).map((r) => r.response);
    yield put(
      workspaceActions.setAttachementtoRFQPartForEdit({
        partPk: payload.partPk,
        attachements: partFiles,
      })
    );
    yield put(workspaceNextActions.uploadMultiplePartFileRequestSuccess());
    yield put(workspaceNextActions.resetFileUploadProgress());
    return;
  }

  const filesPks = results.filter((r) => r.response && !r?.canceled).map((r) => r.response.pk);
  const filesError = results.filter((r) => r.error).map((r) => r.error);

  // Return only if all of the file uploads fail, otherwise continue with the good ones
  if (filesError.length === payload.files.length) {
    yield put(workspaceNextActions.uploadMultiplePartFileRequestFailure());
    yield put(workspaceNextActions.resetFileUploadProgress());
    return;
  }

  if (filesPks.length === 0) {
    // user canceled file upload
    yield put(workspaceNextActions.uploadMultiplePartFileRequestSuccess());
    yield put(workspaceNextActions.resetFileUploadProgress());
    return;
  }

  const companyPart: ProjectCompanyPart = yield select(
    payload.isRFQPart
      ? selectRFQPart(payload.partPk)
      : payload.isOrderPart
        ? selectOrderPartByPk(payload.partPk)
        : selectProjectCompanyPart(payload.partPk)
  );
  const currentFileIds = companyPart.part_uploads_list.map((item) => item.pk);

  // Update part with new files
  const data = {
    files: [...currentFileIds, ...filesPks],
  };

  if (payload.isRFQPart) {
    workspaceActions.editPart(
      getToken(store.getState()),
      data,
      payload.partPk,
      t,
      true
    )(store.dispatch);
  } else {
    yield put(
      projectActions.updateProjectPartRequest({
        pk: Number(payload.partPk),
        part: data,
        skipSuccessToast: true,
      })
    );
  }
  yield put(workspaceNextActions.uploadMultiplePartFileRequestSuccess());
  yield put(workspaceNextActions.resetFileUploadProgress());
}

/**
 * Orchestrates the upload of all RFQ attachments.
 */
function* handleUploadRfqAttachmentsRequest({
  payload,
}: PayloadAction<UploadRfqAttachmentsInput>): any {
  const results: { response: any; error: any }[] = yield all(
    payload.files.map((file) =>
      handleS3MultipartUpload({
        file: file,
        instanceId: payload.rfqPk,
        contentType: FileContentType.RFQ_ATTACHMENT,
        onSuccess: (url: string) => {
          return safeCall(workspaceApi.uploadRfqAttachment, payload.rfqPk, url);
        },
      })
    )
  );

  let hasErrors = false;
  for (let i = 0; i < results.length; ++i) {
    const error = results[i].error;
    if (error) {
      hasErrors = true;
      yield put(workspaceNextActions.uploadRfqAttachmentsFailure(error));
      yield put(workspaceNextActions.fileUploadError(error));
    }
  }

  if (!hasErrors) {
    yield put(workspaceNextActions.uploadRfqAttachmentsSuccess());
    yield put(workspaceNextActions.fileUploadSuccess());
    if (typeof payload.onSuccess === "function")
      payload.onSuccess(results.map((result) => result.response.response));
  } else {
    yield snackbarUtils.error(t("common:error:general"));
  }
}

function* handleUploadAddendaAttachementRequest({
  payload,
}: PayloadAction<UploadAddendaAttachmentsInput>) {
  const results: { response: any; error: any }[] = yield all(
    (payload.files || []).map((file) =>
      handleS3MultipartUpload({
        file: file,
        instanceId: payload.rfq,
        contentType: FileContentType.ADDENDUM_ATTACHMENT,
        onSuccess: (url: string) => {
          return safeCall(workspaceNextService.uploadAddendaAttachment, url);
        },
      })
    )
  );

  const filesError = results.filter((r) => r.error).map((r) => r.error);

  if (filesError.length) {
    yield put(workspaceNextActions.addAddendaFailure());
    filesError.forEach((error) => genericErrorHandler(error));
    return;
  } else {
    yield put(workspaceNextActions.addAddendaSuccess());
    if (typeof payload.onSuccess === "function")
      payload.onSuccess(results.map((item) => item.response.response.data));
  }
}

function* handleAddAddendaRequest({ payload }: PayloadAction<AddAddendaInput>) {
  const filesUrl = payload.files?.map((file) => file.pk);

  const { error, response } = yield safeCall(workspaceNextService.addAddenda, {
    ...payload,
    filesUrl,
  });

  if (error) {
    yield put(workspaceNextActions.addAddendaFailure(error));
    genericErrorHandler(error);
  } else {
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield put(workspaceNextActions.addAddendaSuccess());
    const { rfqDetails } = yield select((state) => state.workspace);
    yield put(
      workspaceActions.updateRFQDetails({
        addenda: [response.data, ...(rfqDetails?.addenda || [])],
      })
    );
    yield snackbarUtils.success(t("rfqAddenda:addendaSent"));
  }
}

function* handleFetchQuoteComparisonRequest({ payload }: PayloadAction<FetchQuoteComparisonInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchQuoteComparison, payload);

  if (error) {
    yield put(workspaceNextActions.fetchQuoteComparisonFailure(error));
  } else {
    yield put(workspaceNextActions.fetchQuoteComparisonSuccess(response.data));

    if (payload?.onSuccess) {
      payload.onSuccess(response.data);
    }
  }
}

function* handleUpdateQuoteComparisonConfigRequest({
  payload,
}: PayloadAction<UpdateQuoteComparisonConfigInput>) {
  const { response, error } = yield safeCall(
    workspaceNextService.updateQuoteComparisonConfig,
    payload
  );

  if (error) {
    yield put(workspaceNextActions.updateQuoteComparisonConfigFailure(error));
  } else {
    const lastQueryRfqId: number = yield select(selectQuoteComparisonLastQueryRfqId);

    yield put(
      workspaceNextActions.fetchQuoteComparisonRequest({
        rfq_id: lastQueryRfqId,
      })
    );

    yield put(modalsActions.closeModal(WorkspaceModalTypes.QUOTE_COMPARISON_CUSTOMIZE_VIEW_MODAL));

    yield put(workspaceNextActions.updateQuoteComparisonConfigSuccess(response.data));

    yield snackbarUtils.success(t("workspaceNext:quoteComparison:viewUpdated"));
  }
}

function* handleProceedQuoteComparisonSelectionRequest({
  payload,
}: PayloadAction<ProceedQuoteComparisonSelectionInput>) {
  const { response, error } = yield safeCall(projectService.createMultiContractRFQ, payload);

  if (error) {
    yield put(workspaceNextActions.proceedQuoteComparisonSelectionFailure(error));
    yield snackbarUtils.error(error);
  } else {
    yield put(workspaceNextActions.proceedQuoteComparisonSelectionSuccess(response.data));

    yield history.push(frontendUrl.buyerQuotes);

    yield put(modalsActions.closeModal(WorkspaceModalTypes.QUOTE_COMPARISON_AWARD_DRAWER));

    yield snackbarUtils.success(t("project:success:splitRFQCreated"));
  }
}

function* handleSendNewVerificationEmailRequest({
  payload,
}: PayloadAction<SendNewVerificationEmailInput>) {
  const { email, onSuccess } = payload;
  const { error } = yield safeCall(workspaceNextService.sendNewVerificationEmail, email);

  if (error) {
    yield put(workspaceNextActions.sendNewVetificationEmailFailure(error));
    genericErrorHandler(error);
  } else {
    yield put(workspaceNextActions.sendNewVetificationEmailSuccess());
    if (typeof onSuccess === "function") onSuccess();
  }
}

function* handleCheckEmailAlreadyExistsRequest({
  payload,
}: PayloadAction<CheckEmailAlreadyExistsInput>) {
  const { email, onSuccess, fromPortal } = payload;
  const { response, error } = yield safeCall(workspaceNextService.checkEmailExists, email);

  if (error) {
    yield put(workspaceNextActions.checkEmailAlreadyExistsFailure(error));
    genericErrorHandler(error);
  } else {
    if (fromPortal) {
      const redirect = getQueryParams().redirect as string;
      switch (response?.data?.next_step) {
        case StepsAfterCheckEmailAlreadyExists.LOGIN:
          window.location.href = `${
            frontendUrl.login
          }?email=${encodeURIComponent(email)}&redirect=${encodeURIComponent(
            redirect || frontendUrl.portal
          )}`;
          break;
        case StepsAfterCheckEmailAlreadyExists.VERIFICATION:
          const { response, error } = yield safeCall(
            workspaceNextService.sendNewVerificationEmail,
            email
          );
          if (error) {
            yield put(workspaceNextActions.checkEmailAlreadyExistsFailure(error));
            genericErrorHandler(error);
            return;
          } else {
            window.location.href = `${
              frontendUrl.validationEmailSent
            }?email=${encodeURIComponent(email)}&redirect=${encodeURIComponent(
              redirect || frontendUrl.portal
            )}`;
            break;
          }
        case StepsAfterCheckEmailAlreadyExists.REGISTER:
          window.location.href = `${frontendUrl.register}?email=${encodeURIComponent(
            email
          )}&isJoinTeam=false&fromPortal=true&redirect=${encodeURIComponent(
            redirect || frontendUrl.portal
          )}`;
          break;
      }
      yield put(workspaceNextActions.checkEmailAlreadyExistsSuccess());
      return;
    }
    yield put(workspaceNextActions.checkEmailAlreadyExistsSuccess());
    if (response?.data?.exists === false) {
      if (typeof onSuccess === "function") onSuccess();
    } else {
      yield snackbarUtils.error(t("userOnboarding:emailAlreadyExist"));
    }
  }
}

function* handleCreateEasyRfqRequest({ payload }: PayloadAction<CreateEasyRfqInput>) {
  const { error } = yield safeCall(workspaceNextService.createEasyRfq, payload);

  if (error) {
    yield put(workspaceNextActions.createEasyRfqFailure(error));
  } else {
    yield put(modalsActions.closeModal(WorkspaceModalTypes.RFQ_CREATION_LITE_DRAWER));

    yield put(workspaceNextActions.createEasyRfqSuccess());

    if (payload?.fromDashboard) {
      history.push(frontendUrl.buyerQuotes);
    } else {
      const projectCompanyOrdersLastQuery: string = yield select(
        selectProjectCompanyOrdersLastQuery
      );

      if (projectCompanyOrdersLastQuery) {
        yield put(
          projectActions.fetchProjectCompanyOrdersRequest({
            query: projectCompanyOrdersLastQuery,
          })
        );
      }
    }

    snackbarUtils.success(t("project:success:yourRFQHasBeenSuccessfullyCreated"));
  }
}

function* handleSyncERPQuotationRequest({ payload }: PayloadAction<SyncERPQuotationInput>) {
  const { error, response } = yield safeCall(workspaceNextService.syncERPQuotation, payload);

  if (error) {
    yield put(workspaceNextActions.syncERPQuotationFailure(error));

    snackbarUtils.error(t("rfq:quotations:syncWithSytelineError"));
  } else {
    yield put(workspaceNextActions.syncERPQuotationSuccess());

    // yield put(
    //   partConfigurationActions.setERPTypeLastSyncDate(
    //     formatDateAndTime(new Date())
    //   )
    // );

    snackbarUtils.toast(
      t("rfq:quotations:syncWithSytelineSuccessItems", {
        count: response?.data?.synchronised_line_items_count,
      })
    );
  }
}

function* handleFetchRFQQuestionsStatsRequest({
  payload,
}: PayloadAction<FetchRFQQuestionsStatsInput>) {
  const { error, response } = yield safeCall(workspaceNextService.fetchRfqQuestionsStats, payload);

  if (error) {
    yield put(workspaceNextActions.fetchRFQQuestionsStatsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchRFQQuestionsStatsSuccess(response.data));
  }
}

function* handleFetchQAThreadDetailsRequest({ payload }: PayloadAction<FetchQAThreadDetailsInput>) {
  const { response, error } = yield safeCall(workspaceNextService.fetchQAThreadDetails, payload);

  if (error) {
    yield put(workspaceNextActions.fetchQAThreadDetailsFailure(error));
  } else {
    yield put(workspaceNextActions.fetchQAThreadDetailsSuccess(response.data));
  }
}

function* handleSendEmailConfirmationRequest({
  payload,
}: PayloadAction<SendEmailConfirmationInput>) {
  const { error } = yield safeCall(workspaceNextService.sendEmailConfirmation, payload);

  if (error) {
    const errorMsg = createErrorMessage(error);
    yield put(workspaceNextActions.sendEmailConfirmationFailure(error));
    history.push(
      `${frontendUrl.login}?errorMessage=${
        /(500|Server\s+Error)/i.test(errorMsg) ? t("common:error:general") : errorMsg
      }`
    );
  } else {
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield put(workspaceNextActions.sendEmailConfirmationSuccess());
  }
}

function* handleUploadQuotationAsBuyerRequest({
  payload,
}: PayloadAction<UploadQuotationAsBuyerInput>) {
  const { error, response } = yield safeCall(workspaceNextService.uploadQuotationAsBuyer, payload);

  if (error) {
    yield put(workspaceNextActions.uploadQuotationAsBuyerFailure(error));
  } else {
    yield put(workspaceNextActions.uploadQuotationAsBuyerSuccess(response.data));
    const { rfqDetails } = yield select((state) => state.workspace);
    if (payload.quotation_id) {
      yield put(
        workspaceActions.updateRFQDetails({
          quotations: (rfqDetails?.quotations || []).map((quotation) =>
            quotation.pk === payload.quotation_id ? response.data : quotation
          ),
        })
      );
    } else {
      yield put(
        workspaceActions.updateRFQDetails({
          quotations: [response.data, ...(rfqDetails?.quotations || [])],
        })
      );
    }
    if (typeof payload.onSuccess === "function") payload.onSuccess(response.data);
  }
}

function* handleUploadQuotationFilesAsBuyerRequest({
  payload,
}: PayloadAction<UploadQuotationFilesAsBuyerInput>) {
  const results: GenericObject = yield all(
    payload.files?.map((file) =>
      safeCall(workspaceNextService.uploadQuotationFileAsBuyer, {
        file,
      })
    )
  );

  const errorResults = results.map((result: any) => result?.error).filter((item: any) => !!item);

  const successResults = results
    .map((result: any) => result?.response?.data)
    .filter((item: any) => !!item);

  if (errorResults?.length > 0) {
    yield put(workspaceNextActions.uploadQuotationFileAsBuyerRequest(errorResults?.join(",")));

    yield snackbarUtils.error(errorResults?.join(","));
  } else if (successResults?.length > 0) {
    if (typeof payload.onSuccess === "function") payload.onSuccess(successResults);
    yield put(workspaceNextActions.uploadQuotationFileAsBuyerSuccess(successResults));
  }
}

function* handleInternalNotesMutateRequest({ payload }: PayloadAction<any>) {
  const { response, error } = yield safeCall(workspaceNextService.mutateInternalNotes, payload);
  if (error) {
    yield put(workspaceNextActions.internalNotesMutateFailure(error));
  } else {
    yield put(workspaceNextActions.internalNotesMutateSuccess(response.data));

    yield put(workspaceNextActions.getInternalNotesRequest(payload.moduleType));

    yield put(modalsActions.closeModal(SharedModalTypes.INTERNAL_NOTES_DELETE_TAG_MODAL));

    yield put(modalsActions.closeModal(SharedModalTypes.INTERNAL_NOTES_MODAL));

    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  }
}

function* handleFetchInternalNotes(payload) {
  const { error, response } = yield safeCall(workspaceNextService.getInternalNotes, payload);
  if (error) {
    yield put(workspaceNextActions.getInternalNotesFailure(error));
  } else {
    yield put(workspaceNextActions.getInternalNotesSuccess(response.data));
  }
}

function* handleAssignInternalNotes({ payload }) {
  const { error, response } = yield safeCall(workspaceNextService.assignInternalNote, {
    allNotes: payload.notes,
    moduleType: payload.moduleType,
    pk: payload.pk,
  });
  if (error) {
    yield put(workspaceNextActions.assignInternalNoteFailure(error));
  } else {
    yield put(workspaceNextActions.assignInternalNoteSuccess(response.data));

    if (typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  }
}

function* searchSuppliersRequest({ payload }: PayloadAction<string>) {
  yield delay(800);

  const { response, error } = yield safeCall(workspaceNextService.fetchSuppliers, payload);

  if (error) {
    yield put(workspaceNextActions.fetchSuppliersFailure(error));
  } else {
    yield put(workspaceNextActions.fetchSuppliersSuccess(response.data));
  }
}

function* handleAddASLRequest({ payload }: PayloadAction<ApprovedSupplierInput>) {
  const { response, error } = yield safeCall(workspaceNextService.addASL, payload);

  if (error) {
    yield put(workspaceNextActions.addASLFailure(error));
    genericErrorHandler(error);
    return;
  } else {
    yield put(workspaceNextActions.addASLSuccess(response.data));

    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield snackbarUtils.toast(t("common:supplierAdded"));
  }
}

function* handleSendAirbusConfirmationRequest({
  payload,
}: PayloadAction<SendAirbusConfirmationInput>) {
  const { error } = yield safeCall(workspaceNextService.sendAirbusConfirmation, payload);

  if (error) {
    yield put(workspaceNextActions.sendAirbusConfirmationFailure(error));
    history.push(`${frontendUrl.login}?errorMessage=${t("common:error:general")}`);
  } else {
    if (typeof payload.onSuccess === "function") payload.onSuccess();
    yield put(workspaceNextActions.sendAirbusConfirmationSuccess());
  }
}
function* handleFetchOTTRequest() {
  const { response, error } = yield safeCall(workspaceNextService.fetchOTT);
  if (error) {
    yield put(workspaceNextActions.fetchOTTRequestFailure(error));
  } else {
    yield put(workspaceNextActions.fetchOTTRequestSuccess(response.data.ott));
  }
}

function* generatePortalUrlRequest({ payload }) {
  const { response, error } = yield safeCall(workspaceNextService.getPortalUrl, payload);
  if (error) {
    yield put(workspaceNextActions.getPortalUrlFailure(error));
  } else {
    window.open(response.data.portal_url, "_blank");
    yield put(workspaceNextActions.getPortalUrlSuccess(response.data.portal_url));
    if (typeof payload.onSuccess === "function") payload.onSuccess();
  }
}

export function* workspaceSaga() {
  yield takeLatest(workspaceNextActions.addASLRequest, handleAddASLRequest);

  yield takeLatest(workspaceNextActions.fetchSuppliersRequest, searchSuppliersRequest);

  yield takeLatest(workspaceNextActions.fetchQATopicsRequest, handleFetchQATopicsRequest);

  yield takeLatest(workspaceNextActions.fetchQATopicRequest, handleFetchQATopicRequest);

  yield takeLatest(
    workspaceNextActions.fetchInternalChatTopicRequest,
    handleFetchInternalChatTopicRequest
  );

  yield takeLatest(workspaceNextActions.createQATopicRequest, handleCreateQATopicRequest);

  yield takeLatest(workspaceNextActions.answerQATopicRequest, handleAnswerQATopicRequest);

  yield takeLatest(
    workspaceNextActions.postInternalChatMessageRequest,
    handlePostInternalChatMessageRequest
  );

  yield takeEvery(workspaceNextActions.qaMarkAsReadRequest, handleQaMarkAsRead);

  yield takeLatest(workspaceNextActions.contactUsRequest, handleContactUsRequest);

  yield takeLatest(
    workspaceNextActions.customQuotationExportRequest,
    handleCustomQuotationExportRequest
  );

  yield takeLatest(workspaceNextActions.inviteSupplierRequest, handleInviteSupplierRequest);

  yield takeLatest(workspaceNextActions.fetchNDAListRequest, handleFetchNDAListRequest);

  yield takeLatest(workspaceNextActions.updateNDARequest, handleUpdateNDARequest);

  yield takeLatest(workspaceNextActions.fetchPartsRequest, handleFetchPartsRequest);

  yield takeLatest(
    workspaceNextActions.fetchFavoriteSuppliersRequest,
    handleFetchFavoriteSuppliersRequest
  );

  yield takeLatest(
    workspaceNextActions.fetchRFQFavoriteSuppliersRequest,
    handleFetchRFQFavoriteSuppliersRequest
  );

  yield takeEvery(
    workspaceNextActions.updateFavoriteSupplierRequest,
    handleUpdateFavoriteSupplierRequest
  );

  yield takeLatest(
    workspaceNextActions.addFavoriteSupplierRequest,
    handleAddFavoriteSupplierRequest
  );

  yield takeLatest(
    workspaceNextActions.removeFavoriteSupplierRequest,
    handleRemoveFavoriteSupplierRequest
  );

  yield takeLatest(
    workspaceNextActions.fetchFavoriteSupplierGroupsRequest,
    handleFetchFavoriteSupplierGroupsRequest
  );

  yield takeLatest(
    workspaceNextActions.createFavoriteSupplierGroupRequest,
    handleCreateFavoriteSupplierGroupRequest
  );

  yield takeLatest(
    [workspaceNextActions.createFavoriteSupplierGroupSuccess],
    refetchFavoriteSupplierGroups
  );

  yield takeLatest(workspaceNextActions.fetchDraftRFQsRequest, handleFetchDraftRFQsRequest);

  yield takeLatest(workspaceNextActions.saveDraftRFQRequest, handleSaveDraftRFQ);

  yield takeLatest(workspaceNextActions.deleteDraftRFQRequest, handleDeleteRFQRequest);

  yield takeLatest(workspaceNextActions.loadDraftRFQRequest, handleLoadDraftRFQRequest);

  yield takeLatest(
    workspaceNextActions.updateDraftRFQCollaboratorsRequest,
    handleUpdateDraftRFQCollaboratorsRequest
  );

  yield takeLatest(
    workspaceNextActions.createDiscordNotifyRequest,
    handleCreateDiscordNotifyRequest
  );

  yield takeLatest(workspaceNextActions.fetchContractsRequest, handleFetchContractsRequest);

  yield takeLatest(workspaceNextActions.fetchTendersRequest, handleFetchTendersRequest);

  yield takeLatest(
    workspaceNextActions.fetchMarketplaceCountsRequest,
    handleMarketplaceCountsRequest
  );

  yield takeLatest(
    [projectActions.attachPurchaseOrderSuccess, UserRatingActionTypes.SEND_USER_RATING_SUCCESS],
    refetchContracts
  );

  yield takeEvery(workspaceNextActions.fetchMyPartsRequest, handleFetchMyPartsRequest);

  yield takeLatest(workspaceNextActions.exportMyPartsRequest, handleExportMyPartsRequest);

  yield takeLatest(workspaceNextActions.importMyPartsRequest, handleImportMyPartsRequest);

  yield takeLatest(workspaceNextActions.deletePartRequest, handleDeletePartRequest);

  yield takeLatest(workspaceNextActions.clonePartRequest, handleClonePartRequest);

  yield takeLatest(workspaceNextActions.updatePartRequest, handleUpdatePartRequest);

  yield takeLatest(workspaceNextActions.createPartRequest, handleCreatePartRequest);

  yield takeLatest(workspaceConstants.RFQ_SUCCESS, refetchMyPartsAndOrders);

  yield takeLatest(
    workspaceNextActions.uploadMyPartsAttachmentsRequest,
    handleUploadMyPartsAttachmentsRequest
  );

  yield takeLatest(workspaceNextActions.exportBRPFilesRequest, handleExportBRPFilesRequest);

  yield takeLatest(
    [
      workspaceNextActions.createPartSuccess,
      workspaceNextActions.createPartFailure,
      workspaceNextActions.updatePartSuccess,
      workspaceNextActions.updatePartFailure,
      workspaceNextActions.fetchMyPartsRequest,
    ],
    resetMyPartsAttachments
  );

  yield takeEvery(workspaceNextActions.contractMarkAsCompleteRequest, handleMarkAsCompleteRequest);

  yield takeLatest(
    workspaceNextActions.fetchSupplierContactsListRequest,
    handleFetchSupplierContactsListRequest
  );

  yield takeLatest(
    workspaceNextActions.inviteSupplierContactRequest,
    handleInviteSupplierContactRequest
  );

  yield takeLatest(
    workspaceNextActions.updateSupplierContactsListRequest,
    handleUpdateSupplierContactsListRequest
  );

  yield takeLatest(workspaceNextActions.fetchPortalDataRequest, handleFetchPortalDataRequest);

  yield takeLatest(
    workspaceNextActions.updateRFQCollaboratorsRequest,
    handleUpdateRFQCollaboratorsRequest
  );

  yield takeLatest(workspaceNextActions.updateRFQIsPublicRequest, handleUpdateRFQIsPublicRequest);

  yield takeLatest(
    workspaceNextActions.updateRFQTargetedProfilesRequest,
    handleUpdateRFQTargetProfilesRequest
  );

  yield takeLatest(workspaceNextActions.fetchAxyaRFQStatsRequest, handleFetchAxyaRFQStats);

  yield takeLatest(
    workspaceNextActions.updateRFQFollowupDateRequest,
    handleUpdateRFQFollowupDateRequest
  );

  yield takeLatest(
    workspaceNextActions.updatePortalQuoteNoQuoteStatusRequest,
    handleUpdatePortalQuoteNoQuoteRequest
  );

  yield takeLatest(
    workspaceNextActions.uploadPortalQuoteFilesRequest,
    handleUploadPortalQuoteFilesRequest
  );

  yield takeLatest(workspaceNextActions.createPortalQuoteRequest, handleCreatePortalQuoteRequest);
  yield takeLatest(workspaceNextActions.updatePortalQuoteRequest, handleUpdatePortalQuoteRequest);

  yield takeLatest(
    workspaceNextActions.exportQuotesComparisonSheetRequest,
    handleExportQuotesComparisonSheetRequest
  );

  yield takeLatest(workspaceNextActions.sendRevisionRequest, handleSendRevisionNoteRequest);

  yield takeLatest(workspaceNextActions.globalSearchRequest, handleGlobalSearchRequest);

  yield takeLatest(
    workspaceNextActions.fetchSupplierQuotesRequest,
    handleFetchSupplierQuotesRequest
  );

  yield takeLatest(
    workspaceNextActions.deletePortalQuoteRequest,
    handleDeletePortalQuoteFileRequest
  );

  yield takeEvery(workspaceNextActions.uploadSinglePartFileRequest, handleUploadSinglePartFile);

  yield takeLatest(
    workspaceNextActions.uploadMultiplePartFileRequest,
    handleMultiplePartFilesUpload
  );

  yield takeLatest(
    workspaceNextActions.uploadRfqAttachmentsRequest,
    handleUploadRfqAttachmentsRequest
  );

  yield takeLatest(workspaceNextActions.fetchPresignedUrlRequest, handleFetchPresignedS3Request);

  yield takeEvery(workspaceNextActions.fileChunkUploadSuccess, handleFileChunkUploadSuccess);

  yield takeLatest(workspaceNextActions.addAddendaRequest, handleAddAddendaRequest);
  yield takeLatest(
    workspaceNextActions.uploadAddendaAttachmentsRequeset,
    handleUploadAddendaAttachementRequest
  );
  yield takeLatest(
    workspaceNextActions.sendNewVetificationEmailRequest,
    handleSendNewVerificationEmailRequest
  );

  yield takeLatest(
    workspaceNextActions.checkEmailAlreadyExistsRequest,
    handleCheckEmailAlreadyExistsRequest
  );

  yield takeLatest(
    workspaceNextActions.fetchQuoteComparisonRequest,
    handleFetchQuoteComparisonRequest
  );

  yield takeLatest(
    workspaceNextActions.updateQuoteComparisonConfigRequest,
    handleUpdateQuoteComparisonConfigRequest
  );

  yield takeLatest(
    workspaceNextActions.proceedQuoteComparisonSelectionRequest,
    handleProceedQuoteComparisonSelectionRequest
  );

  yield takeLatest(workspaceNextActions.createEasyRfqRequest, handleCreateEasyRfqRequest);

  yield takeLatest(workspaceNextActions.syncERPQuotationRequest, handleSyncERPQuotationRequest);

  yield takeLatest(
    workspaceNextActions.fetchRFQQuestionsStatsRequest,
    handleFetchRFQQuestionsStatsRequest
  );

  yield takeLatest(
    workspaceNextActions.fetchQAThreadDetailsRequest,
    handleFetchQAThreadDetailsRequest
  );

  yield takeLatest(
    workspaceNextActions.sendEmailConfirmationRequest,
    handleSendEmailConfirmationRequest
  );

  yield takeLatest(
    workspaceNextActions.uploadQuotationAsBuyerRequest,
    handleUploadQuotationAsBuyerRequest
  );

  yield takeLatest(
    workspaceNextActions.uploadQuotationFileAsBuyerRequest,
    handleUploadQuotationFilesAsBuyerRequest
  );
  yield takeLatest(
    workspaceNextActions.internalNotesMutateRequest,
    handleInternalNotesMutateRequest
  );
  yield takeLatest(workspaceNextActions.getInternalNotesRequest, handleFetchInternalNotes);
  yield takeLatest(workspaceNextActions.assignInternalNoteRequest, handleAssignInternalNotes);

  yield takeLatest(
    workspaceNextActions.sendAirbusConfirmationRequest,
    handleSendAirbusConfirmationRequest
  );
  yield takeLatest(workspaceNextActions.fetchOTTRequest, handleFetchOTTRequest);
  yield takeLatest(workspaceNextActions.getPortalUrlRequest, generatePortalUrlRequest);
}
