import React from "react";
import { ReadinessDashboardSupplierViewTotalCardsGroup } from "./readiness-dashboard-supplier-view-total-cards-group/readiness-dashboard-supplier-view-total-cards-group";
import { ReadinessDashboardSupplierViewChartsCardsGroup } from "./readiness-dashboard-supplier-view-charts-cards-group/readiness-dashboard-supplier-view-charts-cards-group";
import { ReadinessDashboardSupplierViewOnboardingTableCard } from "./readiness-dashboard-supplier-view-onboarding-table-card/readiness-dashboard-supplier-view-onboarding-table-card";
import { ReadinessDashboardSupplierViewSuppliersTableWithSearch } from "./readiness-dashboard-supplier-view-suppliers-table-with-search/readiness-dashboard-supplier-view-suppliers-table-with-search";
import * as S from "./readiness-dashboard-supplier-view.styled";

export const ReadinessDashboardSupplierView: React.FC = () => {
  return (
    <S.StyledRootBox>
      <ReadinessDashboardSupplierViewTotalCardsGroup />

      <ReadinessDashboardSupplierViewChartsCardsGroup />

      <ReadinessDashboardSupplierViewOnboardingTableCard />

      <ReadinessDashboardSupplierViewSuppliersTableWithSearch />
    </S.StyledRootBox>
  );
};
