import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, styled } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
});

const StyledErrorIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.error.main,
  width: "24px",
  height: "24px",
}));

export const OverdueCell: React.FC<GridRenderCellParams<any>> = (params) => {
  return (
    <StyledBox>
      {params?.value ? (
        <>
          <StyledErrorIcon />
          {params?.value}
        </>
      ) : null}
    </StyledBox>
  );
};
