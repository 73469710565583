import React, { useMemo } from "react";
import { CustomLegendList } from "../custom-legend-list/custom-legend-list";
import { getPercentage } from "@next/modules/vendor-management/utils/getPercentage";
import { getTotal } from "@next/modules/vendor-management/utils/getTotal";
import {
  SemiDonutChartItemColors,
  SemiDonutChartItemSlugs,
  SemiDonutChart as SemiDonutChartType,
} from "@next/modules/vendor-management/redux";
import * as S from "./semi-donut-chart.styled";

type Props = {} & SemiDonutChartType;

export const SemiDonutChart: React.FC<Props> = ({ slug, items }) => {
  const orderedItems = useMemo(() => {
    const slugsOrder = Object.keys(SemiDonutChartItemSlugs);
    return [...items].sort((a, b) => slugsOrder.indexOf(a.slug) - slugsOrder.indexOf(b.slug));
  }, [items]);
  const series = useMemo(() => orderedItems?.map((item) => item.value), [items]);
  const total = useMemo(() => getTotal(series), [series]);
  const colors = useMemo(
    () => orderedItems?.map((item) => SemiDonutChartItemColors[item.slug]),
    [items]
  );
  const labels = useMemo(() => {
    return orderedItems?.map((item) => {
      const percentageNumber = Number(getPercentage(item.value, total));
      const percentage = percentageNumber >= 1 ? Math.round(percentageNumber) : percentageNumber;
      return `${SemiDonutChartItemSlugs[item.slug]} (${percentage}%)`;
    });
  }, [orderedItems, total]);

  const chartData = {
    series: series,
    options: {
      chart: {
        type: "donut" as const,
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 90,
          offsetY: 10,
        },
      },
      labels: labels,
      colors: colors,
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
  };

  return (
    <S.StyledRootBox>
      <S.StyledChart options={chartData.options} series={chartData.series} type="donut" />

      <S.StyledLegendWrapper>
        <CustomLegendList labels={labels} colors={colors} />
      </S.StyledLegendWrapper>
    </S.StyledRootBox>
  );
};
