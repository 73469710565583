import React from "react";
import { Grid } from "@mui/material";
import { ReadinessDashboardNavFilter } from "./readiness-dashboard-nav-filter/readiness-dashboard-nav-filter";
import { ReadinessDashboardNavExtractData } from "./readiness-dashboard-nav-extract-data/readiness-dashboard-nav-extract-data";
import { ReadinessDashboardNavMap } from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-nav.styled";

type Props = {
  activeNavTab: ReadinessDashboardNavMap;
  setActiveNavTab: React.Dispatch<React.SetStateAction<ReadinessDashboardNavMap>>;
};

export const ReadinessDashboardNav: React.FC<Props> = ({ activeNavTab, setActiveNavTab }) => {
  const handleTabClick = (value: ReadinessDashboardNavMap) => {
    if (activeNavTab !== value) {
      setActiveNavTab(value);
    }
  };

  return (
    <S.StyledRootBox>
      <Grid container sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
        <Grid item xs={6} md={6} lg={2.4}>
          <ReadinessDashboardNavFilter />
        </Grid>

        {Object.values(ReadinessDashboardNavMap).map((value) => (
          <Grid item xs={6} md={6} lg={2.4} key={value}>
            <S.StyledTabBox
              key={value}
              isThereBorderRadius={true}
              isSelected={activeNavTab === value}
              onClick={() => handleTabClick(value)}
            >
              <S.StyledTypography variant="h6">{value}</S.StyledTypography>
            </S.StyledTabBox>
          </Grid>
        ))}

        <Grid item xs={6} md={6} lg={2.4}>
          <ReadinessDashboardNavExtractData />
        </Grid>
      </Grid>
    </S.StyledRootBox>
  );
};
