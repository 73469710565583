import { all, fork, put } from "redux-saga/effects";

import { appSaga } from "@next/modules/app/redux/sagas";
import { projectSaga } from "@next/modules/project/redux/sagas";
import { marketplaceSaga } from "@next/modules/marketplace/redux/sagas";
import { appActions } from "@next/modules/app/redux";
import { profileSaga } from "@next/modules/profile/redux";
import { workspaceSaga } from "@next/modules/workspace/redux";
import { announcementSaga } from "@next/modules/announcement/redux";
import { ordersSaga } from "@next/modules/orders/redux";
import { dashboardSaga } from "@next/modules/dashboard/redux";
import { newsfeedSaga } from "@next/modules/newsfeed/redux";
import { companyCapabilitesSaga } from "@next/modules/company-capabilities/redux";
import { analyticsSaga } from "@next/modules/analytics/redux";
import { vendorManagementSaga } from "@next/modules/vendor-management/redux";
import { parserSaga } from "@next/modules/ai/redux/sagas";

function* initialSaga() {
  // Indicate that the app is initialized
  yield put(appActions.onAppInit());
}

function* rootSaga() {
  yield fork(initialSaga);
  yield all(
    [
      appSaga,
      projectSaga,
      marketplaceSaga,
      profileSaga,
      workspaceSaga,
      announcementSaga,
      ordersSaga,
      dashboardSaga,
      newsfeedSaga,
      companyCapabilitesSaga,
      analyticsSaga,
      vendorManagementSaga,
      parserSaga,
    ].map(fork)
  );
}

export default rootSaga;
