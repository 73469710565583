import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";

import { useHistory } from "react-router-dom";

import AirbusLogo from "../../../assets/airbus-logo.png";
import NextAxyaLogo from "assets/img/axya-logo-topbar.svg";
import { CustomFormStepper } from "../components/custom-form-stepper/custom-form-stepper";
import { Questionnaries } from "../components/questionnaires/questionnaires";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import {
  IdentificationFormData,
  PartialStep,
  PosEndUsersFormKeyMapping,
  Questionnaire,
  QuestionnaireType,
  Step,
  vendorManagementActions,
} from "../redux";
import { GreetingForm } from "../components/greeting-form/greeting-form";
import {
  selectSteps,
  selectStepsError,
  selectStepsLoading,
  selectVisibleSteps,
  selectedCompanyDetails,
} from "../redux/selectors";

import { ErrorScreen } from "../components/error-screen/error-screen";
import { LoadingScreen } from "../components/loading-screen/loading-screen";
import { getActiveStepIndex } from "../utils/getActiveStepIndex";
import { VendorContactSupportItem } from "@next/modules/app/components/app-bar-next/app-bar-next-action-items/components/vendor-contact-support-action-item";
import useZammadChat from "components/zammad/zammad-chat";
import { frontendUrl } from "urls";
import { orderSteps } from "../utils/orderSteps";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "#00205B",
  width: "100%",
  height: "72px",
  display: "flex",
  flexDirection: "row",
  gap: "25px",
  alignItems: "center",
  paddingLeft: "16px",
  paddingRight: "16px",
  position: "fixed",
  overflow: "hidden",
  boxSizing: "border-box",
  "& > *": {
    minWidth: 0,
  },
});

const ContactSupportWrapper = styled(Box)({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
});

const StyledBox = styled(Box)({
  backgroundColor: "#fafafa",
  overflowY: "visible",
});

export const StyledLoadingBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100%",
    height: "100%",
    maxWidth: "none",
    backgroundColor: theme.palette.background.default,
    margin: 50,
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      height: "95%",
    },
  },
}));

type Props = {
  questionnaireName: Questionnaire;
  isFormDisplayForValidationManagement: boolean;
};

export const VendorManagement: React.FC<Props> = ({
  questionnaireName,
  isFormDisplayForValidationManagement,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const location = useLocation();
  const dispatch = useDispatch();
  const allSteps = useSelector(selectSteps);
  const visibleSteps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(visibleSteps);
  const requestStepsError = useSelector(selectStepsError);
  const isShowGreetingPage = activeFormStepIndex === -1;
  const { closeChat, openChat } = useZammadChat(companyId);
  const [isWebinarDialogOpen, setIsWebinarDialogOpen] = useState(false);
  const identificationFormDataState = useSelector(selectedCompanyDetails) as IdentificationFormData;
  const selectIsStepsLoading = useSelector(selectStepsLoading);

  const history = useHistory();

  useEffect(() => {
    dispatch(vendorManagementActions.fetchSupplierByCompanyIdRequest(companyId));
  }, [companyId, dispatch]);

  useEffect(() => {
    dispatch(vendorManagementActions.setQuestionnaireName(questionnaireName));
    dispatch(
      vendorManagementActions.setIsFormDisplayForValidationManagement(
        isFormDisplayForValidationManagement
      )
    );

    dispatch(
      vendorManagementActions.fetchStepsRequest({
        companyId: companyId,
        questionnaireType: QuestionnaireType[questionnaireName],
        onSuccess: displayFirstStepIfModuleRunsValidationManagement,
      })
    );
  }, [companyId, questionnaireName, dispatch, isFormDisplayForValidationManagement]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("webinar") === "true") {
      setIsWebinarDialogOpen(true);
    }
  }, [location.search]);

  const displayFirstStepIfModuleRunsValidationManagement = (steps: Step[]) => {
    if (isFormDisplayForValidationManagement) {
      const activeStep = steps.find((step) => step.is_active && step.is_visible);
      if (activeStep) {
        if (activeStep.step_name === PosEndUsersFormKeyMapping.Identification) {
          return;
        } else {
          const allVisibleSteps = steps.filter((step) => step.is_visible);
          const orderedSteps = orderSteps(allVisibleSteps);
          const indexOfActiveStep = orderedSteps.indexOf(activeStep);
          makeActiveStepInactive(indexOfActiveStep, orderedSteps);

          const identificationStep = orderedSteps.find(
            (step) => step.step_name === PosEndUsersFormKeyMapping.Identification
          );
          makeIdentificationStepActive(identificationStep);
        }
      } else {
        const identificationStep = steps.find(
          (step) => step.step_name === PosEndUsersFormKeyMapping.Identification
        );
        makeIdentificationStepActive(identificationStep);
      }
    }
  };

  const handleClickLogo = () => {
    if (!isShowGreetingPage && !isFormDisplayForValidationManagement) {
      makeActiveStepInactive(activeFormStepIndex);
    }
  };

  const makeActiveStepInactive = (index: number, allVisibleSteps = visibleSteps) => {
    const step = allVisibleSteps[index];
    const partialStep: PartialStep = {
      is_active: false,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const makeIdentificationStepActive = (step: Step) => {
    const partialStep: PartialStep = {
      is_active: true,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
      })
    );
  };

  const handleCloseWebinarDialog = () => {
    setIsWebinarDialogOpen(false);
    history.replace(window.location.pathname);
  };

  try {
    openChat();
  } catch (e) {}

  if (requestStepsError) {
    return <ErrorScreen message={"Failed loading form steps. Try again."} />;
  }

  if (selectIsStepsLoading) {
    return <LoadingScreen />;
  }

  const routeDashboard = () => {
    if (isFormDisplayForValidationManagement) {
      history.push(`${frontendUrl.validationManagementReviews}`);
    }
  };

  return (
    <StyledBox>
      <StyledAppBar>
        <img
          src={NextAxyaLogo}
          alt="Axya"
          onClick={routeDashboard}
          style={{
            cursor: isFormDisplayForValidationManagement ? "pointer" : "default",
            width: "78px",
            height: "24px",
          }}
        />

        <Avatar
          onClick={handleClickLogo}
          sx={{
            width: "100px",
            height: "40px",
            cursor: !isFormDisplayForValidationManagement ? "pointer" : "default",
          }}
          src={AirbusLogo}
        />

        {allSteps ? <CustomFormStepper /> : null}

        <ContactSupportWrapper>
          <VendorContactSupportItem />
        </ContactSupportWrapper>
      </StyledAppBar>

      {isShowGreetingPage ? <GreetingForm /> : <Questionnaries />}

      <StyledDialog open={isWebinarDialogOpen} onClose={handleCloseWebinarDialog}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {identificationFormDataState?.webinar_link?.[questionnaireName]?.title || ""}
          <IconButton
            aria-label="close"
            onClick={handleCloseWebinarDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {identificationFormDataState?.webinar_link && (
          <DialogContent>
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <video
                controls
                preload="metadata"
                poster={AirbusLogo}
                width="100%"
                height="100%"
                style={{ maxWidth: "100%", height: "auto" }}
              >
                <source
                  src={identificationFormDataState?.webinar_link?.[questionnaireName]?.url || ""}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </DialogContent>
        )}
      </StyledDialog>
    </StyledBox>
  );
};
