import React, { useMemo } from "react";
import Chart from "react-apexcharts";
import { CustomLegendList } from "../custom-legend-list/custom-legend-list";
import {
  OverviewGraphChart,
  OverviewGraphChartItemColors,
  OverviewGraphChartItemSlugs,
} from "@next/modules/vendor-management/redux";
import * as S from "./multiple-lined-chart.styled";

type Props = {} & OverviewGraphChart;

export const MultipleLinedChart: React.FC<Props> = ({ x_axis, y_axes }) => {
  const legendColors = useMemo(() => {
    return y_axes?.map((y_axis) => OverviewGraphChartItemColors[y_axis.slug]);
  }, [y_axes]);
  const legendLabels = useMemo(() => {
    return y_axes?.map((y_axis) => OverviewGraphChartItemSlugs[y_axis.slug]);
  }, [y_axes]);
  const series = useMemo(() => {
    return y_axes?.map((y_axis) => ({
      name: OverviewGraphChartItemSlugs[y_axis.slug],
      data: y_axis.values,
    }));
  }, [y_axes]);

  const chartData = {
    series: series,
    options: {
      chart: {
        type: "line" as const,
        toolbar: {
          offsetX: -25,
          offsetY: -82,
          autoSelected: "zoom" as "zoom",
          tools: {
            pan: true,
            selection: false,
            zoom: false,
          },
        },
        zoom: {
          enabled: true,
          type: "x" as "x",
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight" as const,
        width: 3,
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: x_axis,
        offsetX: 10,
        offsetY: 10,
        labels: {
          style: {
            colors: "#0000008A",
          },
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          formatter: (value: number) => {
            const percentages = [0, 25, 50, 75, 100];
            return percentages.includes(value) ? `${value}%` : "";
          },
          style: {
            colors: "#0000008A",
          },
          offsetX: -5,
        },
        min: 0,
        max: 100,
        tickAmount: 4,
        title: {
          text: "% of specs completed",
          style: {
            color: "#0000008A",
          },
        },
      },
      legend: {
        show: false,
      },
      colors: legendColors,
    },
  };

  return (
    <S.StyledBox>
      <S.LegendWrapper>
        <CustomLegendList labels={legendLabels} colors={legendColors} />
      </S.LegendWrapper>

      <Chart options={chartData.options} series={chartData.series} type="line" height="298px" />
    </S.StyledBox>
  );
};
