import React from "react";
import { CustomTypography } from "@next/components/custom-typography";
import * as S from "./analytic-graph-card-chart-labels.styled";
import TimelineIcon from "@mui/icons-material/Timeline";

export enum ChartLabelType {
  COLUMN = "column",
  LINE = "line",
}

type Props = {
  chartLabels: {
    name: string;
    type: ChartLabelType;
  }[];
  colors: string[];
};

export const ChartLabels: React.FC<Props> = ({ chartLabels, colors }) => {
  const legendListItems = chartLabels?.map((chartLabel, i) => {
    if (chartLabel.type === ChartLabelType.LINE) {
      return (
        <S.StyledDiv key={chartLabel.name}>
          <TimelineIcon style={{ marginLeft: "24px", color: colors[i] }} fontSize="small" />

          <CustomTypography variant="body2" style={{ marginLeft: "8px", marginTop: ".2px" }}>
            {chartLabel.name}
          </CustomTypography>
        </S.StyledDiv>
      );
    } else {
      return (
        <li key={chartLabel.name} style={{ marginLeft: "24px" }}>
          <S.StyledSpan style={{ color: colors[i], fontSize: "54px" }}>•</S.StyledSpan>

          <CustomTypography variant="body2" style={{ color: "#000000" }}>
            {chartLabel.name}
          </CustomTypography>
        </li>
      );
    }
  });

  return <S.StyledUl>{legendListItems}</S.StyledUl>;
};
