import { Box, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "24px",
  borderRadius: "32px",
  backgroundColor: "#fff",
  border: `1px solid ${theme.palette.divider}`,
}));

export const TitleTypography = styled(Typography)({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  "@media (max-width: 480px)": {
    fontSize: "16px",
  },
  "@media (max-width: 445px)": {
    fontSize: "14px",
  },
});

export const TextTypography = styled(Typography)({
  fontFamily: "Space Grotesk",
  fontSize: "42px",
  lineHeight: "54px",
  "@media (max-width: 480px)": {
    fontSize: "36px",
  },
});

export const SecondaryTextTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "Space Grotesk",
  fontSize: "42px",
  lineHeight: "54px",
  color: theme.palette.text.secondary,
  "@media (max-width: 480px)": {
    fontSize: "36px",
  },
}));
