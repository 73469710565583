import { Box, styled } from "@mui/material";

export const StyledRootBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "16px",
  padding: "16px",
  alignItems: "center",
});
