import React from "react";
import * as S from "./readiness-dashboard-supplier-view-total-card.styled";

type Props = {
  title: string;
  total_value: number;
  completed_value: number;
};

export const ReadinessDashboardSupplierViewTotalCard: React.FC<Props> = ({
  title,
  completed_value,
  total_value,
}) => {
  return (
    <S.StyledBox>
      <S.TitleTypography variant="h6">{title}</S.TitleTypography>

      <S.TextTypography>{completed_value}</S.TextTypography>

      <S.SecondaryTextTypography>{total_value}</S.SecondaryTextTypography>
    </S.StyledBox>
  );
};
