import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  selectSpecsTableData,
  selectSpecsTableDataLoading,
} from "@next/modules/vendor-management/redux/selectors";
import { getReadinessDashboardSpecViewSpecsTableColumns } from "./readiness-dashboard-spec-view-specs-table.columns";
import * as S from "./readiness-dashboard-spec-view-specs-table.styled";

type Props = {
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const ReadinessDashboardSpecViewSpecsTable: React.FC<Props> = ({
  page,
  pageSize,
  setPage,
}) => {
  const isLoading = useSelector(selectSpecsTableDataLoading);
  const specsTableData = useSelector(selectSpecsTableData);
  const rows = specsTableData?.results;
  const rowCount = specsTableData?.count;
  const columns = useMemo(() => {
    return getReadinessDashboardSpecViewSpecsTableColumns();
  }, []);

  return (
    <S.StyledBox>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={40}
        autoHeight
        getRowId={(row) => row.specification}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : null)}
        pagination
        paginationMode="server"
        rowCount={rowCount || 0}
        rowsPerPageOptions={[pageSize]}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage + 1)}
        page={page - 1}
        loading={isLoading}
      />
    </S.StyledBox>
  );
};
