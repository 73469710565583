import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaginatedAutoComplete from "@next/components/paginated-autocomplete";
import { AutocompleteRenderInputParams, Box, TextField, Theme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import SupplierListItem from "@next/modules/workspace/components/rfq-creation-form/supplier-list-item";
import { selectAnalyticFilters } from "../../redux/selectors";
import { analyticsActions } from "../../redux";
import { GenericUser } from "@next/modules/profile/redux";
import { selectCompanyUsers } from "services/profile/profile.selectors";
import { getUserDisplayName } from "@next/modules/profile/helpers/profile-helpers";

type StyleProps = {
  paperWidth?: number | string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    button: {
      height: 40,
      marginLeft: theme.spacing(2),
    },
    paperComponent: {
      width: ({ paperWidth }) => paperWidth,
      marginLeft: "auto",
    },
    componentContainer: {
      padding: theme.spacing(2),
    },
    infoText: {
      color: theme.palette.action.active,
    },
  })
);

interface BuyersDropdownProps {
  getOptionDisabled?: Function;
  paperWidth?: number | string;
  multiple?: boolean;
  fieldName?: string;
}

const AnaltyticFiltersBuyersDropdown: React.FC<BuyersDropdownProps> = ({
  getOptionDisabled,
  paperWidth = 420,
  multiple = true,
}) => {
  const { t } = useTranslation();
  const users = useSelector(selectCompanyUsers);
  const dispatch = useDispatch();
  const classes = useStyles({ paperWidth });

  const analyticFilters = useSelector(selectAnalyticFilters);

  const value: any = analyticFilters?.selectedBuyers;

  const normalizedValue = useMemo(
    () =>
      value
        ? Array.isArray(value)
          ? value
          : users?.find((user) => user["email"] === value?.email)
        : undefined,
    [users, value]
  );

  const renderOption = useCallback(
    (option, { selected }) => (
      <SupplierListItem
        name={getUserDisplayName(option)}
        picture={option.picture}
        selected={selected}
        enableCheckbox
        avatarStyle={{ borderRadius: "4px" }}
      />
    ),
    []
  );

  const onSetValue = (newValue: any) => {
    dispatch(
      analyticsActions.setAnalyticFilters({
        ...analyticFilters,
        selectedBuyers: newValue,
      })
    );
  };

  const getInputFieldValue = useCallback((value: any) => {
    return `${value.name}`;
  }, []);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return (
      <TextField
        {...params}
        variant="outlined"
        label={
          analyticFilters.selectedBuyers?.length === 0
            ? t("analytics:global:allBuyers")
            : t("analytics:global:buyer")
        }
        placeholder={
          analyticFilters.selectedBuyers?.length === 0 ? t("analytics:global:allBuyers") : ""
        }
      />
    );
  };

  const renderTags = (tags: GenericUser[]) => {
    return (
      <Box pl={1}>{tags.length > 1 ? tags.length + " buyers" : getUserDisplayName(tags[0])}</Box>
    );
  };

  return (
    <Box display="flex" width={"100%"}>
      <Box flex={1} width={"100%"}>
        <PaginatedAutoComplete
          value={normalizedValue}
          onSelectionChange={onSetValue}
          options={users || []}
          renderOption={renderOption}
          getOptionSelected={(option: GenericUser, value: GenericUser) =>
            option.email === value.email
          }
          paperComponentClassName={classes.paperComponent}
          placeholder={t("workspaceNext:rfqDrawer:searchInList")}
          getOptionDisabled={getOptionDisabled}
          multiple={multiple}
          disableClearable={false}
          disableCloseOnSelect={multiple}
          getInputFieldValue={multiple ? undefined : getInputFieldValue}
          getOptionLabel={(option) => option.name || ""}
          renderInput={renderInput}
          renderTags={renderTags}
        />
      </Box>
    </Box>
  );
};

export default AnaltyticFiltersBuyersDropdown;
