import React from "react";
import { CustomLegendList } from "../custom-legend-list/custom-legend-list";
import {
  SpecViewSpecTypeCard,
  SpecViewSpecTypeDonutChartItemColors,
  SpecViewSpecTypeDonutChartItemSlugs,
} from "@next/modules/vendor-management/redux";
import * as S from "./spec-view-donut-chart.styled";

type Props = {} & SpecViewSpecTypeCard;

export const SpecViewDonutChart: React.FC<Props> = ({
  fully_compliant,
  compliant_with_rfd,
  partially_compliant,
  not_compliant,
  poe_not_collected,
  at_risk,
  out_of_scope,
}) => {
  const labels = Object.values(SpecViewSpecTypeDonutChartItemSlugs);
  const colors = Object.values(SpecViewSpecTypeDonutChartItemColors);
  const series = [
    fully_compliant,
    compliant_with_rfd,
    partially_compliant,
    not_compliant,
    poe_not_collected,
    at_risk,
    out_of_scope,
  ];

  const chartData = {
    series: series,
    options: {
      chart: {
        type: "donut" as const,
      },
      labels: labels,
      colors: colors,
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
    },
  };

  return (
    <S.StyledBox>
      <S.StyledChart options={chartData.options} series={chartData.series} type="donut" />

      <S.LegendWrapper>
        <CustomLegendList labels={labels} colors={colors} />
      </S.LegendWrapper>
    </S.StyledBox>
  );
};
