import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getReadinessDashboardSuppliersTableColumns } from "./readiness-dashboard-suppliers-table.columns";
import { ReadinessDashboardSuppliersTableRow } from "@next/modules/vendor-management/redux";
import { selectSuppliersTableDataLoading } from "@next/modules/vendor-management/redux/selectors";
import * as S from "./readiness-dashboard-suppliers-table.styled";

type Props = {
  page: number;
  pageSize: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  rows: ReadinessDashboardSuppliersTableRow[];
  rowCount: number;
};

export const ReadinessDashboardSuppliersTable: React.FC<Props> = ({
  rows,
  page,
  pageSize,
  setPage,
  rowCount,
}) => {
  const isLoading = useSelector(selectSuppliersTableDataLoading);
  const columns = useMemo(() => {
    return getReadinessDashboardSuppliersTableColumns();
  }, []);

  return (
    <S.StyledTableWrapper>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={40}
        getRowId={(row) => `${row.supplier}-${uuidv4()}`}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : null)}
        autoHeight
        pagination
        paginationMode="server"
        rowCount={rowCount || 0}
        rowsPerPageOptions={[pageSize]}
        pageSize={pageSize}
        onPageChange={(newPage) => setPage(newPage + 1)}
        page={page - 1}
        loading={isLoading}
      />
    </S.StyledTableWrapper>
  );
};
