import React from "react";
import { Button } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { StyledTabBox, StyledTypography } from "../readiness-dashboard-nav.styled";
import { vendorManagementService } from "@next/modules/vendor-management/services/vendor-management-services";

export const ReadinessDashboardNavExtractData: React.FC = () => {
  const handleClickExtractData = () => {
    vendorManagementService.extractDataRequest();
  };

  return (
    <StyledTabBox>
      <Button
        startIcon={<ArrowDownwardIcon />}
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          color: "rgba(0, 0, 0, 0.87)",
        }}
        fullWidth
        onClick={handleClickExtractData}
      >
        <StyledTypography variant="h6" sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
          EXTRACT DATA
        </StyledTypography>
      </Button>
    </StyledTabBox>
  );
};
