import { Box, Typography, styled } from "@mui/material";

export const StyledRootBox = styled(Box)({
  width: "99%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  gap: "40px",
  borderRadius: "32px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  backgroundColor: "#fff",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
  alignSelf: "flex-start",
}));
