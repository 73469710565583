import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import { getReadinessDashboardSupplierViewOnboardingTableColumns } from "./readiness-dashboard-supplier-view-onboarding-table.columns";
import * as S from "./readiness-dashboard-supplier-view-onboarding-table.styled";

export const ReadinessDashboardSupplierViewOnboardingTable = () => {
  const columns = useMemo(() => {
    return getReadinessDashboardSupplierViewOnboardingTableColumns();
  }, []);
  const dashboarData = useSelector(selectReadinessDashboardData);
  const rows = dashboarData?.supplier_view?.onboarding_positive_response;

  return (
    <S.StyledBox>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        headerHeight={48}
        rowHeight={48}
        autoHeight
        getRowId={(row) => row.category}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even" : null)}
      />
    </S.StyledBox>
  );
};
