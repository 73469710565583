import { RootState } from "store";

export const selectRequestsReport = (state: RootState) => state.analyticsNext.requestsReport;
export const selectRequestsReportLoading = (state: RootState) =>
  state.analyticsNext.requestsReportLoading;
export const selectRequestsReportError = (state: RootState) =>
  state.analyticsNext.requestsReportError;

export const selectOrdersReport = (state: RootState) => state.analyticsNext.ordersReport;
export const selectOrdersReportLoading = (state: RootState) =>
  state.analyticsNext.ordersReportLoading;

// ORDERS CHARTS SELECTORS
export const selectOrdersVolumeChart = (state: RootState) => state.analyticsNext.ordersVolumeChart;
export const selectOrdersVolumeChartLoading = (state: RootState) =>
  state.analyticsNext.ordersVolumeChartLoading;

export const selectOrdersExpectedReceiptValueChart = (state: RootState) =>
  state.analyticsNext.ordersExpectedReceiptValueChart;
export const selectOrdersExpectedReceiptValueChartLoading = (state: RootState) =>
  state.analyticsNext.ordersExpectedReceiptValueChartLoading;

export const selectOrdersOnTimeDeliveryChart = (state: RootState) =>
  state.analyticsNext.ordersOnTimeDeliveryChart;
export const selectOrdersOnTimeDeliveryChartLoading = (state: RootState) =>
  state.analyticsNext.ordersOnTimeDeliveryChartLoading;

export const selectOrdersItemLatenessChart = (state: RootState) =>
  state.analyticsNext.ordersItemLatenessChart;
export const selectOrdersItemLatenessChartLoading = (state: RootState) =>
  state.analyticsNext.ordersItemLatenessChartLoading;
// END ORDERS CHARTS SELECTORS

export const selectSupplierReport = (state: RootState) => state.analyticsNext.supplierReport;
export const selectSupplierReportLoading = (state: RootState) =>
  state.analyticsNext.supplierReportLoading;

export const selectAnalyticFilters = (state: RootState) => state.analyticsNext.analyticFilters;

export const analyticsSelectors = {
  selectRequestsReport,
  selectRequestsReportLoading,
  selectOrdersReport,
  selectOrdersReportLoading,
  selectSupplierReport,
  selectAnalyticFilters,
  selectSupplierReportLoading,
};
