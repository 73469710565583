import { Box, Typography, styled } from "@mui/material";
import Chart from "react-apexcharts";

export const StyledBox = styled(Box)({
  width: "100%",
  height: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
});

export const StyledChart = styled(Chart)({
  width: "300px",
  height: "296px",
  position: "absolute",
  marginTop: "82px",
  marginRight: "12px",
});

export const StyledTypography = styled(Typography)({
  lineHeight: "24px",
  letterSpacing: "0.5px",
  textAlign: "center",
  position: "absolute",
  marginRight: "12px",
  marginBottom: "8px",
});
