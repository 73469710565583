import React, { useMemo } from "react";
import { CustomLegendList } from "../custom-legend-list/custom-legend-list";
import {
  SpecViewStackedColumnChart,
  SpecViewStackedColumnChartItemColors,
  SpecViewStackedColumnChartItemSlugs,
} from "@next/modules/vendor-management/redux";
import * as S from "./stacked-column-chart.styled";

type Props = {} & SpecViewStackedColumnChart;

export const StackedColumnChart: React.FC<Props> = ({ x_axis, y_axes }) => {
  const orderedYAxes = useMemo(() => {
    const slugsOrder = Object.keys(SpecViewStackedColumnChartItemSlugs);
    return [...y_axes].sort((a, b) => slugsOrder.indexOf(a.slug) - slugsOrder.indexOf(b.slug));
  }, [y_axes]);
  const legendColors = useMemo(() => {
    return orderedYAxes?.map((y_axis) => SpecViewStackedColumnChartItemColors[y_axis.slug]);
  }, [orderedYAxes]);
  const legendLabels = useMemo(() => {
    return orderedYAxes?.map((y_axis) => SpecViewStackedColumnChartItemSlugs[y_axis.slug]);
  }, [orderedYAxes]);
  const series = useMemo(() => {
    return orderedYAxes?.map((y_axis) => ({
      name: SpecViewStackedColumnChartItemSlugs[y_axis.slug],
      data: y_axis.values,
    }));
  }, [orderedYAxes]);

  const chartData = {
    series: series,
    options: {
      chart: {
        type: "bar" as "bar",
        stacked: true,
        toolbar: {
          offsetX: 11,
          offsetY: -63,
          autoSelected: "zoom" as "zoom",
          tools: {
            pan: true,
            selection: false,
            zoom: false,
          },
        },
        zoom: {
          enabled: true,
          type: "x" as "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "28%",
        },
      },
      stroke: {
        width: 0,
      },
      grid: {
        show: false,
      },
      dataLabels: {
        show: false,
        enabled: false,
      },
      xaxis: {
        categories: x_axis,
        tickPlacement: "on",
        axisBorder: {
          show: false,
        },
        labels: {
          style: {
            colors: "#0000008A",
          },
        },
      },
      yaxis: [
        {
          opposite: true,
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: "Specs",
            style: {
              color: "#0000008A",
            },
          },
          tooltip: {
            enabled: false,
          },
          labels: {
            style: {
              colors: "#0000008A",
            },
            formatter: (value: number) => {
              if (value >= 1000) {
                return `${value / 1000}k`;
              }
              return value.toString();
            },
          },
        },
      ],
      legend: {
        show: false,
      },
      colors: legendColors,
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <S.StyledBox>
      <S.LegendWrapper>
        <CustomLegendList colors={legendColors} labels={legendLabels} />
      </S.LegendWrapper>

      <S.StyledChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={"348"}
      />
    </S.StyledBox>
  );
};
