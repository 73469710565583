import React from "react";
import { PublicLayout } from "layout/PublicLayout";
import { VendorManagement } from "@next/modules/vendor-management/pages/vendor-management";
import { frontendUrl } from "urls";
import { CustomRoute } from "./types";
import { Questionnaire } from "@next/modules/vendor-management/redux";
import AppLayout from "layout/AppLayout";
import { ValidationManagement } from "@next/modules/vendor-management/pages/validation-management";
import { ReadinessDashboard } from "@next/modules/vendor-management/pages/readiness-dashboard";

const VendorManagementRoutes: CustomRoute[] = [
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicVendorManagementPosEndUsersQuestionnaire}/:companyId`,
    key: "publicVendorManagement",
    exact: true,
    component: () => (
      <VendorManagement
        questionnaireName={Questionnaire.PosEndUsers}
        isFormDisplayForValidationManagement={false}
      />
    ),
  },
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicVendorManagementPomManufacturerQuestionnaire}/:companyId`,
    key: "publicVendorManagement",
    exact: true,
    component: () => (
      <VendorManagement
        questionnaireName={Questionnaire.PomManufacturer}
        isFormDisplayForValidationManagement={false}
      />
    ),
  },
  {
    layout: (props) => <PublicLayout {...props} showAppBar={false} />,
    path: `${frontendUrl.publicVendorManagementPomDistributorQuestionnaire}/:companyId`,
    key: "publicVendorManagement",
    exact: true,
    component: () => (
      <VendorManagement
        questionnaireName={Questionnaire.PomDistributor}
        isFormDisplayForValidationManagement={false}
      />
    ),
  },
  // for validation management
  {
    layout: (props) => (
      <AppLayout {...props} isHideAppBar={true} routeConfig={{ customLayoutPadding: "0px" }} />
    ),
    path: `${frontendUrl.validationManagementReviews}`,
    key: "validationManagement",
    exact: true,
    component: () => <ValidationManagement />,
    allowedRoles: ["approver", "viewer"],
  },
  {
    layout: (props) => (
      <AppLayout {...props} isHideAppBar={true} routeConfig={{ customLayoutPadding: "0px" }} />
    ),
    path: `${frontendUrl.validationManagementReviewPosEndUsers}/:companyId`,
    key: "validationManagementReviewPosEndUsers",
    exact: true,
    component: () => (
      <VendorManagement
        questionnaireName={Questionnaire.PosEndUsers}
        isFormDisplayForValidationManagement={true}
      />
    ),
    allowedRoles: ["approver", "viewer"],
  },
  {
    layout: (props) => (
      <AppLayout {...props} isHideAppBar={true} routeConfig={{ customLayoutPadding: "0px" }} />
    ),
    path: `${frontendUrl.validationManagementReviewPomManufacturer}/:companyId`,
    key: "validationManagementReviewPomManufacturer",
    exact: true,
    component: () => (
      <VendorManagement
        questionnaireName={Questionnaire.PomManufacturer}
        isFormDisplayForValidationManagement={true}
      />
    ),
    allowedRoles: ["approver", "viewer"],
  },
  {
    layout: (props) => (
      <AppLayout {...props} isHideAppBar={true} routeConfig={{ customLayoutPadding: "0px" }} />
    ),
    path: `${frontendUrl.validationManagementReviewPomDistributor}/:companyId`,
    key: "validationManagementReviewPomDistributor",
    exact: true,
    component: () => (
      <VendorManagement
        questionnaireName={Questionnaire.PomDistributor}
        isFormDisplayForValidationManagement={true}
      />
    ),
    allowedRoles: ["approver", "viewer"],
  },
  // for readiness dashboard
  {
    layout: (props) => (
      <AppLayout {...props} isHideAppBar={true} routeConfig={{ customLayoutPadding: "0px" }} />
    ),
    path: `${frontendUrl.readinessDashboard}`,
    key: "readinessDashboard",
    exact: true,
    component: () => <ReadinessDashboard />,
    allowedRoles: ["approver", "viewer"],
  },
];

export default VendorManagementRoutes;
