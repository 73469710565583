import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentParserConfiguration,
  selectSamples,
  selectSamplesLoading,
} from "@next/modules/ai/redux/selectors";
import { parserActions } from "@next/modules/ai/redux/slices";
import SampleResultAccordion from "../parser-sample-setup/sample-result-accordion";

const SampleRunner = ({ parserId }: { parserId: string; supplierName: string }) => {
  const dispatch = useDispatch();
  const samples = useSelector(selectSamples);
  const loading = useSelector(selectSamplesLoading);
  const parserConfiguration = useSelector(selectCurrentParserConfiguration);

  useEffect(() => {
    // Dispatch the action to fetch configuration
    dispatch(
      parserActions.fetchParserConfigurationRequest({
        parserId,
        sampleData: null,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(parserActions.fetchSamplesRequest(parserId));
  }, [dispatch, parserId]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={5}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box sx={{ width: "100%" }}>
      <Box style={{ padding: 20 }}>
        {samples.length === 0 ? (
          <Typography>No samples available.</Typography>
        ) : (
          samples.map((sample, index) => (
            <Accordion key={sample.id} expanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Typography>
                    Sample {index + 1}: {`Sample ID ${sample.id}`}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <SampleResultAccordion key={sample.id} parserId={parserId} sampleId={sample.id} />
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
    </Box>
  );
};

export default SampleRunner;
