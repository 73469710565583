import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Form Validation Import
import { Formik, FormikProps } from "formik";

// MaterialUI specific Import
import { Box, Grid } from "@mui/material";
import { CircularProgress } from "@mui/material";

// Image Assets
import logoNextAxya from "assets/img/logo-next-axya.svg";

// Links
import { frontendUrl } from "urls";

import { AuthActionsMenu } from "components/auth-actions-menu/auth-actions-menu.component";
import { LoginFormBase } from "./login-form-base";
import {
  TLoginFormState,
  loginFormValidationSchema,
  setLoginFormState,
} from "./login-form-definitions";

import { getIsLoading, getUsername } from "services/profile/profile.selectors";
import { profileActions } from "services/profile/profile.actions";
import { useParams } from "react-router-dom";
import { Login2FAFormBase } from "./login-2fa-form-base";
import {
  TLogin2FAFormState,
  login2faFormValidationSchema,
  setLogin2FAFormState,
} from "./login-2fa-form-definitions";
import UnsubscribedMessageBox from "./unsubscribed-message-box";
import { useQuery, useQueryParam } from "@next/hooks/useQuery";
import { useEnterKeyListener } from "@next/hooks/useEnterKeyListener";
import { createStyles, makeStyles } from "@mui/styles";
import snackbarUtils from "@next/utils/snackbarUtils";
import { appActions } from "@next/modules/app/redux";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderCon: {
      display: "grid",
      placeItems: "center",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 9999,
      background: theme.palette.common.white,
    },
  })
);

type Props = {
  unsubscribed?: boolean;
};

export const LoginForm: React.FC<Props> = ({ unsubscribed }) => {
  const formRef = useRef<FormikProps<TLoginFormState>>(null);

  const dispatch = useDispatch();
  const params = useParams<{ ephemeral_token?: string }>();
  const is2FALogin = params?.ephemeral_token !== undefined;
  const classes = useStyles();
  const [fromRecover, setFromRecover] = useQueryParam("fromRecover");
  const [fromReset, setFromReset] = useQueryParam("fromReset");
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();
  const username = useSelector(getUsername);
  const isLoading = useSelector(getIsLoading);
  const [query] = useQuery();
  const { email } = query;

  const submitLogin = (values: TLoginFormState) => {
    setInitialized(true);
    dispatch(
      appActions.checkUserEmailRequest({
        email: values?.email,
        callback: (is2Fa) => {
          if (!is2Fa) {
            dispatch(profileActions.login(values.email, values.password));
          }
        },
      })
    );
  };

  const submit2FALogin = (values: TLogin2FAFormState) => {
    setInitialized(true);
    dispatch(profileActions.loginCode(values.code, params.ephemeral_token));
  };

  if (!initialized && isLoading) {
    // Make sure that the loader is in a valid state
    dispatch(profileActions.resetLoginState());
  }

  useEffect(() => {
    if (fromRecover) {
      snackbarUtils.toast(t("auth:success:recoveryEmailSent"));
      setFromRecover(undefined);
    }
    if (fromReset) {
      snackbarUtils.success(t("auth:success:passwordUpdated"));
      setFromReset(undefined);
    }
  }, []);

  useEnterKeyListener(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    formRef?.current?.submitForm();
  });

  return (
    <div className={"auth"}>
      <Grid
        container
        className={"content content--small"}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ position: "relative" }}
      >
        {isLoading ? (
          <Box className={classes.loaderCon}>
            <CircularProgress />
          </Box>
        ) : null}

        <React.Fragment>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item>
              <img src={logoNextAxya} alt={"Axya Logo"} className={"logo"} />
            </Grid>
          </Grid>

          {unsubscribed ? (
            <UnsubscribedMessageBox />
          ) : is2FALogin ? (
            <>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Formik
                  render={(props) => <Login2FAFormBase {...props} />}
                  initialValues={setLogin2FAFormState()}
                  onSubmit={submit2FALogin}
                  validationSchema={login2faFormValidationSchema(t)}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Formik
                  render={(props) => <LoginFormBase {...props} />}
                  initialValues={setLoginFormState(initialized, username, email?.toString())}
                  onSubmit={submitLogin}
                  validationSchema={loginFormValidationSchema(t)}
                  innerRef={formRef}
                />
              </Grid>
              <AuthActionsMenu
                minorLinkUrl={frontendUrl.authIssues}
                minorLinkText={t("auth:actionsMenu:cantConnect")}
                majorText={t("auth:actionsMenu:stillNoAccount")}
                majorLinkUrl={frontendUrl.register}
                majorLinkText={t("auth:actionsMenu:join")}
              />
            </>
          )}
        </React.Fragment>
      </Grid>
    </div>
  );
};

export default LoginForm;
