import { PaginationAndFilterForSpecification } from "../redux";

export const generateSearchAndFilterSpecQuery = (
  params: PaginationAndFilterForSpecification
): string => {
  const {
    page,
    pageSize,
    search,
    supplier,
    status,
    specType,
    isPomDistributor,
    stepId,
    filter_completed,
    entityId,
    supplierType,
    searchTerm,
  } = params;
  let query = `page=${page}&pageSize=${pageSize}`;

  if (search) {
    query += `&search=${encodeURIComponent(search)}`;
  }

  if (searchTerm) {
    query += `&searchTerm=${encodeURIComponent(searchTerm)}`;
  }

  if (supplier) {
    query += `&supplier=${encodeURIComponent(supplier)}`;
  }

  if (status) {
    query += `&status=${encodeURIComponent(status)}`;
  }

  if (specType) {
    if (Array.isArray(specType)) {
      specType.forEach((type) => {
        query += `&specType=${encodeURIComponent(type)}`;
      });
    } else {
      query += `&specType=${encodeURIComponent(specType)}`;
    }
  }

  if (isPomDistributor) {
    query += `&questionnaire_type=pom_distributors`;
  }

  if (filter_completed) {
    query += `&filter_completed=${encodeURIComponent(filter_completed)}`;
  }

  if (stepId) {
    query += `&step_id=${encodeURIComponent(stepId)}`;
  }

  if (entityId || entityId === null) {
    query += `&entity_id=${encodeURIComponent(entityId)}`;
  }

  if (supplierType) {
    query += `&supplier_type=${encodeURIComponent(supplierType)}`;
  }

  return query;
};
