import { Box, Typography, styled } from "@mui/material";

export const StyledRootBox = styled(Box)({
  width: "100%",
  padding: "24px",
  gap: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

type TabProps = {
  isSelected?: boolean;
  isThereBorderRadius?: boolean;
};

export const StyledTabBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isThereBorderRadius",
})<TabProps>(({ isSelected, isThereBorderRadius, theme }) => ({
  width: "100%",
  height: "56px",
  borderRadius: isThereBorderRadius ? "48px" : "0px",
  border: !isSelected ? "1px solid rgba(0, 0, 0, 0.15)" : "none",
  backgroundColor: isSelected ? "#001D70" : "#FFFFFF",
  color: isSelected ? "#FFFFFF" : theme.palette.text.primary,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "12px",
  cursor: "pointer",
}));

export const StyledTypography = styled(Typography)({ lineHeight: "24px", letterSpacing: "0.15px" });
