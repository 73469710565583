import React from "react";
import * as S from "./custom-legend-list.styled";

type Props = {
  labels: string[];
  colors: string[];
};
export const CustomLegendList: React.FC<Props> = ({ labels, colors }) => {
  return (
    <S.StyledBox>
      {labels?.map((label, i) => (
        <S.StyledItemBox key={label}>
          <S.StyledMarker style={{ backgroundColor: colors[i] }}></S.StyledMarker>

          <S.StyledTypography variant="body2">{label}</S.StyledTypography>
        </S.StyledItemBox>
      ))}
    </S.StyledBox>
  );
};
