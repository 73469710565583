import React, { forwardRef } from "react";

import { TextField } from "@mui/material";

export const GenericAutoCompleteTextField = forwardRef(
  ({ placeholder, rows, label, ...props }, ref) => {
    // const [field, meta] = useField(props);
    // const errorText = meta.error && meta.touched ? meta.error : "";

    return (
      <TextField
        ref={ref}
        inputProps={{ tabIndex: "0", readOnly: true }}
        readOnly={true}
        placeholder={placeholder}
        fullWidth
        label={label}
        rows={rows}
        multiline={!!rows}
        // helperText={errorText}
        // error={!!errorText}
        id="outlined-basic"
        variant="outlined"
        className="form-text-field"
        color="secondary" /** The focus color */
        {...props}
      />
    );
  }
);
