import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import PartViewer from "components/part-dialog/viewer/viewer.component";

const RawTextDialog: React.FC<RawTextDialogProps> = ({ rawText, pdfUrl, onSelect, onClose }) => {
  const [selectedText, setSelectedText] = useState("");
  const [highlightedText, setHighlightedText] = useState(rawText);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleMouseUp = () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString();

    if (selectedText) {
      const range = selection.getRangeAt(0);
      const startOffset = range.startOffset;
      const endOffset = range.endOffset;

      // Highlight the selected text in yellow
      const highlighted = `${rawText.substring(
        0,
        startOffset
      )}<mark style="background-color: yellow;">${selectedText}</mark>${rawText.substring(
        endOffset
      )}`;

      setSelectedText(selectedText);
      setHighlightedText(highlighted);
    }
  };

  const handleConfirmSelection = () => {
    if (selectedText) {
      onSelect(selectedText);
      onClose();
    }
  };

  const handleNextPage = () => {
    if (pageNumber < (numPages || 1)) setPageNumber(pageNumber + 1);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) setPageNumber(pageNumber - 1);
  };

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          minHeight: "95vh",
        },
      }}
    >
      <DialogTitle>Select Text</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ flexGrow: 1, padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Paper>
                <Typography variant="h6">Raw Text</Typography>
                <Box
                  sx={{
                    whiteSpace: "pre",
                    overflowX: "auto",

                    overflowY: "scroll",
                  }}
                  onMouseUp={handleMouseUp}
                >
                  <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
                </Box>
                <Box mt={2}>
                  <Typography variant="h6">Selected Text</Typography>
                  {selectedText ? (
                    <Typography variant="body2">{selectedText}</Typography>
                  ) : (
                    <Typography variant="body2">
                      <i>Select text from the panel above.</i>
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper sx={{ padding: 2, height: "100%", overflow: "auto" }}>
                <Typography variant="h6">PDF Preview</Typography>
                <PartViewer src={pdfUrl} disableSnapshot={true} />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmSelection} color="primary" disabled={!selectedText}>
          Confirm Selection
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RawTextDialog;
