import { Box, Typography, styled } from "@mui/material";

export const StyledTitleBox = styled(Box)(({ theme }) => ({
  width: "99%",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
  padding: "40px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "32px",
  backgroundColor: theme.palette.background.paper,
  border: `1px solid #0000000D`,
}));

export const StyledTypography = styled(Typography)({
  textAlign: "center",
  letterSpacing: "0.15px",
  lineHeight: "24px",
});
