import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, styled } from "@mui/material";
import { StyledApprovedIcon, StyledCompleteIcon } from "../../form-step-box/form-step-box.styled";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HideSourceIcon from "@mui/icons-material/HideSource";
import RemoveIcon from "@mui/icons-material/Remove";
import ErrorIcon from "@mui/icons-material/Error";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import { CustomTypography } from "@next/components/custom-typography";
import { DashboardStatusCellSlugs } from "@next/modules/vendor-management/redux";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  alignItems: "center",
});

const StyledAcknowledgedIcon = styled(CheckIcon)(({ theme }) => ({
  color: "",
  width: "24px",
  height: "24px",
}));

const StyledUnacknowledgedIcon = styled(PanoramaFishEyeIcon)(({ theme }) => ({
  color: "#2B82AB",
  width: "24px",
  height: "24px",
}));

const StyledNotStartedIcon = styled(PanoramaFishEyeIcon)(({ theme }) => ({
  color: "#242D66",
  width: "24px",
  height: "24px",
}));

const StyledBouncedIcon = styled(RemoveIcon)(({ theme }) => ({
  color: "#242D66",
  width: "24px",
  height: "24px",
}));

const StyledClosedIcon = styled(CloseIcon)(({ theme }) => ({
  color: "#1C1B1F",
  width: "24px",
  height: "24px",
}));

const StyledGreenCheckIcon = styled(CheckCircleIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  width: "24px",
  height: "24px",
}));

const StyledHideSourceIcon = styled(HideSourceIcon)({
  color: "#242D66",
  width: "24px",
  height: "24px",
});

export const StyledReadyForSCQMIcon = styled(CheckCircleIcon)({
  color: "#2B82AB",
  width: "24px",
  height: "24px",
});

export const StyledInReviewIcon = styled(ErrorIcon)({
  color: "#7497AD",
  width: "24px",
  height: "24px",
});

export const StyledPartialCompleteIcon = styled(TimelapseRoundedIcon)({
  color: "#2B82AB",
  width: "24px",
  height: "24px",
});

const StyledNotMigratedIcon = styled(PanoramaFishEyeIcon)({
  color: "#7497AD",
  width: "24px",
  height: "24px",
});

const Icons = {
  approved: StyledApprovedIcon,
  "In review": StyledInReviewIcon,
  review_required: StyledInReviewIcon,
  completed: StyledCompleteIcon,
  ready_for_scqm: StyledReadyForSCQMIcon,
  in_progress_by_supplier: StyledPartialCompleteIcon,
  in_progress_by_scqm: StyledPartialCompleteIcon,
  not_started: StyledNotStartedIcon,
  Acknowledged: StyledAcknowledgedIcon,
  Unacknowledged: StyledUnacknowledgedIcon,
  Bounced: StyledBouncedIcon,
  out_of_scope: StyledClosedIcon,
  "Out of scope": StyledClosedIcon,
  "Not migrated": StyledNotMigratedIcon,
  "Partially migrated": StyledPartialCompleteIcon,
  "Already migrated": StyledGreenCheckIcon,
  "POE not collected": StyledHideSourceIcon,
  "Fully migrated": StyledGreenCheckIcon,
  "Already compliant": StyledGreenCheckIcon,
  "Partially compliant": StyledPartialCompleteIcon,
  "Not compliant": StyledUnacknowledgedIcon,
};

export const DashboardStatusCell: React.FC<GridRenderCellParams<any>> = (params) => {
  const Icon = Icons[params.value];

  return (
    <StyledBox>
      {Icon ? <Icon /> : null}

      <CustomTypography>{DashboardStatusCellSlugs[params.value]}</CustomTypography>
    </StyledBox>
  );
};
