import { Box, Typography, styled } from "@mui/material";

type Props = {
  isRouted: boolean;
};

export const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isRouted",
})<Props>(({ theme, isRouted }) => ({
  width: "276px",
  height: "48px",
  backgroundColor: "#0A2C8F",
  borderRadius: "8px",
  padding: "12px",
  cursor: "pointer",
  border: isRouted ? "1px solid #FFFFFF" : "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  transition: "width 0.3s ease",
  [theme.breakpoints.down("md")]: {
    width: "200px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "180px",
  },
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#FFFFFF",
  lineHeight: "20px",
  letterSpacing: "0.5px",
  transition: "font-size 0.3s ease",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));
