import React from "react";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { HeaderCell } from "../table-cells/header-cell";
import { ProgressCell } from "../table-cells/progress-cell";
import { CustomTypography } from "@next/components/custom-typography";

const defaultColumnConfig: Partial<GridColDef> = {
  flex: 0.5,
  sortable: false,
  filterable: false,
  disableColumnMenu: true,
  editable: false,
  renderHeader: HeaderCell,
  minWidth: 220,
};

export const getReadinessDashboardSupplierViewOnboardingTableColumns = () => {
  const columns: GridColDef[] = [
    {
      ...defaultColumnConfig,
      field: "category",
      headerName: "Supplier category",
      minWidth: 160,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <CustomTypography fontWeight={700}>{params?.value?.toString()}</CustomTypography>;
      },
    },
    {
      ...defaultColumnConfig,
      field: "isupply",
      headerName: "Isupply",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "am9000",
      headerName: "AM9000",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "a2emm_001_emcm",
      headerName: "A2EMM-001 (EMCM)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "aqpl_airbus_com",
      headerName: "AQPL Airbus.com",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "a220_asl_airbus_com",
      headerName: "A220 ASL Airbus.com",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "normaster",
      headerName: "Normaster",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "icms",
      headerName: "ICMS (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "icms2",
      headerName: "ICMS2 (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "eqsl_bbd",
      headerName: "eQSL (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "eqsl_airbus",
      headerName: "eQSL (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "a220bok_plm002_bbd",
      headerName: "A220BOK (PLM002) (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "acer_eadms_airbus",
      headerName: "ACER (eADMS) (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "bm7024_tool_plm025",
      headerName: "BM7024 tool (PLM 025)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "ecra_plm063",
      headerName: "eCRA (PLM063)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "elasticsearch_plm069",
      headerName: "Elasticsearch (PLM069)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "mp_search_tool_plm128",
      headerName: "M&P search tool (PLM128)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "psrm_plm163",
      headerName: "PSRM (PLM163)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "edv8_bbd",
      headerName: "EDV8 (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "edv8_airbus",
      headerName: "EDV8 (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "navigator_ietp_bbd",
      headerName: "Navigator IETP (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "a220_ietp_portal_airbus",
      headerName: "A220 IETP Portal (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "cust_snags_bbd",
      headerName: "Cust. Snags (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "clb_eqlb_airbus",
      headerName: "CLB / eQLB (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "aceri_bbd",
      headerName: "ACERI (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "capital_a220_airbus",
      headerName: "Capital A220 (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "eroom_build_to_print",
      headerName: "eRoom Build to Print (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "maps",
      headerName: "MAPS (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "eroom_gest_de_change",
      headerName: "eRoom Gest. De Change",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "rh1",
      headerName: "RH1 (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "workday",
      headerName: "Workday (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "strategia",
      headerName: "Strategia (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "peoplefluentlms_and_certification",
      headerName: "PeopleFluentLMS & Certification (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "efim_bbd",
      headerName: "eFIM (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "efim_airbus",
      headerName: "eFIM (Legal app Airbus)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "a220_supplier_collaboration",
      headerName: "A220 Supplier Collaboration (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "arms_for_partners",
      headerName: "ARMS for Partners (employee at the company) (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "csa_portal_or_customs",
      headerName: "CSA Portal/Customs (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "datalink",
      headerName: "Datalink (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "documentum_for_AHMS_and_LSAP_vault",
      headerName: "Documentum for AHMS & LSAP Vault (v7) (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "econformity_ats",
      headerName: "eConformity- ATS (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "econformity_sap",
      headerName: "eConformity- SAP Corrective Action (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "econformity_vrnc",
      headerName: "eConformity- VRNC (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "edimt",
      headerName: "EDIMT (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "e_journal",
      headerName: "eJournal (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "engineering_fracas",
      headerName: "Engineering FRACAS (RELEX) (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "enovia_ecosystem",
      headerName: "ENOVIA eco-system (CATIA, ENOVIA, eProduct, PDD) (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "q_checker",
      headerName: "Q-Checker (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "sap_snc_qualtiy_cfolder",
      headerName: "SAP SNC - Quality cFolder (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
    {
      ...defaultColumnConfig,
      field: "secure_data_exchange",
      headerName: "Secure Data Exchange (SDE) (Legal app BBD)",
      renderCell: ProgressCell,
      headerAlign: "center",
    },
  ];

  return columns;
};
