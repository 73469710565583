import { Box, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: "99%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: "32px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  backgroundColor: "#fff",
  padding: "48px",
  gap: "24px",
  margin: "0",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
  alignSelf: "flex-start",
}));
