import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { CURRENCY_OPTIONS } from "@next/utils/countryList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { analyticsActions } from "../../redux";
import { selectAnalyticFilters } from "../../redux/selectors";

type Props = {};

export const AnalyticFiltersCurrencyDropdown = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticFilters = useSelector(selectAnalyticFilters);

  const onSetValue = (currency: string) => {
    dispatch(
      analyticsActions.setAnalyticFilters({
        ...analyticFilters,
        currency,
      })
    );
  };
  return (
    <FormControl fullWidth>
      <InputLabel>{t("analytics:global:currency")}</InputLabel>
      <Select
        labelId={`currency`}
        label={t("analytics:global:currency")}
        size="small"
        sx={{
          minWidth: "200px",
        }}
        value={analyticFilters.currency}
        onChange={(e) => {
          onSetValue(e.target.value as string);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }}
      >
        <MenuItem value={CURRENCY_OPTIONS.USD}>$ USD</MenuItem>
        <MenuItem value={CURRENCY_OPTIONS.CAD}>$ CAD</MenuItem>
        <MenuItem value={CURRENCY_OPTIONS.EUR}>€ EUR</MenuItem>
      </Select>
    </FormControl>
  );
};
