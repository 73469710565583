import { Box, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: "264px",
  height: "264px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
});

export const StyledContentBox = styled(Box)({
  width: "152px",
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  top: "148px",
  transform: "translateY(-50%)",
});

export const StyledTopTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "color",
})<{ color: string }>(({ theme, color }) => ({
  fontWeight: "700",
  lineHeight: "36px",
  color: color,
}));

export const StyledBottomTypography = styled(Typography)({
  fontWeight: "400",
  lineHeight: "36px",
  color: "rgba(0, 0, 0, 0.54)",
});

export const StyledNameTypography = styled(Typography)({
  lineHeight: "20px",
  color: "rgba(0, 0, 0, 0.87)",
  letterSpacing: "0.25px",
  textAlign: "center",
});
