import { Box, Typography, styled } from "@mui/material";
import Chart from "react-apexcharts";

export const StyledRootBox = styled(Box)({
  width: "100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
}));

export const StyledLegendWrapper = styled(Box)({
  position: "absolute",
  bottom: 0,
});

export const StyledChart = styled(Chart)({
  width: "420px",
  height: "360px",
  position: "absolute",
  bottom: -50,

  "@media (max-width: 1100px) and (min-width: 900px)": {
    width: "350px",
    height: "350px",
  },
});
