import { AppTheme } from "layout/AppTheme";
import React from "react";
import Chart from "react-apexcharts";
import {
  ChartLabelType,
  ChartLabels,
} from "../../analytic-graph-card/analytic-graph-card-legend-list/analytic-graph-card-chart-labels";
import { RequestsReportChart } from "@next/modules/analytics/redux";
import { formatNumber } from "helpers/formatNumber";
import { useTranslation } from "react-i18next";

const colors = [
  AppTheme.palette.primary.light,
  AppTheme.palette.secondary.main,
  AppTheme.palette.info.main,
];

export const RequestsReportCumulativeColumnChart: React.FC<RequestsReportChart> = ({
  id,
  x_axis,
  y_axes,
}) => {
  const { t } = useTranslation();
  const chartLabels = [
    {
      name: t("analytics:global:itemsAwarded"),
      type: ChartLabelType.COLUMN,
    },
    {
      name: t("analytics:global:totalValue"),
      type: ChartLabelType.COLUMN,
    },
    {
      name: t("analytics:global:cumulativeValue"),
      type: ChartLabelType.LINE,
    },
  ];

  const data = {
    series: [
      {
        name: chartLabels[0].name,
        type: "column",
        data: y_axes[1].values,
      },
      {
        name: chartLabels[1].name,
        type: "column",
        data: y_axes[0].values,
      },
      {
        name: chartLabels[2].name,
        type: "line",
        data: y_axes[2].values,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line" as "line",
        stacked: false,
        toolbar: {
          autoSelected: "zoom" as "zoom",
          tools: {
            pan: false,
            selection: false,
            zoom: false,
          },
        },
        zoom: {
          enabled: true,
          type: "x" as "x",
          autoScaleYaxis: true,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "50%",
          borderRadius: 2,
          borderRadiusWhenStacked: "all" as "all",
          borderRadiusApplication: "around" as "around",
        },
      },
      stroke: {
        width: [1, 1, 2],
        colors: ["", "", AppTheme.palette.info.main],
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: x_axis,
        tickPlacement: "on",
      },
      yaxis: [
        {
          lines: {
            show: false,
          },
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: chartLabels[0].name,
          },
          tooltip: {
            enabled: true,
          },
          labels: {
            formatter: (value: number) => formatNumber(value),
          },
        },

        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => `${formatNumber(value)} $`,
          },
          title: {
            text: chartLabels[1].name,
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          opposite: true,
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: (value: number) => `${formatNumber(value)} $`,
          },
          title: {
            text: chartLabels[2].name,
          },
          tooltip: {
            enabled: true,
          },
        },
      ],
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
      },
      legend: {
        show: false,
      },
      colors: [colors[0], colors[1], colors[2]],
      fill: {
        opacity: 1,
      },
    },
  };

  return (
    <>
      <ChartLabels chartLabels={chartLabels} colors={colors} />
      <div style={{ marginLeft: "-12px" }}>
        <Chart options={data.options} series={data.series} type="bar" height={"370"} />
      </div>
    </>
  );
};
