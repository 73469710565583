import React, { useEffect } from "react";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { CustomNodeDefinition, CustomNodeProps, JsonEditor } from "json-edit-react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSampleTestData,
  selectSampleTestDataError,
  selectSampleTestDataLoading,
} from "@next/modules/ai/redux/selectors";
import { parserActions } from "@next/modules/ai/redux/slices";
import { Box, Button, Typography } from "@mui/material";
import { lowerCase } from "lodash";

interface UpdatedData {
  newData: any;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "white",
  color: "#0baa58",
  fontWeight: "bold",
  borderRadius: "4px",
  border: `2px solid #0baa58`,
  padding: "0 25px",
  height: "24px",
  "& .MuiChip-label": {
    padding: 0,
    fontSize: "0.75rem",
    lineHeight: "1.5",
  },
}));

const StyledMatchedValueByNameChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "white",
  color: "#0d88bd",
  borderRadius: "4px",
  border: `2px solid #0d88bd`,
  padding: "0 25px",
  height: "24px",
  "& .MuiChip-label": {
    padding: 0,
    fontSize: "1rem",
    lineHeight: "1.5",
  },
}));

const StyledMatchedValueByLineNumberQuantityChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "white",
  color: "red",
  borderRadius: "4px",
  border: `2px solid red`,
  padding: "0 25px",
  height: "24px",
  "& .MuiChip-label": {
    padding: 0,
    fontSize: "1rem",
    lineHeight: "1.5",
  },
}));

const SpecialValue: React.FC<CustomNodeProps> = ({ value }) => {
  if (!value) {
    return null;
  }

  if (lowerCase(value.toString()).indexOf("match") > -1)
    if (lowerCase(value.toString()).indexOf("line number") > -1) {
      return (
        <StyledMatchedValueByLineNumberQuantityChip label={value.toString()} variant="filled" />
      );
    } else {
      return <StyledMatchedValueByNameChip label={value.toString()} variant="filled" />;
    }
  else {
    return <StyledChip label={value.toString()} variant="filled" />;
  }
};

const customNodeDefinitions: CustomNodeDefinition<
  Record<string, unknown>,
  Record<string, unknown>
>[] = [
  {
    condition: ({ key }: { key: any }) => {
      return [
        "_unit_price",
        "_leadtime_days",
        "_leadtime_unit",
        "total_price",
        "_quantity",
        "_line_number",
        "_item_name",
        "_sub_total",
        "_match",
      ].includes(key);
    },
    element: SpecialValue,
    showOnView: true,
    showOnEdit: true,
    name: "Special Value",
  },
];

const RFQSampleViewer = ({ parserId, sampleId, sampleData }) => {
  const dispatch = useDispatch();

  const loading = useSelector(selectSampleTestDataLoading);
  const error = useSelector(selectSampleTestDataError);
  const sampleTestData = useSelector(selectSampleTestData);

  useEffect(() => {
    dispatch(parserActions.fetchSampleTestDataRequest({ parserId, sampleId }));
  }, [dispatch, parserId, sampleId]);

  const handleDataChange = (updatedData: UpdatedData) => {
    dispatch(
      parserActions.updateSampleTestDataRequest({
        parserId,
        sampleId,
        payload: updatedData?.newData,
      })
    );
  };

  const handleImportSample = () => {
    dispatch(parserActions.createSampleTestDataRequest({ parserId, sampleId }));
  };

  const handleCreateSampleAsProject = () => {
    dispatch(parserActions.createSampleTestDataAsProjectRequest({ parserId, sampleId }));
  };
  return (
    <>
      <Box sx={{ padding: "16px", backgroundColor: "#f4f4f4" }}>
        <Box
          sx={{
            marginBottom: "16px",
            display: "flex",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: "4px" }}>
            Test Data
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateSampleAsProject}
            sx={{
              marginLeft: 10,
              marginRight: 1,
            }}
          >
            Create Sample as Project
          </Button>
          <Button variant="contained" color="primary" onClick={handleImportSample}>
            Import Sample
          </Button>
        </Box>
        {loading && <p>Loading...</p>}
        {error && <p>Error: {error}</p>}
        {sampleTestData && (
          <Box>
            <JsonEditor
              data={sampleTestData}
              rootName="RFQ Data"
              indent={5}
              customNodeDefinitions={customNodeDefinitions}
              onUpdate={handleDataChange}
              showCollectionCount={false}
              showArrayIndices={false}
              showStringQuotes={false}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default RFQSampleViewer;
