import React from "react";
import { useSelector } from "react-redux";
import { StackedColumnChart } from "../../charts/stacked-column-chart/stacked-column-chart";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import { SpecViewStackedColumnChart } from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-spec-view-tracking-chart-card.styled";

export const ReadinessDashboardSpecViewTrackingChartCard: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const chartData: SpecViewStackedColumnChart = dashboardData?.spec_view?.poe_date_chart;

  return (
    <S.StyledRootBox>
      <S.StyledTypography variant="h6">POE date tracking at specification level</S.StyledTypography>

      {chartData ? (
        <StackedColumnChart x_axis={chartData.x_axis} y_axes={chartData.y_axes} />
      ) : null}
    </S.StyledRootBox>
  );
};
