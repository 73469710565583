import React from "react";
import { OverviewGraphChartTitleSlugs } from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-overview-graph-chart-card.styled";

type Props = {
  slug: keyof typeof OverviewGraphChartTitleSlugs;
  GraphComponent: React.ReactNode;
};

export const ReadinessDashboardOverviewGraphChartCard: React.FC<Props> = ({
  slug,
  GraphComponent,
}) => {
  return (
    <S.StyledCardBox>
      <S.StyledTypography variant="h6">{OverviewGraphChartTitleSlugs[slug]}</S.StyledTypography>

      {GraphComponent}
    </S.StyledCardBox>
  );
};
