import { Box, styled } from "@mui/material";

export const StyledRootBox = styled(Box)({
  overflowY: "visible",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  marginTop: "64px",
  overflowX: "hidden",
  alignItems: "center",
});

export const StyledStickyNavWrapper = styled(Box)({
  position: "sticky",
  top: 70,
  zIndex: 1000,
  backgroundColor: "#F0F7FB",
  width: "100%",
});
