import { Box, Typography, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "13px",
  flexWrap: "wrap",
});

export const StyledItemBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
});

export const StyledMarker = styled(Box)({
  width: "15px",
  height: "15px",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "20px",
  letterSpacing: "0.25px",
  color: theme.palette.text.primary,
  "@media (max-width: 1500px)": {
    fontSize: "12px",
  },
  "@media (max-width: 1300px)": {
    fontSize: "11px",
  },
  "@media (max-width: 1150px)": {
    fontSize: "10px",
  },
  "@media (max-width: 899px)": {
    fontSize: "16px",
  },
  "@media (max-width: 600px)": {
    fontSize: "13px",
  },
  "@media (max-width: 400px)": {
    fontSize: "9px",
  },
}));
