import { Box, Typography, styled } from "@mui/material";

export const StyledChartBox = styled(Box)({
  width: "100%",
  height: "462px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "40px",
  borderRadius: "32px",
  border: "1px solid rgba(0, 0, 0, 0.05)",
  backgroundColor: "#fff",
});

export const StyledTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "24px",
  letterSpacing: "0.15px",
  textAlign: "center",
  color: theme.palette.text.primary,
  "@media (max-width: 1500px) and (min-width: 1200px)": {
    fontSize: "18px",
  },
  "@media (max-width: 1200px) and (min-width: 1150px)": {
    fontSize: "14px",
  },
  "@media (max-width: 1150px) and (min-width: 900px)": {
    fontSize: "12px",
  },
}));
