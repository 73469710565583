import React from "react";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { ReadinessDashboardOverviewSemiDonutChartCard } from "./readiness-dashboard-overview-semi-donut-chart-card/readiness-dashboard-overview-semi-donut-chart-card";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import { SemiDonutChart as SemiDonutChartType } from "@next/modules/vendor-management/redux";

export const ReadinessDashboardOverviewSemiDonutChartsGroup: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const donutCharts: SemiDonutChartType[] = dashboardData?.overview?.semi_donut_charts;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
        {donutCharts?.map((chart, index) => (
          <Grid key={chart.slug} item xs={12} md={4} lg={4}>
            <ReadinessDashboardOverviewSemiDonutChartCard slug={chart.slug} items={chart.items} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
