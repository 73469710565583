import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ClickAwayListener, Menu } from "@mui/material";
import { StyledTabBox, StyledTypography } from "../readiness-dashboard-nav.styled";
import { vendorManagementActions } from "@next/modules/vendor-management/redux";
import { selectSupplierFilter } from "@next/modules/vendor-management/redux/selectors";
import * as S from "./readiness-dashboard-nav-filter.styled";

const menuItems = ["All Suppliers", "P1", "P2", "P3", "P4", "P5"];

export const ReadinessDashboardNavFilter: React.FC = () => {
  const dispatch = useDispatch();
  const supplierFilter = useSelector(selectSupplierFilter);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [widthOfMenu, setWidthOfMenu] = useState(0);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setWidthOfMenu(event.currentTarget.offsetWidth);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    dispatch(vendorManagementActions.setSupplierFilter(value));
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <>
        <StyledTabBox onClick={handleOpen}>
          <StyledTypography variant="h6">{supplierFilter}</StyledTypography>

          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </StyledTabBox>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ ".MuiList-root": { padding: 0 } }}
          slotProps={{
            paper: {
              style: {
                width: widthOfMenu,
                textAlign: "center",
                borderRadius: 0,
                marginTop: "1px",
              },
            },
          }}
        >
          {menuItems.map((item) => (
            <S.StyledMenuItem
              key={item}
              onClick={() => handleSelect(item)}
              selected={supplierFilter === item}
            >
              <StyledTypography variant="h6">{item}</StyledTypography>
            </S.StyledMenuItem>
          ))}
        </Menu>
      </>
    </ClickAwayListener>
  );
};
