import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ReadinessDashboardOverviewGraphChartCard } from "./readiness-dashboard-overview-graph-chart-card/readiness-dashboard-overview-graph-chart-card";
import { MultipleLinedChart } from "../../charts/multiple-lined-chart/multiple-lined-chart";
import { AreaChart } from "../../charts/area-chart/area-chart";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";

const Charts = { poe_collected_monthly: MultipleLinedChart, questionnaire_progression: AreaChart };

export const ReadinessDashboardOverviewGraphChartsGroup: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  // TODO: use whole overviewGraphCharts array after if client wants second chart
  const overviewGraphCharts = dashboardData?.overview?.charts[0] as unknown as any[];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ width: "100%", justifyContent: "center" }} spacing={2}>
        {overviewGraphCharts?.map((chart, index) => {
          const ChartComponent = Charts[chart.slug];
          if (index === 1) return null; // TODO: remove this line after if client wants second chart
          return (
            <Grid key={index} item xs={12} md={12} lg={12}>
              <ReadinessDashboardOverviewGraphChartCard
                key={index}
                slug={chart.slug}
                GraphComponent={
                  ChartComponent ? (
                    <ChartComponent slug={chart.slug} x_axis={chart.x_axis} y_axes={chart.y_axes} />
                  ) : null
                }
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
