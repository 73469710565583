import { Box, styled } from "@mui/material";
import Chart from "react-apexcharts";

export const StyledBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "40px",
});

export const LegendWrapper = styled(Box)({
  alignSelf: "flex-start",
});

export const StyledChart = styled(Chart)(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.divider}`,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
