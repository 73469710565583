import React from "react";
import { Box, styled } from "@mui/material";
import { ValidationManagementAppBar } from "../components/validation-management/validation-management-app-bar/validation-management-app-bar";
import { ReadinessDashboardBody } from "../components/readiness-dashboard/readiness-dashboard-body/readiness-dashboard-body";

const StyledBox = styled(Box)({
  width: "100%",
  backgroundColor: "#F0F7FB",
});

export const ReadinessDashboard: React.FC = () => {
  return (
    <StyledBox>
      <ValidationManagementAppBar />

      <ReadinessDashboardBody />
    </StyledBox>
  );
};
