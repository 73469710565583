import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { GridRenderCellParams } from "@mui/x-data-grid-pro-v5";
import { Box, Button, Typography, styled } from "@mui/material";
import RecommendIcon from "@mui/icons-material/Recommend";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import {
  MessageThread,
  PosEndUsersFormKeyMapping,
  Step,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectIsFormDisplayForValidationManagement,
  selectMessageThreadsOfStep,
  selectNumberOfUnapprovedLines,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
});

const StyledApprovedIcon = styled(RecommendIcon)(({ theme }) => ({
  color: theme.palette.success.main,
  width: "24px",
  height: "24px",
}));

const StyledInReviewIcon = styled(ErrorIcon)(({ theme }) => ({
  color: theme.palette.warning.main,
  width: "24px",
  height: "24px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "73.5px",
  height: "32px",
  borderRadius: "8px",
  gap: "8px",
  padding: "6px 12px",
  border: "1px solid rgba(0, 0, 0, 0.25)",
}));

const StyledBtnForResolveAndApproved = styled(Button)({
  display: "flex",
  flexDirection: "row",
  height: "32px",
  width: "155px",
  borderRadius: "8px",
  padding: "6px 12px",
  border: "1px solid rgba(0, 0, 0, 0.25)",
  justifyContent: "flex-start",
});

export const StyledBtnBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "24px",
  height: "24px",
  position: "absolute",
  right: "8px",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  "&:active": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    transition: "background-color 0.3s ease",
  },
});

const StyledCloseIcon = styled(CloseIcon)({
  color: "rgba(28, 27, 31, 1)",
  width: "16px",
  height: "16px",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.25px",
  [theme.breakpoints.down("md")]: {
    fontSize: "12px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
  },
}));

type Props = {
  params: GridRenderCellParams<any>;
  supplier_title?: string;
};

export const ValidationCell: React.FC<Props> = ({ params, supplier_title }) => {
  const dispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();

  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const activeStep: Step = steps[activeFormStepIndex];
  const isStepDesignAndBuild = activeStep?.step_name === PosEndUsersFormKeyMapping.DesignAndBuild; // it doesn't have "name" field in row
  const numberOfUnapprovedLines = useSelector(selectNumberOfUnapprovedLines);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const isApproved = params?.row?.approval_status === "approved";
  const messageThreads: MessageThread[] = useSelector(selectMessageThreadsOfStep);
  const possibleMessageThreadOfSpec = messageThreads?.find(
    (thread: MessageThread) => thread.entity_id === params?.row?.entity_id
  );
  const messageThreadOfSpec = possibleMessageThreadOfSpec // if there is a thread for the spec, use it, otherwise assign a new one from the spec for the review process, api needs that
    ? possibleMessageThreadOfSpec
    : {
        id: null,
        is_resolved: null,
        title: !isStepDesignAndBuild ? params?.row?.name : params?.row?.drawing_ref,
        entity_id: params?.row?.entity_id,
        entity_name: params?.row?.entity_name,
        supplier_title: supplier_title ? supplier_title : null,
      };

  const openDrawer = () => {
    dispatch(vendorManagementActions.setIsDrawerOpen(true));
    dispatch(vendorManagementActions.setCurrentMessageThread(messageThreadOfSpec));
    dispatch(vendorManagementActions.setIsShowReviewBoxForThreadfulReview(true));
  };

  const handleClickApproveBtn = () => {
    dispatch(
      vendorManagementActions.updateApprovalStatusOfSpecRequest({
        companyId: companyId,
        stepId: activeStep.step,
        body: {
          status: "approved",
          entity_id: params?.row?.entity_id,
          entity_name: params?.row?.entity_name,
        },
        onSuccess: onSuccessForUpdateApprovalStatusOfSpec,
      })
    );
  };

  const onSuccessForUpdateApprovalStatusOfSpec = () => {
    if (numberOfUnapprovedLines === 1) {
      updateApprovalStatusOfStep();
    }
    dispatch(vendorManagementActions.decreaseNumberOfUnapprovedLinesOfStep());
    dispatch(vendorManagementActions.triggerForFetchingSpecsOfStep());
  };

  const updateApprovalStatusOfStep = () => {
    dispatch(
      vendorManagementActions.updateApprovalStatusOfStepRequest({
        companyId: companyId,
        stepId: activeStep.step,
        status: "approved",
      })
    );
  };

  return (
    <>
      {!isApproved &&
      (messageThreadOfSpec?.is_resolved === null || messageThreadOfSpec?.is_resolved === true) &&
      isFormDisplayForValidationManagement ? (
        <>
          <StyledBox>
            <StyledButton
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={handleClickApproveBtn}
            >
              <StyledTypography variant="body2">Approve</StyledTypography>
            </StyledButton>

            <StyledButton variant="outlined" color="secondary" size="medium" onClick={openDrawer}>
              <StyledTypography variant="body2">Review</StyledTypography>
            </StyledButton>
          </StyledBox>
        </>
      ) : null}

      {isApproved && messageThreadOfSpec?.is_resolved !== false ? (
        <StyledBtnForResolveAndApproved
          sx={{ cursor: "default" }}
          startIcon={<StyledApprovedIcon />}
        >
          <StyledTypography variant="body2">Approved</StyledTypography>

          <StyledBtnBox onClick={openDrawer}>
            <StyledCloseIcon fontSize="small" />
          </StyledBtnBox>
        </StyledBtnForResolveAndApproved>
      ) : null}

      {messageThreadOfSpec?.is_resolved === false ? (
        <StyledBtnForResolveAndApproved
          startIcon={<StyledInReviewIcon />}
          onClick={openDrawer}
          sx={{ cursor: "pointer" }}
        >
          <StyledTypography variant="body2">To resolve</StyledTypography>
        </StyledBtnForResolveAndApproved>
      ) : null}
    </>
  );
};
