import React from "react";
import { SupplierViewDonutChartSlugs } from "@next/modules/vendor-management/redux";
import * as S from "./supplier-view-donut-chart.styled";

type Props = {
  colors: string[];
  series: number[];
  labels: string[];
  slug: keyof typeof SupplierViewDonutChartSlugs;
};
export const SupplierViewDonutChart: React.FC<Props> = ({ colors, series, labels, slug }) => {
  const chartData = {
    series: series,
    options: {
      chart: {
        type: "donut" as const,
      },
      colors: colors,
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      labels: labels,
      stroke: {
        width: 1,
      },
    },
  };
  const innerLabel = SupplierViewDonutChartSlugs[slug];

  return (
    <S.StyledBox>
      <S.StyledChart options={chartData.options} series={chartData.series} type="donut" />

      <S.StyledTypography variant="body1">{innerLabel}</S.StyledTypography>
    </S.StyledBox>
  );
};
