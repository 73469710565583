import { AppBar, Avatar, Box, styled } from "@mui/material";

export const StyledAppBar = styled(AppBar)({
  backgroundColor: "#00205B",
  width: "100%",
  height: "72px",
  display: "flex",
  flexDirection: "row",
  gap: "25px",
  alignItems: "center",
  paddingLeft: "16px",
  paddingRight: "16px",
  position: "fixed",
  overflow: "hidden",
  boxSizing: "border-box",
  "& > *": {
    minWidth: 0,
  },
});

export const ProfileWrapper = styled(Box)({
  marginLeft: "auto",
  display: "flex",
  alignItems: "center",
});

export const StyledImg = styled("img")({
  width: "78px",
  height: "24px",
});

export const StyledAvatar = styled(Avatar)({
  width: "100px",
  height: "40px",
});

export const StyledNavTabBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
});
