import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { parse } from "cookie";
import { useDispatch } from "react-redux";
import { profileActions } from "services/profile";
import { history } from "helpers/history";
import { frontendUrl } from "urls";

export const KeycloakAuthCallback = () => {
  const dispatch = useDispatch();
  const [isAuthenticatingError, setIsAuthenticatingError] = useState(false);

  useEffect(() => {
    try {
      const cookies = parse(document.cookie);
      if (!cookies?.jwt_tokens) {
        history.replace(frontendUrl.login);
        return;
      }
      const [refreshToken, accessToken] = cookies["jwt_tokens"].split(":");

      if (refreshToken && accessToken) {
        dispatch(
          profileActions.setLoginSuccess({
            token: accessToken,
            refresh: refreshToken,
          })
        );
      }
    } catch (error) {
      console.error("AUTHENTICATION ERROR", error);
      setIsAuthenticatingError(true);
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100vw" height="100vh">
      <Box display="flex" alignItems="center" flexDirection="column">
        {!isAuthenticatingError ? <CircularProgress /> : null}

        <Box mt="16px">
          <Typography>
            {isAuthenticatingError ? "Authentication Error" : "Authenticating"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
