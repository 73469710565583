import { Box, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: "100%",
  height: "372px",
  display: "flex",
  flexDirection: "column",
});

export const LegendWrapper = styled(Box)({
  alignSelf: "flex-start",
  marginBottom: "60px",
});
