import React from "react";
import { Box, Container, Link, Theme, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@mui/styles";
import axyalogo from "assets/img/axya-logo.svg";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectSendEmailConfirmationLoading } from "@next/modules/workspace/redux/selectors";
import { useQuery } from "@next/hooks/useQuery";
import CustomButton from "@next/components/custom-button";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: theme.palette.common.white,
      borderRadius: "24px",
      padding: theme.spacing(6),
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(6),
      textAlign: "center",
    },
    checkContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
      borderRadius: "16px",
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.background.paper,
    },
    linksContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(2),
    },
  })
);

export const EmailConfirmed = () => {
  const params = useParams<{ token?: string }>();
  const [query] = useQuery();
  const { email } = query;
  const publicToken = params?.token;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const emailConfirmationloading = useSelector(selectSendEmailConfirmationLoading);
  const [isEmailConfirmed, setIsEmailConfirmed] = React.useState(false);

  return (
    <div className={"auth"}>
      <Container maxWidth="md" className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.checkContainer}>
            <Check />
          </Box>
          {isEmailConfirmed ? (
            <>
              <Typography variant="h5">{t("userOnboardingEmailConfirmed:title")}</Typography>
              <Box>
                <Trans i18nKey="userOnboardingEmailConfirmed:description" />
              </Box>
              <Box className={classes.linksContainer}>
                <Link
                  href={`mailto:engineering@axya.co`}
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="none"
                >
                  engineering@axya.co
                </Link>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h5">
                {t("userOnboardingEmailConfirmed:preConfirmTitle")}
              </Typography>
              <Box>
                <Trans
                  i18nKey="userOnboardingEmailConfirmed:preConfirmDescription"
                  values={{
                    email,
                  }}
                />
              </Box>
              <CustomButton
                variant="contained"
                color="primary"
                loading={emailConfirmationloading}
                fullWidth
                onClick={() => {
                  dispatch(
                    workspaceNextActions.sendEmailConfirmationRequest({
                      token: publicToken,
                      onSuccess: () => setIsEmailConfirmed(true),
                    })
                  );
                }}
              >
                {t("userOnboardingEmailConfirmed:confirmEmailBtn")}
              </CustomButton>
            </>
          )}
          <img src={axyalogo} alt="axya logo" />
        </Box>
      </Container>
      {/* )} */}
    </div>
  );
};
