import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { CustomLegendList } from "../../../charts/custom-legend-list/custom-legend-list";
import { SupplierViewDonutChart } from "../../../charts/supplier-view-donut-chart/supplier-view-donut-chart";
import {
  SupplierViewDonutChartItemColors,
  SupplierViewDonutChartItemSlugs,
  SupplierViewDonutChartsCard,
  SupplierViewDonutChartsCardSlugs,
} from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-supplier-view-charts-card.styled";

type Props = {} & SupplierViewDonutChartsCard;

export const ReadinessDashboardSupplierViewChartsCard: React.FC<Props> = ({ slug, charts }) => {
  const labels = useMemo(() => {
    return Object.keys(SupplierViewDonutChartItemSlugs)
      .filter((key) => charts.some((chart) => key in chart))
      .sort(
        (a, b) =>
          Object.keys(SupplierViewDonutChartItemSlugs).indexOf(a) -
          Object.keys(SupplierViewDonutChartItemSlugs).indexOf(b)
      )
      .map(
        (key) =>
          SupplierViewDonutChartItemSlugs[key as keyof typeof SupplierViewDonutChartItemSlugs]
      );
  }, [charts]);

  const colors = useMemo(() => {
    return Object.keys(SupplierViewDonutChartItemColors)
      .filter((key) => charts.some((chart) => key in chart))
      .sort(
        (a, b) =>
          Object.keys(SupplierViewDonutChartItemSlugs).indexOf(a) -
          Object.keys(SupplierViewDonutChartItemSlugs).indexOf(b)
      )
      .map(
        (key) =>
          SupplierViewDonutChartItemColors[key as keyof typeof SupplierViewDonutChartItemColors]
      );
  }, [charts]);

  return (
    <S.StyledCardBox>
      <S.StyledTypography variant="h6">{SupplierViewDonutChartsCardSlugs[slug]}</S.StyledTypography>

      <S.StyledGridContainer container spacing={1}>
        {charts.map((chart, index) => {
          const series = Object.keys(SupplierViewDonutChartItemSlugs)
            .filter((key) => key in chart)
            .sort(
              (a, b) =>
                Object.keys(SupplierViewDonutChartItemSlugs).indexOf(a) -
                Object.keys(SupplierViewDonutChartItemSlugs).indexOf(b)
            )
            .map((key) => chart[key as keyof typeof chart] as number);

          return (
            <Grid item xs={6} md={6} lg={3} key={chart.slug}>
              <SupplierViewDonutChart
                key={index}
                colors={colors}
                series={series}
                labels={labels}
                slug={chart.slug}
              />
            </Grid>
          );
        })}
      </S.StyledGridContainer>

      <CustomLegendList labels={labels} colors={colors} />
    </S.StyledCardBox>
  );
};
