import React, { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ExportMenu from "../export-menu";

import { useParams } from "react-router-dom";
import { GridToolbarColumnsButton, GridToolbarFilterButton } from "@mui/x-data-grid-pro-v5";
import { ProjectStats } from "../project-stats/project-stats";
import { SearchTextField } from "@next/components/search-text-field";
import { useDispatch, useSelector } from "react-redux";
import { workspaceNextActions } from "@next/modules/workspace/redux";
import { selectProjectStats } from "../../redux/selectors";
import CustomButton from "@next/components/custom-button";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ProjectPartSelectedOptions } from "./project-part-selected-options";
import { ProjectPartBulkImport } from "./project-part-bulk-import";

type Props = {
  onClickAddPart: () => void;
  onImport: (fileResults?: any) => void;
  showProjectPartSelectedOptions: boolean;
  selectedPartCount: number;
  searchTextFieldProps?: {
    clearSearch: () => void;
    setSearchValue: (value: string) => void;
    searchValue: string;
    disabled: boolean;
  };
};

const GridToolbarColumnsButtonCustom = (
  props: Partial<ComponentProps<typeof GridToolbarColumnsButton>>
) => {
  return (
    <GridToolbarColumnsButton
      placeholder=""
      {...(props as ComponentProps<typeof GridToolbarColumnsButton>)}
    />
  );
};

const GridToolbarFilterButtonCustom = (
  props: Partial<ComponentProps<typeof GridToolbarFilterButton>>
) => {
  return (
    <GridToolbarFilterButton
      placeholder=""
      {...(props as ComponentProps<typeof GridToolbarFilterButton>)}
    />
  );
};

export const ProjectPartTableToolbar: React.FC<Props> = ({
  onClickAddPart,
  onImport,
  searchTextFieldProps,
  showProjectPartSelectedOptions,
  selectedPartCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const projectStats = useSelector(selectProjectStats);
  const { code: projectCode = "" } = useParams<{ code: string }>();

  const onExport = () => {
    dispatch(workspaceNextActions.exportMyPartsRequest({ code: projectStats?.code }));
  };

  return (
    <Box>
      {showProjectPartSelectedOptions ? (
        <ProjectPartSelectedOptions selectedPartCount={selectedPartCount} />
      ) : (
        <Box width={"100%"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              p: "16px",
              gap: "8px",
            }}
          >
            <ProjectStats />
            <SearchTextField {...searchTextFieldProps} />
            <Button variant="text" color="inherit" onClick={onClickAddPart} startIcon={<AddIcon />}>
              {t("project:navbar:button:addPart")}
            </Button>
            <GridToolbarColumnsButtonCustom color="inherit" />
            <GridToolbarFilterButtonCustom
              onResize={undefined}
              onResizeCapture={undefined}
              color="inherit"
            />

            <ExportMenu isInDetail={true} projectCodes={[projectCode]} />

            <CustomButton startIcon={<ArrowDownwardIcon />} size="large" onClick={onExport}>
              {t("workspaceNext:rfqDrawer:partsAndFilesModal:downloadEmptyTemplate")}
            </CustomButton>
          </Box>
          <ProjectPartBulkImport onImport={onImport} />
        </Box>
      )}
    </Box>
  );
};
