import React from "react";
import { Typography } from "@mui/material";
import * as S from "./analytic-filters.styled";

import { AnalyticFiltersTimeframeDropdown } from "./analytic-filters-timeframe-dropdown";
import AnaltyticFiltersSuppliersAutoCompleteDropdown from "./analtyic-filters-suppliers-autocomplete-dropdown";
import AnaltyticFiltersBuyersDropdown from "./analytic-filters-buyers-dropdown";
import { AnalyticFiltersCurrencyDropdown } from "./analytic-filters-currency-dropdown";
import CustomButton from "@next/components/custom-button";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { analyticsActions } from "../../redux";
import { selectAnalyticFilters } from "../../redux/selectors";

type Props = {
  isInSupplierReport: boolean;
  isInOrderReport?: boolean;
};

export const AnalyticFilters: React.FC<Props> = ({ isInSupplierReport, isInOrderReport }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const analyticFilters = useSelector(selectAnalyticFilters);

  const handleExportAsExcel = () => {
    dispatch(analyticsActions.exportPOAnalyticsAsExcelRequest(analyticFilters));
  };
  return (
    <S.RootDiv>
      <S.InnerDiv>
        <AnalyticFiltersTimeframeDropdown fullWidth={isInOrderReport} />

        {!isInSupplierReport ? (
          <AnaltyticFiltersSuppliersAutoCompleteDropdown fullWidth={isInOrderReport} />
        ) : null}

        {isInOrderReport ? (
          <>
            <AnaltyticFiltersBuyersDropdown />

            <AnalyticFiltersCurrencyDropdown />

            <CustomButton
              variant="contained"
              size="large"
              sx={{ minWidth: "min-content", py: "10px" }}
              onClick={handleExportAsExcel}
            >
              <Typography variant="body2">{t("analytics:global:exportExcel")}</Typography>
            </CustomButton>
          </>
        ) : null}
      </S.InnerDiv>
    </S.RootDiv>
  );
};
