import React from "react";
import { ReadinessDashboardSpecViewSpecLevelTableWithSearch } from "./readiness-dashboard-spec-view-spec-level-table-with-search/readiness-dashboard-spec-view-spec-level-table-with-search";
import { ReadinessDashboardSpecViewTrackingChartCard } from "./readiness-dashboard-spec-view-tracking-chart-card/readiness-dashboard-spec-view-tracking-chart-card";
import { ReadinessDashboardSpecViewSpecCardsGroup } from "./readiness-dashboard-spec-view-spec-cards-group/readiness-dashboard-spec-view-spec-cards-group";
import * as S from "./readiness-dashboard-spec-view.styled";

export const ReadinessDashboardSpecView: React.FC = () => {
  return (
    <S.StyledRootBox>
      <ReadinessDashboardSpecViewSpecLevelTableWithSearch />

      <ReadinessDashboardSpecViewTrackingChartCard />

      <ReadinessDashboardSpecViewSpecCardsGroup />
    </S.StyledRootBox>
  );
};
