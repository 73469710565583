import React from "react";
import {
  SemiDonutChartSlugs,
  SemiDonutChart as SemiDonutChartType,
} from "@next/modules/vendor-management/redux";
import { SemiDonutChart } from "../../../charts/semi-donut-chart/semi-donut-chart";
import * as S from "./readiness-dashboard-overview-semi-donut-chart-card.styled";

type Props = {} & SemiDonutChartType;

export const ReadinessDashboardOverviewSemiDonutChartCard: React.FC<Props> = ({ slug, items }) => {
  return (
    <S.StyledChartBox>
      <S.StyledTypography variant="h6">{SemiDonutChartSlugs[slug]}</S.StyledTypography>

      <SemiDonutChart slug={slug} items={items} />
    </S.StyledChartBox>
  );
};
