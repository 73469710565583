import { Box, styled } from "@mui/material";
import { CustomTypography } from "@next/components/custom-typography";

export const RootDiv = styled("div")`
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: white;
`;

export const InnerDiv = styled("div")`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-direction: row;
  @media (max-width: 1028px) {
    flex-direction: column;
    height: auto;
  }
`;

export const OptionName = styled(CustomTypography)`
  margin-left: 8px;
  width: 100px;
`;

export const StyledBox = styled(Box)`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
`;
