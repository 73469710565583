import axios from "axios";
import { axiosApi } from "@next/api";
import { t } from "assets/configi18n/i18n";

import { backendUrl } from "urls";
import {
  AddAddendaInput,
  AddFavoriteSupplierInput,
  AnswerTopicInput,
  ApprovedSupplierInput,
  AxyaRFQStats,
  ContactUsInput,
  CreateEasyRfqInput,
  CreatePortalQuoteInput,
  CreateTopicInput,
  CustomQuotationExportInput,
  DeletePortalQuoteFileInput,
  DraftRFQ,
  DraftRFQData,
  ExportQuotesComparisonSheetInput,
  FavoriteSupplier,
  FetchContractsInput,
  FetchDraftRFQsInput,
  FetchFavoriteSupplierInput,
  FetchInternalChatTopicInput,
  FetchMyPartsInput,
  FetchPartsInput,
  FetchPortalDataInput,
  FetchPresignedS3Input,
  FetchQAThreadDetailsInput,
  FetchQuoteComparisonInput,
  FetchRFQFavoriteSuppliersInput,
  FetchRFQQuestionsStatsInput,
  FetchSupplierContactsList,
  FetchTopicInput,
  FetchTopicsInput,
  FinishMultipartS3UploadInput,
  GlobalSearchInput,
  InviteSupplierContactInput,
  InviteSupplierInput,
  NDA,
  PortalData,
  PostInternalChatMessageInput,
  QaMarkAsReadInput,
  QnATopicBody,
  SendAirbusConfirmationInput,
  SendEmailConfirmationInput,
  SendRevisionNoteInput,
  SyncERPQuotationInput,
  TopicMessageType,
  TopicType,
  UpdateDraftRFQCollaboratorsInput,
  UpdateNDAInput,
  UpdatePortalQuoteInput,
  UpdateQuoteComparisonConfigInput,
  UpdateQuoteNoQuoteStatusInput,
  UpdateRFQCollaboratorsInput,
  UpdateRFQTargetedProfilesInput,
  UploadPortalQuoteFileInput,
  UploadQuotationAsBuyerInput,
  UploadS3FileInput,
} from "../redux";
import querystring from "query-string";
import { convertFilesToObjects } from "@next/utils/fileUtils";
import { convertObjectToFormData } from "@next/utils/apiUtils";
import { ExportMyPartsInput, ImportMyPartsInput } from "@next/modules/project/redux";
import { AxiosResponse } from "axios";
import { END } from "redux-saga";
import { createErrorMessage } from "helpers/createErrorMessage";
import { DistributionForSupplierContacts } from "@next/modals/shared-supplier-contact-lists-modal";

const fetchQATopics = ({ rfqId }: FetchTopicsInput) => {
  return axiosApi.get<TopicType[]>(`${backendUrl.rfqs}/${rfqId}/${backendUrl.questions}`);
};

const fetchQATopic = ({ token }: FetchTopicInput) => {
  return axiosApi.get<TopicType[]>(`${backendUrl.questionsBase}${token}/`);
};

const fetchInternalChatTopic = ({ rfqId }: FetchInternalChatTopicInput) => {
  return axiosApi.get<TopicType[]>(`${backendUrl.questionsBase}internal/${rfqId}`);
};

const createQATopic = ({ rfqId, ...rest }: CreateTopicInput) => {
  return axiosApi.post<Pick<CreateTopicInput, "body" | "subject" | "is_private">, TopicType>(
    `${backendUrl.rfqs}/${rfqId}/${backendUrl.questions}`,
    rest
  );
};

const answerQATopic = async ({
  topicToken,
  answer,
  firstName,
  lastName,
  attachments,
}: AnswerTopicInput) => {
  const body: QnATopicBody = { answer };
  if (firstName && lastName) body.profile_update = { first_name: firstName, last_name: lastName };

  const res = await axiosApi.post<QnATopicBody, AxiosResponse<TopicMessageType>>(
    `${backendUrl.questionsBase}${topicToken}/`,
    body
  );

  if (attachments && attachments.length) {
    try {
      await Promise.all(
        attachments.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return axiosApi.post(
            `${backendUrl.rfqQuestions}${topicToken}/messages/${res.data.pk}/files`,
            formData
          );
        })
      );
      res.data.files = convertFilesToObjects(attachments);
    } catch (error) {
      res.data.filesError = error as Error;
    }
  }

  return res;
};

const postInternalChatMessage = async ({
  body,
  rfqId,
  attachments,
}: PostInternalChatMessageInput) => {
  const res = await axiosApi.post<{ body: string }, AxiosResponse<any>>(
    `${backendUrl.questionsBase}internal/${rfqId}/`,
    {
      body,
    }
  );

  if (attachments && attachments.length) {
    try {
      await Promise.all(
        attachments.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          return axiosApi.post(
            `${backendUrl.questionsBase}internal/messages/${res.data.messages.at(-1).pk}/files`,
            formData
          );
        })
      );
      res.data.messages.at(-1).files = convertFilesToObjects(attachments);
    } catch (error) {
      res.data.messages.at(-1).filesError = error as Error;
    }
  }

  return res;
};

const qaMarkAsRead = ({ qaId }: QaMarkAsReadInput) => {
  return axiosApi.post<TopicType[]>(`${backendUrl.qa}${qaId}/activities/`);
};

const contactUs = ({ msg, subject, company_id }: ContactUsInput) => {
  let query_params = "";
  if (company_id) {
    query_params = `?company_id=${company_id}`;
  }
  return axiosApi.post(`${backendUrl.contactUs}${query_params}`, {
    msg,
    subject: subject ? subject : t("contactUs:feedback"),
  });
};

const customQuotationExport = ({ quotationId }: CustomQuotationExportInput) =>
  axiosApi.get(`${backendUrl.customQuotations}/${quotationId}/excelExport`, {
    responseType: "blob",
  });

const inviteSupplier = ({ suppliers }: InviteSupplierInput) => {
  return axiosApi.post(`${backendUrl.inviteSupplier}`, suppliers);
};

const fetchNDAList = () => {
  return axiosApi.get<NDA[]>(`${backendUrl.NDA}`);
};

const updateNDA = ({ pk }: UpdateNDAInput) => {
  return axiosApi.patch<boolean>(`${backendUrl.rfq}${pk}/signed-nda/`);
};

const fetchPartsList = (input?: FetchPartsInput) => {
  return axiosApi.get(
    `${input?.url || backendUrl.parts}${
      input?.query ? `?${querystring.stringify(input?.query).replace(/&[^=&]+=(?=&|$)/g, "")}` : ""
    }`
  );
};

const fetchFavoriteSuppliersList = (input?: FetchRFQFavoriteSuppliersInput) => {
  return axiosApi.get(
    `${input?.url || backendUrl.favoriteSuppliers}${
      input?.query ? `?${querystring.stringify(input?.query)}` : ""
    }`
  );
};

const fetchFavoriteSuppliers = ({ query = "" }: FetchFavoriteSupplierInput) => {
  return axiosApi.get(`${backendUrl.favoriteSuppliers}?${query}`);
};

const fetchSingleFavoriteSupplier = ({ pk }: { pk: number }) => {
  return axiosApi.get(`${backendUrl.favoriteSuppliers}${pk}`);
};

const addFavoriteSupplier = ({ pk }: AddFavoriteSupplierInput) => {
  return axiosApi.post(`${backendUrl.favoriteSuppliers}`, { pk });
};

const updateFavoriteSupplier = ({ pk, ...partialFavoriteSupplier }: Partial<FavoriteSupplier>) => {
  return axiosApi.patch(`${backendUrl.favoriteSuppliers}${pk}`, {
    ...partialFavoriteSupplier,
  });
};

const removeFavoriteSupplier = (id: number) => {
  return axiosApi.delete(`${backendUrl.favoriteSuppliers}${id}`);
};

const fetchFavoriteSupplierGroups = () => {
  return axiosApi.get(backendUrl.favoriteSupplierGroups);
};

const createFavoriteSupplierGroup = (name: string) => {
  return axiosApi.post(backendUrl.favoriteSupplierGroups, { name });
};

const fetchDraftRFQs = (input?: FetchDraftRFQsInput): Promise<AxiosResponse<DraftRFQ[]>> => {
  const { page = 1, pageSize = 15, project } = input?.query || {};

  let url = `${backendUrl.rfqDrafts}/?page=${page}&pageSize=${pageSize}`;
  if (project) url += `&project=${project}`;

  return axiosApi.get(url);
};

const getDraftRFQ = (draftId: number) => {
  return axiosApi.get(`${backendUrl.rfqDrafts}/${draftId}`);
};

const createDraftRFQ = (data: DraftRFQData): Promise<AxiosResponse<DraftRFQ>> => {
  return axiosApi.post(`${backendUrl.rfqDrafts}/`, { data });
};

const updatedDraftRFQ = (
  draftId: number,
  data: Partial<DraftRFQData>
): Promise<AxiosResponse<DraftRFQ>> => {
  return axiosApi.patch(`${backendUrl.rfqDrafts}/${draftId}`, { data });
};

const removeDraftRFQ = (draftId: number) => {
  return axiosApi.delete(`${backendUrl.rfqDrafts}/${draftId}`);
};

const updateDraftRFQCollaborators = (data: UpdateDraftRFQCollaboratorsInput) => {
  return axiosApi.post(`${backendUrl.draft}/${data.draftId}/collaborators`, data.collaborators);
};

const createDiscordNotify = (
  // This endpoint accepts any kind of data with keys and values.
  params: { title: string; channel_id: string } & any
) => {
  delete params.is_agreed;

  const formData = convertObjectToFormData(params);

  return axiosApi.post(`${backendUrl.discordNotify}`, formData);
};

const fetchContracts = ({ query = "" }: FetchContractsInput) => {
  return axiosApi.get(`${backendUrl.contracts}?${query}`);
};

const fetchTenders = () => {
  return axiosApi.get(backendUrl.tenders);
};

const fetchMarketplaceCounts = async () => {
  const [tenders, nonTenders] = await Promise.all([
    axiosApi.get(`${backendUrl.tenders}count/`),
    axiosApi.get(`${backendUrl.marketplace}?page=1&pageSize=1`),
  ]);
  return {
    tenders_total: tenders.data.count,
    tenders_unseen: 0,
    public_total: nonTenders.data.count_public_total,
    public_unseen: nonTenders.data.count_public_unseen,
    private_total: nonTenders.data.count_private_total,
    private_unseen: nonTenders.data.count_private_unseen,
  };
};

const fetchMyParts = ({ query = "" }: FetchMyPartsInput) => {
  return axiosApi.get(`${backendUrl.parts}?${query}`);
};

const exportMyParts = ({ code = "", headersOnly }: ExportMyPartsInput) => {
  return axiosApi.get(`${backendUrl.bomExport}${code}`, {
    responseType: "blob",
    params: {
      headers_only: headersOnly,
    },
  });
};

const importMyParts = ({ code = "", file }: ImportMyPartsInput) => {
  const formData = new FormData();

  formData.append("file", file);

  return axiosApi.post(`${backendUrl.bomImport}${code}`, formData, {});
};

const fetchSupplierContactsList = (payload: FetchSupplierContactsList) => {
  const { supplierId, distributionForSupplierContacts } = payload;
  return axiosApi.get(
    `${backendUrl.company}/${supplierId}/profiles/${
      distributionForSupplierContacts === DistributionForSupplierContacts.RFQ
        ? "?distribution_type=rfq_distribution"
        : distributionForSupplierContacts === DistributionForSupplierContacts.ORDER
          ? "?distribution_type=po_distribution"
          : ""
    }`
  );
};

const inviteSupplierContact = (data: InviteSupplierContactInput) => {
  return axiosApi.post(`${backendUrl.company}/${data.supplierId}/profiles/`, data);
};

const fetchPresignedS3 = (data: FetchPresignedS3Input) => {
  return axiosApi
    .post(`${backendUrl.presignedS3}/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Error fetching presigned S3 URL:", error);
      throw error;
    });
};

const finishMultipartS3Upload = (data: FinishMultipartS3UploadInput) => {
  return axiosApi.post(`${backendUrl.finishMultipart}/`, data);
};

const uploadS3File = (data: UploadS3FileInput, event_emitter: any) => {
  const cancelTokenSource = axios.CancelToken.source();
  return axios
    .put(data.url, data.file, {
      headers: {
        "Content-Type": data.file.type,
      },
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentCompleted = Math.round((loaded * 100) / total);
        event_emitter({
          fileName: data.file.name,
          fileType: data.file.type,
          uploaded: loaded,
          total,
          percent: percentCompleted,
          source: cancelTokenSource,
        });

        if (percentCompleted === 100) {
          event_emitter(END);
        }
      },
    })
    .catch((error) => {
      event_emitter(END);
      if (!axios.isCancel(error)) {
        throw error;
      }
      return { canceled: true, error: false };
    });
};

const fetchPortalData = ({ rfqToken }: FetchPortalDataInput) => {
  return axiosApi.get<PortalData>(`${backendUrl.supplierPortal}${rfqToken}/`);
};

const fetchAxyaRFQStatsData = () => {
  return axiosApi.get<AxyaRFQStats>(`${backendUrl.axyaStatsSupplier}`);
};

const updateRFQCollaborators = (data: UpdateRFQCollaboratorsInput) => {
  return axiosApi.post(
    `${backendUrl.requestForQuotation}${data.rfqId}/collaborators${
      data.poId ? `?po_id=${data.poId}` : ""
    }`,
    data.collaborators
  );
};

const updatePortalQuoteNoQuote = ({ rfqToken, ...payload }: UpdateQuoteNoQuoteStatusInput) => {
  return axiosApi.post(`${backendUrl.marketplace}quoteNoQuote/${rfqToken}/`, payload);
};

const uploadPortalQuoteFile = ({ rfqToken, file }: UploadPortalQuoteFileInput) => {
  const formData = new FormData();

  formData.append("file", file);

  return axiosApi.post(`${backendUrl.uploadQuotes}${rfqToken}/`, formData);
};

const deletePortalQuoteFile = ({ filePk }: DeletePortalQuoteFileInput) => {
  return axiosApi.delete(`${backendUrl.uploadQuotes}${filePk}/`);
};

const createPortalQuote = ({
  rfqToken,
  additional_details,
  files,
  delay,
  delay_unit,
  currency,
  total_cost,
}: CreatePortalQuoteInput) => {
  return axiosApi.post(`${backendUrl.supplierPortal}${rfqToken}/`, {
    additional_details,
    files,
    delay,
    delay_unit,
    currency,
    total_cost,
  });
};

const updatePortalQuote = ({
  rfqToken,
  additional_details,
  files,
  quotation_id,
  delay,
  delay_unit,
  currency,
  total_cost,
}: UpdatePortalQuoteInput) => {
  return axiosApi.post(`${backendUrl.supplierPortal}${rfqToken}/`, {
    additional_details,
    files,
    quotation_id,
    delay,
    delay_unit,
    currency,
    total_cost,
  });
};

const exportQuotesComparisonSheet = ({ rfqPk }: ExportQuotesComparisonSheetInput) => {
  return axiosApi.get(`${backendUrl.rfqs}/${rfqPk}/reports/export`, {
    responseType: "blob",
  });
};

const sendRevisionNote = ({ rfqId, ...payload }: SendRevisionNoteInput) => {
  return axiosApi.post(`${backendUrl.rfq}${rfqId}/revision/`, payload);
};

const globalSearch = ({ term }: GlobalSearchInput) => {
  return axiosApi.get(`${backendUrl.globalSearch}?q=${encodeURIComponent(term)}&pageSize=8&page=1`);
};

const fetchSupplierQuotes = ({ query = "" }: FetchFavoriteSupplierInput) => {
  return axiosApi.get(`${backendUrl.quotation}?${query}`);
};

function uploadAddendaAttachment(file: string) {
  return axiosApi.post(`${backendUrl.addenda}/file`, { file: file });
}

const addAddenda = async ({ ...payload }: AddAddendaInput) => {
  return axiosApi.post(`${backendUrl.addenda}`, {
    ...payload,
    files: payload.filesUrl || [],
  });
};

const fetchQuoteComparison = ({ rfq_id }: FetchQuoteComparisonInput) => {
  return axiosApi.get(`${backendUrl.rfqs}/${rfq_id}/quoteComparison/`);
};

const updateQuoteComparisonConfig = ({
  config_id,
  updated_data,
}: UpdateQuoteComparisonConfigInput) => {
  return axiosApi.put(`${backendUrl.quoteComparisonConfigs}/${config_id}`, updated_data);
};

const sendNewVerificationEmail = (email: string) => {
  return axiosApi.put(`${backendUrl.inviteMember}`, { email });
};

const checkEmailExists = (email: string) => {
  return axiosApi.post(`${backendUrl.emailExist}`, { email });
};

const createEasyRfq = ({ fromDashboard, ...restPayload }: CreateEasyRfqInput) => {
  return axiosApi.post(`${backendUrl.easyRFQ}/`, restPayload);
};

const updateRFQTargetProfiles = ({
  rfqId,
  supplierId,
  target_profiles,
}: UpdateRFQTargetedProfilesInput) => {
  return axiosApi.post(
    `${backendUrl.rfq}${rfqId}/suppliers/${supplierId}/targetProfiles/`,
    target_profiles
  );
};

const syncERPQuotation = ({ quotation_id }: SyncERPQuotationInput) => {
  return axiosApi.post(`${backendUrl.syncERPQuotation}`, { quotation_id });
};

const fetchRfqQuestionsStats = ({ rfqId }: FetchRFQQuestionsStatsInput) => {
  return axiosApi.get(`${backendUrl.rfqs}/${rfqId}/questions/stats/`);
};

const fetchQAThreadDetails = ({ qaId }: FetchQAThreadDetailsInput) => {
  return axiosApi.get(`${backendUrl.questionsBase}${qaId}/messages/`);
};

const sendEmailConfirmation = ({ token }: SendEmailConfirmationInput) => {
  return axiosApi.post(`${backendUrl.emailConfirmation}${token}`);
};

const uploadQuotationAsBuyer = ({ data, quotation_id }: UploadQuotationAsBuyerInput) => {
  if (quotation_id) {
    return axiosApi.put(`${backendUrl.quotationAsBuyer}${quotation_id}/`, data);
  }
  return axiosApi.post(`${backendUrl.quotationAsBuyer}`, data);
};

const uploadQuotationFileAsBuyer = ({ file }: { file: File }) => {
  const formData = new FormData();
  formData.append("file", file);
  return axiosApi.post(`${backendUrl.uploadQuotes}`, formData);
};

const mutateInternalNotes = (data) => {
  let payloadData = data;

  if (data.hasOwnProperty("updateNoteDescription")) {
    const { updateNoteDescription, ...rest } = payloadData;
    payloadData = rest;
  }
  return axiosApi.post(backendUrl.notes, payloadData);
};
const getInternalNotes = ({ payload }) => {
  return axiosApi.get(`${backendUrl.notes}?module=${payload}`);
};
const assignInternalNote = ({ allNotes, moduleType, pk }) => {
  const { notes } = allNotes;
  let url =
    moduleType === "PO" ? `${backendUrl.orders}/${pk}/notes/` : `${backendUrl.rfq}${pk}/notes/`;
  return axiosApi.post(url, {
    notes: notes,
    module: moduleType,
  });
};
export const fetchSuppliers = (email: string) => {
  return axiosApi.get(`${backendUrl.searchSupplier}?email=${email}`);
};

export const addASL = (payload: ApprovedSupplierInput) => {
  return axiosApi.post(backendUrl.addASL, payload);
};

const sendAirbusConfirmation = async ({ token }: SendAirbusConfirmationInput) => {
  try {
    return await axiosApi.post(`${backendUrl.airbusConfirmation}${token}`);
  } catch (error) {
    if (createErrorMessage(error).toLowerCase().includes("acknowledgement already completed")) {
      return;
    } else {
      throw error;
    }
  }
};
const fetchOTT = async () => {
  return await axiosApi.post(backendUrl.ott);
};
const getPortalUrl = async (rfqId: number) => {
  return await axiosApi.post(backendUrl.generatePortalUrl, rfqId);
};
export const workspaceNextService = {
  addASL,
  fetchSuppliers,
  fetchAxyaRFQStatsData,
  fetchTenders,
  fetchMarketplaceCounts,
  fetchQATopics,
  fetchQATopic,
  fetchInternalChatTopic,
  createQATopic,
  answerQATopic,
  postInternalChatMessage,
  qaMarkAsRead,
  contactUs,
  customQuotationExport,
  inviteSupplier,
  fetchNDAList,
  updateNDA,
  fetchPartsList,
  fetchFavoriteSuppliersList,
  addFavoriteSupplier,
  fetchFavoriteSuppliers,
  fetchSingleFavoriteSupplier,
  updateFavoriteSupplier,
  removeFavoriteSupplier,
  fetchFavoriteSupplierGroups,
  createFavoriteSupplierGroup,
  fetchDraftRFQs,
  createDraftRFQ,
  updatedDraftRFQ,
  removeDraftRFQ,
  updateDraftRFQCollaborators,
  getDraftRFQ,
  createDiscordNotify,
  fetchContracts,
  fetchMyParts,
  exportMyParts,
  importMyParts,
  fetchSupplierContactsList,
  inviteSupplierContact,
  fetchPortalData,
  updateRFQCollaborators,
  updatePortalQuoteNoQuote,
  uploadPortalQuoteFile,
  deletePortalQuoteFile,
  createPortalQuote,
  updatePortalQuote,
  exportQuotesComparisonSheet,
  sendRevisionNote,
  globalSearch,
  fetchSupplierQuotes,
  fetchPresignedS3,
  uploadS3File,
  addAddenda,
  uploadAddendaAttachment,
  fetchQuoteComparison,
  updateQuoteComparisonConfig,
  sendNewVerificationEmail,
  checkEmailExists,
  createEasyRfq,
  updateRFQTargetProfiles,
  finishMultipartS3Upload,
  syncERPQuotation,
  fetchRfqQuestionsStats,
  fetchQAThreadDetails,
  sendEmailConfirmation,
  uploadQuotationAsBuyer,
  uploadQuotationFileAsBuyer,
  mutateInternalNotes,
  getInternalNotes,
  assignInternalNote,
  sendAirbusConfirmation,
  fetchOTT,
  getPortalUrl,
};
