import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { SearchTextField } from "../../../search-text-field/search-text-field";
import { ReadinessDashboardSpecViewSpecsTable } from "../../../table/readiness-dashboard-spec-view-specs-table/readiness-dashboard-spec-view-specs-table";
import { selectSupplierFilter } from "@next/modules/vendor-management/redux/selectors";
import {
  PaginationSizeForSpecs,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-spec-view-spec-level-table-with-search.styled";

export const ReadinessDashboardSpecViewSpecLevelTableWithSearch: React.FC = () => {
  const dispatch = useDispatch();
  const supplierFilter = useSelector(selectSupplierFilter);
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);

  const clearSearch = () => {
    setPage(1);
    setSearchValue("");
  };

  const handleSearchValueChange = (value: string) => {
    if (searchValue !== value) {
      setPage(1);
    }
    setSearchValue(value);
  };

  useEffect(() => {
    const supplierType =
      supplierFilter === "All Suppliers" ? "" : supplierFilter.toLocaleLowerCase();

    dispatch(
      vendorManagementActions.fetchSpecsTableDataRequest({
        page: page,
        pageSize: PaginationSizeForSpecs,
        searchTerm: searchValue,
        supplierType: supplierType,
      })
    );
  }, [searchValue, page]);

  return (
    <S.StyledRootBox>
      <S.StyledTypography variant="h6">Tracking at specification level</S.StyledTypography>

      <Box>
        <SearchTextField
          searchValue={searchValue}
          setSearchValue={handleSearchValueChange}
          clearSearch={clearSearch}
          placeholder="Search a spec name, dash ref or status..."
          width="300px"
        />
      </Box>

      <ReadinessDashboardSpecViewSpecsTable
        page={page}
        setPage={setPage}
        pageSize={PaginationSizeForSpecs}
      />
    </S.StyledRootBox>
  );
};
