import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Select,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { SharedModalTypes } from "@next/modals/types";
import { modalsActions } from "@next/redux/modalsSlices";
import {
  selectAssignInternalNoteLoading,
  selectInternalNotesList,
} from "@next/modules/workspace/redux/selectors";
import { NoteType, SelectInternalNote, workspaceNextActions } from "@next/modules/workspace/redux";
import { t } from "assets/configi18n/i18n";
import { CustomTypography } from "../custom-typography";
import { ordersActions } from "@next/modules/orders/redux";
import {
  selectOrdersLastQuery,
  selectUpdateOrderLoading,
} from "@next/modules/orders/redux/selectors";
import {
  selectProjectCompanyOrdersLastQuery,
  selectUpdateRFQLoading,
} from "@next/modules/project/redux/selectors";
import { projectActions } from "@next/modules/project/redux";
import CustomButton from "../custom-button/custom-button";
import { useHasCompanyRole } from "@next/hooks/useHasCompanyRole";

type StyleProps = {
  isDetailPage?: boolean;
};
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    dropdownTagCell: {
      display: "flex",
      flexDirection: "column",
      minWidth: "414px",
    },
    tagsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.customBackground.light,
      },
    },
    tag: {
      display: "flex",
      gap: "12px",
      alignItems: "center",
      padding: "12px 16px",
    },
    customDividerStyle: {
      margin: "0 -15px",
      backgroundColor: theme.palette.chip.dark,
      filter: "blur(0.5px)",
    },
    textFieldItems: {
      display: "flex",
      alignItems: "baseline",
      gap: "12px",
    },
    manageTags: {
      padding: "15px",
      margin: "10px 0",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: theme.palette.customBackground.light,
      },
    },
    optionColor: {
      width: "16px",
      height: "16px",
      borderRadius: "100%",
    },
    title: {
      maxWidth: ({ isDetailPage }) => (isDetailPage ? "380px" : "285px"),
    },
    defaultTagTitle: {
      color: theme.palette.text.secondary,
      maxWidth: ({ isDetailPage }) => (isDetailPage ? "400px" : "180px"),
      display: ({ isDetailPage }) => (isDetailPage ? "block" : "inline-block"),
      marginTop: ({ isDetailPage }) => (isDetailPage ? "3px" : "5px"),
      whiteSpace: ({ isDetailPage }) => (isDetailPage ? "normal" : "nowrap"),
      overflow: ({ isDetailPage }) => (isDetailPage ? "normal" : "hidden"),
      textOverflow: "ellipsis",
    },
    tagTitle: {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: ({ isDetailPage }) => (isDetailPage ? "400px" : "180px"),
    },
    detailPageNoteDescription: {
      color: theme.palette.text.secondary,
      maxWidth: "400px",
      whiteSpace: "normal",
      fontSize: "12px",
      textAlign: "justify",
    },
    noteDescription: {
      color: theme.palette.text.secondary,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      maxWidth: ({ isDetailPage }) => (isDetailPage ? "400px" : "150px"),
      fontSize: "12px",
    },
    bottomActionButton: {
      marginRight: "15px",
      textAlign: "right",
      margin: "8px 0",
    },
    flexItemsDown: {
      display: "flex",
      flexDirection: "column",
    },
    progress: {
      marginRight: "10px",
    },
    emptyTagsSection: {
      margin: "8px",
      color: "darkgrey",
      marginLeft: "1rem",
    },
  })
);

type Props = {
  notes: NoteType[];
  internal_note_description?: string | undefined;
  paramID: string | number;
  isDetailPage?: boolean;
  moduleType?: string;
};

export const InternalNoteDropDown: React.FC<Props> = ({
  notes,
  internal_note_description,
  paramID,
  isDetailPage = true,
  moduleType,
}) => {
  const ordersLastQuery = useSelector(selectOrdersLastQuery);
  const hasViewerRole = useHasCompanyRole(["viewer", "restricted_viewer"]);
  const uploadPODescriptionLoading = useSelector(selectUpdateOrderLoading);
  const uploadRfqDescriptionLoading = useSelector(selectUpdateRFQLoading);
  const projectCompanyOrdersLastQuery = useSelector(selectProjectCompanyOrdersLastQuery);
  const assignNoteLoading = useSelector(selectAssignInternalNoteLoading);
  const dispatch = useDispatch();
  const classes = useStyles({
    isDetailPage,
  });
  const [currentCheckedNote, setCurrentCheckedNote] = useState<NoteType>(null);
  const internalNotesList = useSelector(selectInternalNotesList);

  const selectedNote = notes[0]; // Right now, only one note can be assigned
  const [noteDescription, setNoteDescription] = useState<string>(internal_note_description || "");
  const [isModified, setIsModified] = useState<boolean>(false);

  useEffect(() => {
    setIsModified(noteDescription !== internal_note_description);
  }, [noteDescription]);

  const selectedPks = notes?.map((notes: NoteType) => notes.pk);

  const handleChangeNote = async (event: React.MouseEvent, note: NoteType) => {
    setCurrentCheckedNote(note);
    event.stopPropagation();
    const payload = { pk: paramID };
    dispatch(
      workspaceNextActions.assignInternalNoteRequest({
        pk: paramID as number,
        notes: {
          notes: notes?.every((item: NoteType) => item.pk !== note.pk) ? [note.pk] : [],
        },
        moduleType,
        onSuccess: () => {
          dispatch(workspaceNextActions.getInternalNotesRequest(moduleType));
          if (moduleType == "PO") {
            if (isDetailPage) {
              dispatch(ordersActions.fetchOrderRequest(payload as any));
            } else {
              dispatch(ordersActions.fetchOrdersRequest({ query: ordersLastQuery }));
            }
          } else {
            if (isDetailPage) {
              window.location.reload();
              return;
            }
            dispatch(
              projectActions.fetchProjectCompanyOrdersRequest({
                query: projectCompanyOrdersLastQuery,
              })
            );
          }
        },
      } as SelectInternalNote)
    );
  };

  const stopAutoDropdownClose = (event: React.MouseEvent | React.KeyboardEvent) => {
    //we don't need to close dropdown unexpectedly
    event.stopPropagation();
  };

  const handleDescriptionChange = (event: React.MouseEvent) => {
    stopAutoDropdownClose(event);
    if (internal_note_description === noteDescription.trim()) {
      return; // note description was not updated
    }
    if (moduleType == "RFQ") {
      dispatch(
        projectActions.updateRFQRequest({
          pk: paramID as any,
          internal_note_description: noteDescription,
          onSuccess: () => {
            if (isDetailPage) {
              window.location.reload();
              return;
            } else {
              dispatch(
                projectActions.fetchProjectCompanyOrdersRequest({
                  query: projectCompanyOrdersLastQuery,
                })
              );
            }
          },
        })
      );
    } else {
      dispatch(
        ordersActions.updateOrderRequest({
          pk: paramID as number,
          internal_note_description: noteDescription,
          onSuccess: () => {
            if (isDetailPage) {
              const payload = {
                pk: paramID,
              };
              dispatch(ordersActions.fetchOrderRequest(payload as any));
            } else {
              dispatch(
                ordersActions.fetchOrdersRequest({
                  query: ordersLastQuery,
                })
              );
            }
          },
        })
      );
    }
  };

  return (
    <FormControl fullWidth={!isDetailPage && true}>
      <Select
        disabled={hasViewerRole}
        displayEmpty
        fullWidth
        renderValue={() =>
          selectedNote ? (
            <div
              className={classes.textFieldItems}
              style={{
                marginTop: isDetailPage && !internal_note_description && "2px",
              }}
            >
              <div
                style={{
                  backgroundColor: selectedNote?.color,
                  height: "16px",
                  width: "16px",
                  borderRadius: 30,
                  margin: "auto 0",
                }}
              ></div>
              <div className={classes.flexItemsDown}>
                <CustomTypography variant="caption" className={classes.tagTitle}>
                  {selectedNote?.title}
                </CustomTypography>
                {internal_note_description && (
                  <CustomTypography
                    className={
                      isDetailPage ? classes.detailPageNoteDescription : classes.noteDescription
                    }
                  >
                    {internal_note_description}
                  </CustomTypography>
                )}
              </div>
            </div>
          ) : (
            <Tooltip title={internal_note_description ? internal_note_description : ""}>
              <Typography variant="caption" className={classes.defaultTagTitle}>
                {internal_note_description
                  ? internal_note_description
                  : t("project:table:row:internalNotes:addATag")}
              </Typography>
            </Tooltip>
          )
        }
        multiple={false}
        IconComponent={ExpandMore}
        color="primary"
        sx={
          isDetailPage
            ? {
                "&.MuiOutlinedInput-root": {
                  padding: "0 10px",
                  borderRadius: "24px",
                },
                "& .MuiSelect-select": {
                  padding: "10px",
                },
                "& .MuiSelect-icon": {
                  color: "black",
                },
              }
            : {
                // minWidth: "250px",
                boxShadow: "none",
                "& .MuiSelect-select": {
                  paddingLeft: 0,
                },
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiSelect-icon": {
                  color: "black",
                },
              }
        }
      >
        <Box className={classes.dropdownTagCell} onClick={stopAutoDropdownClose}>
          <Box maxHeight="240px" overflow="auto" marginBottom="8px">
            {internalNotesList?.length > 0 ? (
              internalNotesList?.map((tags) => (
                <Box
                  key={tags.id}
                  className={classes.tagsContainer}
                  onClick={(event: React.MouseEvent) => handleChangeNote(event, tags)}
                >
                  <>
                    <Box className={classes.tag}>
                      <span
                        className={classes.optionColor}
                        style={{
                          backgroundColor: tags.color,
                        }}
                      ></span>
                      <CustomTypography variant="body2" className={classes.title}>
                        {tags.title}
                      </CustomTypography>
                    </Box>
                    {assignNoteLoading && currentCheckedNote?.id == tags?.id ? (
                      <CircularProgress className={classes.progress} size="25px" />
                    ) : (
                      <Checkbox
                        disableRipple
                        sx={{ paddingRight: "16px" }}
                        onClick={(event: React.MouseEvent) => handleChangeNote(event, tags)}
                        size="small"
                        checked={selectedPks?.includes(tags.id)}
                        color="primary"
                      />
                    )}
                  </>
                </Box>
              ))
            ) : (
              <Typography
                variant="body1"
                className={classes.emptyTagsSection}
                onClick={stopAutoDropdownClose}
              >
                {t("project:table:row:internalNotes:noTags")}
              </Typography>
            )}
          </Box>
          <Divider className={classes.customDividerStyle} />

          <Typography
            variant="body2"
            className={classes.manageTags}
            onClick={(event) => {
              stopAutoDropdownClose(event);
              if (!uploadPODescriptionLoading || !uploadRfqDescriptionLoading) {
                dispatch(
                  modalsActions.showModal({
                    key: SharedModalTypes.INTERNAL_NOTES_MODAL,
                  })
                );
              }
            }}
          >
            {t("project:table:row:internalNotes:manage")}
          </Typography>
          <Divider className={classes.customDividerStyle} />
          <TextField
            key={selectedNote?.pk}
            sx={{
              "& .MuiInputBase-formControl": {
                padding: 0,
              },
              "& .MuiOutlinedInput-input": {
                margin: "10px",
              },
              margin: "12px 16px 4px 16px",
            }}
            placeholder={t("workspaceNext:QA:simpleRFQ:addNote")}
            value={noteDescription}
            rows={3}
            inputProps={{
              maxLength: 200,
            }}
            onKeyDown={stopAutoDropdownClose}
            onClick={stopAutoDropdownClose}
            multiline
            variant="outlined"
            color="secondary"
            onChange={(e) => setNoteDescription(e.target.value)}
          />
          <Box
            className={classes.bottomActionButton}
            onClick={(event: React.MouseEvent) => {
              stopAutoDropdownClose(event);
            }}
          >
            <CustomButton
              variant="contained"
              color="primary"
              onClick={handleDescriptionChange}
              loading={uploadRfqDescriptionLoading || uploadPODescriptionLoading}
              disabled={!isModified}
            >
              {t("common:save")}
            </CustomButton>
          </Box>
        </Box>
      </Select>
    </FormControl>
  );
};
