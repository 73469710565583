import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ReadinessDashboardSupplierViewTotalCard } from "./readiness-dashboard-supplier-view-total-card/readiness-dashboard-supplier-view-total-card";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import {
  SupplierViewTotalCardKeyMapping,
  SupplierViewTotalCardSlugs,
} from "@next/modules/vendor-management/redux";

export const ReadinessDashboardSupplierViewTotalCardsGroup: React.FC = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const data = dashboardData?.supplier_view;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid container sx={{ width: "100%" }} spacing={2}>
        {SupplierViewTotalCardKeyMapping.map(({ total, completed }) => {
          const completed_value = data?.[completed] ?? 0;
          const total_value = data?.[total] ?? 0;
          const title = SupplierViewTotalCardSlugs[total];
          return (
            <Grid item xs={6} md={6} lg={3} key={completed}>
              <ReadinessDashboardSupplierViewTotalCard
                title={title}
                completed_value={completed_value}
                total_value={total_value}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
