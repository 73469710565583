import React from "react";
import { SpecViewDonutChart } from "../../../charts/spec-view-donut-chart/spec-view-donut-chart";
import { SpecViewSpecTypeCard, SpecViewSpecTypeSlugs } from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-spec-view-spec-card.styled";

type Props = {} & SpecViewSpecTypeCard;

export const ReadinessDashboardSpecViewSpecCard: React.FC<Props> = ({
  slug,
  fully_compliant,
  compliant_with_rfd,
  partially_compliant,
  not_compliant,
  poe_not_collected,
  at_risk,
  out_of_scope,
}) => {
  return (
    <S.StyledCardBox>
      <S.StyledTypography variant="h6">{SpecViewSpecTypeSlugs[slug]}</S.StyledTypography>

      <SpecViewDonutChart
        slug={slug}
        fully_compliant={fully_compliant}
        compliant_with_rfd={compliant_with_rfd}
        partially_compliant={partially_compliant}
        not_compliant={not_compliant}
        poe_not_collected={poe_not_collected}
        at_risk={at_risk}
        out_of_scope={out_of_scope}
      />
    </S.StyledCardBox>
  );
};
