import React from "react";
import { ReadinessDashboardSupplierViewOnboardingTable } from "../../../table/readiness-dashboard-supplier-view-onboarding-table/readiness-dashboard-supplier-view-onboarding-table";
import * as S from "./readiness-dashboard-supplier-view-onboarding-table-card.styled";

export const ReadinessDashboardSupplierViewOnboardingTableCard = () => {
  return (
    <S.StyledCardBox>
      <S.StyledTypography variant="h6">
        Supplier Onboarding Positive Response Breakdown
      </S.StyledTypography>

      <ReadinessDashboardSupplierViewOnboardingTable />
    </S.StyledCardBox>
  );
};
