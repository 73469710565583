import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { ReadinessDashboardSpecViewSpecCard } from "./readiness-dashboard-spec-view-spec-card/readiness-dashboard-spec-view-spec-card";
import { selectReadinessDashboardData } from "@next/modules/vendor-management/redux/selectors";
import { SpecViewSpecTypeCard } from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-spec-view-spec-cards-group.styled";

export const ReadinessDashboardSpecViewSpecCardsGroup = () => {
  const dashboardData = useSelector(selectReadinessDashboardData);
  const specTypesData: SpecViewSpecTypeCard[] = dashboardData?.spec_view?.spec_type_cards;

  return (
    <>
      <S.StyledTitleBox>
        <S.StyledTypography variant="h6">Specification overview</S.StyledTypography>
      </S.StyledTitleBox>

      <Grid container sx={{ width: "100%" }} spacing={1}>
        {specTypesData?.map((specType, index) => (
          <Grid key={specType.slug} item xs={12} md={6} lg={6} xl={4}>
            <ReadinessDashboardSpecViewSpecCard
              slug={specType.slug}
              fully_compliant={specType.fully_compliant}
              compliant_with_rfd={specType.compliant_with_rfd}
              partially_compliant={specType.partially_compliant}
              not_compliant={specType.not_compliant}
              poe_not_collected={specType.poe_not_collected}
              at_risk={specType.at_risk}
              out_of_scope={specType.out_of_scope}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
