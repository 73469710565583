import { Box, Grid, styled } from "@mui/material";

export const StyledBox = styled(Box)({
  width: "100%",
  paddingTop: "80px",
  paddingBottom: "80px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const StyledGrid = styled(Grid)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
