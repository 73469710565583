import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReadinessDashboardSuppliersTable } from "../../../table/readiness-dashboard-suppliers-table/readiness-dashboard-suppliers-table";
import { selectSuppliersTableData } from "@next/modules/vendor-management/redux/selectors";
import {
  PaginationSizeForSpecs,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import * as S from "./readiness-dashboard-overview-suppliers-table-card.styled";

export const ReadinessDashboardOverviewSuppliersTableCard: React.FC = () => {
  const dispatch = useDispatch();
  const suppliersTableData = useSelector(selectSuppliersTableData);
  const rowCount = suppliersTableData?.count;
  const suppliers = suppliersTableData?.results;
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      vendorManagementActions.fetchSuppliersTableDataRequest({
        page: page,
        pageSize: PaginationSizeForSpecs,
        supplierType: "p1",
      })
    );
  }, [page]);

  return (
    <S.StyledBox>
      <S.StyledTypography variant="h6">{"P1 suppliers"}</S.StyledTypography>

      <ReadinessDashboardSuppliersTable
        page={page}
        setPage={setPage}
        pageSize={PaginationSizeForSpecs}
        rows={suppliers}
        rowCount={rowCount}
      />
    </S.StyledBox>
  );
};
