import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";
import { selectSamples, selectSamplesLoading } from "@next/modules/ai/redux/selectors";

import { aiParserService } from "@next/modules/ai/services/aiParserService";
interface Sample {
  id: number;
  filename: string;
  is_ready: boolean;
  is_passing: boolean;
}

interface SamplesListViewProps {
  parserId: string;
  supplierName: string;
}

const SamplesListView: React.FC<SamplesListViewProps> = ({ parserId, supplierName }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const samples = useSelector(selectSamples);
  const isLoadingSamples = useSelector(selectSamplesLoading);
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);

  // Fetch samples when parserId changes
  useEffect(() => {
    if (parserId) {
      dispatch(parserActions.fetchSamplesRequest(parserId));
    }
  }, [parserId, dispatch]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    dispatch(parserActions.uploadSampleRequest({ parserId, formData }));
    setOpen(false);
    setFile(null);
  };

  const handleSampleClick = (sampleId: number) => {
    history.push(`/ai/parsers/${supplierName}:${parserId}/samples/${sampleId}`);
  };

  return (
    <Box display="flex" flexDirection="column">
      <AppBar position="static" sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <Toolbar>
          <Typography width="100%" variant="h6" sx={{ color: "text.primary" }}>
            {supplierName} Samples
          </Typography>
          <Box display="flex" justifyContent="flex-end" width="100%" alignItems="center">
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              sx={{ mr: 1 }}
              onClick={() => {
                aiParserService.exportParserAsJson(parserId, supplierName);
              }}
            >
              Export Parser as JSON
            </Button>
            <Button variant="contained" startIcon={<AddIcon />} onClick={() => setOpen(true)}>
              Upload Sample
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box flex="1" p={3} overflow="auto">
        <Grid container spacing={3}>
          {samples.map((sample) => (
            <Grid item xs={3} sm={2} md={1} key={sample.id}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                textAlign="center"
                onClick={() => handleSampleClick(sample.id)}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <IconButton size="large">
                  <FolderIcon fontSize="large" />
                  <span
                    style={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    {sample.is_passing ? (
                      <CheckCircleIcon color="success" />
                    ) : (
                      <ErrorIcon color="error" />
                    )}
                  </span>
                </IconButton>

                <Tooltip title={sample.filename}>
                  <Typography
                    variant="body2"
                    style={{
                      maxWidth: 80,
                      overflow: "hidden",
                      wordWrap: "break-word",
                      whiteSpace: "normal",
                    }}
                  >
                    {sample.filename}
                  </Typography>
                </Tooltip>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Upload Modal */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Upload Sample</DialogTitle>
        <DialogContent>
          <TextField type="file" fullWidth onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpload} color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SamplesListView;
