import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReadinessDashboardCockpit } from "../readiness-dashboard-cockpit/readiness-dashboard-cockpit";
import { ReadinessDashboardNav } from "../readiness-dashboard-nav/readiness-dashboard-nav";
import { ReadinessDashboardOverview } from "../readiness-dashboard-overview/readiness-dashboard-overview";
import { ReadinessDashboardSpecView } from "../readiness-dashboard-spec-view/readiness-dashboard-spec-view";
import { ReadinessDashboardSupplierView } from "../readiness-dashboard-supplier-view/readiness-dashboard-supplier-view";
import { LoadingScreen } from "../../loading-screen/loading-screen";
import { ErrorScreen } from "../../error-screen/error-screen";
import {
  ReadinessDashboardNavMap,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectReadinessDashboardDataLoading,
  selectReadinessDashboardDataRequestError,
  selectSupplierFilter,
} from "@next/modules/vendor-management/redux/selectors";
import * as S from "./readiness-dashboard-body.styled";

const DashboardNavTabsMap = {
  OVERVIEW: ReadinessDashboardOverview,
  "SPEC VIEW": ReadinessDashboardSpecView,
  "SUPPLIER VIEW": ReadinessDashboardSupplierView,
};

export const ReadinessDashboardBody: React.FC = () => {
  const dispatch = useDispatch();
  const supplierFilter = useSelector(selectSupplierFilter);
  const isLoadingFetchReadinessDashboardData = useSelector(selectReadinessDashboardDataLoading);
  const errorFetchReadinessDashboardData = useSelector(selectReadinessDashboardDataRequestError);
  const [activeNavTab, setActiveNavTab] = useState(ReadinessDashboardNavMap.Overview);
  const ActiveComponentOfTab = DashboardNavTabsMap[activeNavTab];

  useEffect(() => {
    const supplierType =
      supplierFilter === "All Suppliers" ? "" : supplierFilter.toLocaleLowerCase();
    dispatch(
      vendorManagementActions.fetchReadinessDashboardDataRequest({ supplierType: supplierType })
    );
  }, [supplierFilter]);

  if (errorFetchReadinessDashboardData) {
    return <ErrorScreen message={"Failed loading dashboard data. Try again."} />;
  }

  if (isLoadingFetchReadinessDashboardData) {
    return <LoadingScreen />;
  }

  return (
    <S.StyledRootBox>
      <ReadinessDashboardCockpit />

      <S.StyledStickyNavWrapper>
        <ReadinessDashboardNav activeNavTab={activeNavTab} setActiveNavTab={setActiveNavTab} />
      </S.StyledStickyNavWrapper>

      <ActiveComponentOfTab />
    </S.StyledRootBox>
  );
};
