import { Box, styled } from "@mui/material";
import Chart from "react-apexcharts";

export const StyledBox = styled(Box)({
  width: "100%",
  height: "122px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "24px",
  position: "relative",
});

export const LegendWrapper = styled(Box)({
  position: "absolute",
  top: "135px",
});

export const StyledChart = styled(Chart)({
  width: "190px",
  height: "190px",
  marginTop: "-5px",
});
